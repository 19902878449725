<template>
  <div id="pdf-print" :class="{'separateTop': tvDashIsOpen}">
    <div class="loading-card-controller" v-show="loading">
      <Circle2 class="spinner-card-controller"/>
    </div>
    <div v-if="defaultCardsQuestions.length > 0">
      <div class="row">
        <app-card class="col-lg-11 col-md-11 col-xs-11 col-sm-11 overview-card" style="padding: 0px !important;">
          <div class="overview-card-row">
            <div class="dashboard-content-stats">
              <tweenedNumber :label="$t('analytics.answers')" :number="statsUpdated.totalAnswers"></tweenedNumber>
              <tweenedNumber :label="$t('analytics.time')" :number="statsUpdated.responseAverageTime" formula="min"></tweenedNumber>
            </div>
            <div data-html2canvas-ignore="true" class="hide-when-print dashboard-content-options">
              <div class="options1-dash">
                <div v-for="(button, index) in buttons" :key="index">
                  <app-button
                    inline
                    :icon="button.icon"
                    icon-color="gray"
                    icon-size="sm"
                    class="px-0 my-0 mr-1 colored"
                    :class="{'isActive': button.isActive}"
                    v-on:click="changeModeGraphs(button.view)"
                  >
                    <app-textstyle
                      color="gray"
                      size="size-xxs"
                      style="white-space: nowrap;"
                    >{{ button.label }}
                    </app-textstyle>
                  </app-button>
                </div>
              </div>
              <!-- END change types -->
              <div class="options2-dash">
                <div>
                  <app-button
                    inline
                    icon="fullscreen"
                    icon-color="gray"
                    icon-size="sm"
                    class="colored"
                    :class="{tvDashButtomColorActive: !!tvDashIsOpen}"
                    v-on:click="tvDashPage()"
                  >
                    <div class="tv-text">
                      <app-textstyle color="gray" size="size-xxs" :class="{tvDashButtomColorActive: !!tvDashIsOpen}">TV Dash</app-textstyle>
                    </div>
                  </app-button>
                </div>
                <div v-if="!hideExports">
                  <app-button
                    inline
                    icon="download"
                    icon-color="gray"
                    icon-size="sm"
                    class="colored"
                    v-on:click="exportPDF()"
                  >
                    <div class="">
                      <app-textstyle color="gray" size="size-xxs">{{ $t('export.title') }}</app-textstyle>
                    </div>
                  </app-button>
                </div>
                <div style="margin-top:-8px">
                  <app-button
                    class="button-customized-dash"
                    inline
                    :class="{tvDashButtomColorActive: !!showCustomizedDashboard}"
                    v-on:click="showDashboardCustom()"
                  >
                    <div style="padding: 5px">
                      <i style="font-size: 28px; color: #838383;" class="material-icons">palette</i>
                      <div style="margin-top: -2px;">
                        <app-textstyle
                          color="gray"
                          size="size-xxs"
                          :class="{ tvDashButtomColorActive: !!showCustomizedDashboard }"
                        >{{ $t('dashboard.graphics.customized_dash') }}</app-textstyle>
                      </div>
                    </div>
                  </app-button>
                </div>
              </div>
            </div>
          </div>
        </app-card>
      </div>

      <DashboardOverview
        v-if="!showCustomizedDashboard && !loading && cardsQuestionsFormatted.length"
        :tvDash="tvDashIsOpen"
        :dataCard="cardsQuestionsFormatted"
        :survey="currentSurvey"
        :allQuestionsSynthetic="allQuestionsSynthetic"
        :allQuestionsOvertime="allQuestionsOvertime"
        :allQuestionsOverall="allQuestionsOverall"
        @show-sidebar-dash-custom="showDashboardCustom()"
      />

      <DashboardCustom
        v-if="showCustomizedDashboard"
        :survey="currentSurvey"
        :allQuestionsSynthetic="allQuestionsSynthetic"
        :allQuestionsOvertime="allQuestionsOvertime"
        :allQuestionsOverall="allQuestionsOverall"
        :tvDash="tvDashIsOpen"
      />

      <div v-if="onlyReferralQuestions" class="row" style="text-align: center; width: 650px !important;">
        <app-heading class="col-lg-12 col-md-12 col-sm-12 col-xs-12" level="h3" style="line-height: 48px">
          {{ $t('dashboard.no_elegible_component') }}
        </app-heading>
      </div>
      <theme-style>
        .addCardIcon, .addCard .card,
        .question-card-wrapp > div > h6,
        .isActive > div > i,
        .isActive > div > span {
          color: {{user.account.theme.colors.accent || '#ff607d'}} !important;
        }
        .saveBtn {
          background-color: {{user.account.theme.colors.primary || '#673ab7'}} !important;
          color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff'}};
          border-color: {{ user.account.theme.colors.secondary || '#3f3356'}} !important;
          box-shadow: 0 5px 10px 0 {{ user.account.theme.colors.secondary || '#3f3356'}} 30% !important;
        }
      </theme-style>
    </div>
    <NoDataToShow v-if="Object.keys(statsUpdated).length > 0 && allQuestionsSynthetic.length > 0 && allQuestionsSynthetic[0].questions.length <= 0" title="dashboard.no_responses" />
  </div>
</template>

<script>
import gsap from 'gsap'
import { mapGetters } from 'vuex'
import { dashboardMaker, formatters, gridMaker } from '@/_helpers'
import { dashboardsService } from '@/_services'
import DashboardCustom from '../dashboardCustom/DashboardCustom'
import NoDataToShow from '../../alerts/NoDataToShow.vue'
import tweenedNumber from '../../../components/animations/TweenedNumber.vue'
import DashboardOverview from './DashboardOverview.vue'
import('lodash-es').then((lodashEs) => { window._ = lodashEs.default })

export default {
  name: 'CardController',
  components: {
    NoDataToShow,
    DashboardCustom,
    DashboardOverview,
    tweenedNumber,
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      show: 0,
      key: 0,
      defaultCardsQuestions: [],
      cardsQuestionsFormatted: [],
      cardSelected: '',
      customizedCardSelected: '',
      showCustomizedSidebarConfig: false,
      showCustomizedDashboard: false,
      showSidebarConfig: false,
      tvDashIsOpen: false,
      surveyGeneralInformation: {},
      allQuestionsSynthetic: [],
      allQuestionsOvertime: [],
      allQuestionsOverall: [],
      isLoadingData: true,
      awaitOvertimeRequest: false,
      awaitOverallRequest: false,
      renderListCardsDash: 0,
      isLoadingContent: false,
      buttons: [
        { view: 'average', icon: 'dashboard', label: this.$t('dashboard.views.average'), isActive: false },
        { view: 'overtime', icon: 'chart-line', label: this.$t('dashboard.views.overtime'), isActive: false },
        { view: 'overall', icon: 'answers', label: this.$t('dashboard.views.overall'), isActive: false }
      ],
      exporting: false,
      loading: true,
      chosenView: 'average',
      cardsToExport: undefined,
      onlyReferralQuestions: false,
      statsUpdated: {
        totalAnswers: 0,
        responseAverageTime: 0
      },
      tweenedTotalSendings: 0,
      tweenedTotalAnswers: 0,
      tweenedResponseAverageTime: 0
    }
  },
  destroyed () {
    document.removeEventListener('fullscreenchange', this.closeDash)
    document.removeEventListener('webkitfullscreenchange', this.closeDash)
    document.removeEventListener('mozfullscreenchange', this.closeDash)
    document.removeEventListener('MSFullscreenChange', this.closeDash)
  },
  beforeDestroy () {
    this.$root.$off('filter-updated')
    this.$root.$off('get-overtime-data')
    this.$root.$off('get-overall-data')
    this.$root.$off('expand-enum')
    this.$root.$off('update-card-custom-on-reload-overtime')
    this.$root.$off('update-card-custom-on-reload-overall')
  },
  async mounted () {
    this.$nextTick(async () => {
      this.isLoadingData = true
      await Promise.all([this.getSynthetic(), this.updateStats()])
      this.checkIfOnlyReferral()
      document.addEventListener('fullscreenchange', this.closeDash)
      document.addEventListener('webkitfullscreenchange', this.closeDash)
      document.addEventListener('mozfullscreenchange', this.closeDash)
      document.addEventListener('MSFullscreenChange', this.closeDash)
      this.isLoadingData = false
      this.setLoading(true)

      this.$root.$on('check-and-update-custom-dashboard', () => {
        if (this.showCustomizedDashboard) { this.showDashboardCustom() }
      })

      this.getOverviewDataForCards = async () => {
        if (this.$route.name === 'Overview') {
          this.setLoading()
          this.awaitOverallRequest = true // block any other place that get overall
          this.checkIfOnlyReferral()
          await this.getSynthetic()
          this.allQuestionsOverall = []
          this.awaitOverallRequest = false
          this.show += 1
          this.setLoading(true)
        }
      }

      this.$root.$on('filter-updated', () => {
        this.getOverviewDataForCards()
        this.updateStats()
      })

      this.$root.$on('get-overtime-data', async (query, gridRange) => {
        this.isLoadingData = true
        if ((this.allQuestionsOvertime.length === 0 && !this.awaitOvertimeRequest) || this.currentPage === 'dashboardCustom') {
          this.awaitOvertimeRequest = true // essa linha evita que repetidos eventos de get-overtime façam requests simultaneos
          // isso acontece quando varios cards overtime são renderizados no dash personalizado
          // como n deu tempo do request inicial do primeiro card terminar, os demais fazem um request pedindo dados para "todos" novamente
          const formattedQuery = formatters.formatDateByAddTimezone(query, 3, 'hours')
          const res = await dashboardsService.getOvertimeData(formattedQuery, gridRange, this.$route.query.cleanEmptyQuestions)
          this.allQuestionsOvertime = res.filter(item => item)
          this.awaitOvertimeRequest = false // agora libera para pegar mais dados novamente

          if (this.allQuestionsOvertime.length && this.currentPage === 'dashboardCustom') {
            this.$root.$emit('update-card-custom-on-reload-overtime', this.allQuestionsOvertime)
          }
        }
        this.isLoadingData = false
      })

      this.$root.$on('get-overall-data', async (query) => {
        this.isLoadingData = true
        if ((this.allQuestionsOverall.length === 0 && !this.awaitOverallRequest) || this.currentPage === 'dashboardCustom') {
          this.awaitOverallRequest = true
          this.allQuestionsOverall = await dashboardsService.getSyntheticData(formatters.formatDateByAddTimezone(query, 3, 'hours'))
          this.awaitOverallRequest = false

          if (this.allQuestionsOverall.length && this.currentPage === 'dashboardCustom') {
            this.$root.$emit('update-card-custom-on-reload-overall', this.allQuestionsOverall)
          }
        }
        this.isLoadingData = false
      })

      this.$root.$on('expand-enum', (obj) => {
        const idToScroll = `card-${obj.question}`
        const scroll = setTimeout(() => {
          const element = document.getElementById(idToScroll)
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
          clearTimeout(scroll)
        }, 500)
      })

      this.changeModeGraphs(this.currentPage)
    })
  },
  computed: {
    currentPage () { return this.$store.getters['dashboard/getCurrentPage'] },
    currentSurvey () { return this.$store.getters['dashboard/getCurrentSurvey'] },
    lang () { return this.$store.getters['account/getLang'] },
    ...mapGetters({
      user: 'account/getUser'
    }),
    hideExports () { return this.user.features.hideExports }
  },
  methods: {
    setLoading (close) {
      if (!close) { this.loading = true }

      const action = close ? 0 : 0.2
      const divLoading = document.querySelector('.loading-card-controller')
      gsap.to(divLoading, {
        opacity: action,
        duration: 0.2,
        onComplete: () => {
          if (close) { this.loading = false }
        }
      })
    },
    closeSideBarConfig (event) {
      if (event) { this.showDashboardCustom() }
      this.showSidebarConfig = false
    },
    openSideBarConfig (cardItem) {
      this.cardSelected = cardItem
      this.showSidebarConfig = true
    },
    async getSynthetic () {
      try {
        const extractQuestions = questions => { return questions.filter(item => item.question._id).map(item => item.question) }
        const formattedDate = formatters.formatDateByAddTimezone(this.$route.query.q, 3, 'hours')
        const cleanEmptyQuestions = this.$route.query.cleanEmptyQuestions

        this.allQuestionsSynthetic = await dashboardsService.getSyntheticData(formattedDate, cleanEmptyQuestions)

        const hasQuestion = this.allQuestionsSynthetic.length && this.allQuestionsSynthetic[0].questions.length

        if (hasQuestion) {
          const questions = extractQuestions(this.allQuestionsSynthetic[0].questions)
          const removeNonPermittedUis = questions.filter(item => !['referral', 'anonymous'].includes(item.type))
          this.defaultCardsQuestions = dashboardMaker.makeDefaultDash(removeNonPermittedUis)
          this.totalAnswered()
          this.formatDataCard()

          if (this.currentPage === 'overtime') { this.getOvertime() }
          if (this.currentPage === 'overall') { this.getOverall() }
        } else {
          this.defaultCardsQuestions = []
        }
      } catch (error) {
        console.error('Error fetching synthetic data:', error)
        this.defaultCardsQuestions = []
      } finally {
        this.setLoading(true)
      }
    },
    async getOvertime () {
      const query = this.$route.query.q
      const gridRange = gridMaker.getGrid(gridMaker.getDataFromQuery(query).$gte, gridMaker.getDataFromQuery(query).$lte)
      const formattedQuery = formatters.formatDateByAddTimezone(query, 3, 'hours')
      const res = await dashboardsService.getOvertimeData(formattedQuery, gridRange, this.$route.query.cleanEmptyQuestions)
      this.allQuestionsOvertime = res.filter(item => item)
      this.formatDataCard()
    },
    async getOverall () {
      const query = this.$route.query.q
      this.allQuestionsOverall = await dashboardsService.getSyntheticData(formatters.formatDateByAddTimezone(query, 3, 'hours'))
      this.formatDataCard()
    },
    async updateStats () {
      const surveyId = this.$store.getters['dashboard/getCurrentSurvey']._id
      const formattedQuery = formatters.formatDateByAddTimezone(this.$route.query.q, 3, 'hours')
      const result = await dashboardsService.getSurveyStats(surveyId, formattedQuery)
      this.statsUpdated = result
    },
    totalAnswered () {
      this.defaultCardsQuestions.map(i => {
        const idx = this.allQuestionsSynthetic[0].questions.findIndex(q => q.question._id === i.question._id)
        if (idx >= 0) {
          i.total = this.allQuestionsSynthetic[0].questions[idx].total
        }
      })
    },
    activeExpand (index) {
      this.defaultCardsQuestions[index].isExpanded = !this.defaultCardsQuestions[index].isExpanded
    },
    checkIfOnlyReferral () {
      // check if survey has only referral questions, so we can display a message
      let only = true
      this.currentSurvey.questions.map(item => {
        if (item.type !== 'referral') { only = false }
      })
      this.onlyReferralQuestions = only
    },
    exportPDF () {
      window.print()
    },
    closeDash () {
      this.$root.$emit('tv-dash-page')

      if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        this.tvDashIsOpen = false
        return
      }

      this.tvDashIsOpen = true
    },
    showDashboardCustom () {
      this.$store.commit('dashboard/updateCurrentPage', 'dashboardCustom')

      this.showCustomizedDashboard = true

      this.chosenView = 'dashboardCustom'

      const classBtn = document.querySelector('.button-customized-dash')
      if (classBtn) {
        classBtn.className = document.querySelector('.button-customized-dash').className
      }

      this.buttons.forEach(o => {
        if (this.showCustomizedDashboard) { o.isActive = false }
        if (!this.showCustomizedDashboard && this.chosenView === o.view) { o.isActive = true }
      })

      this.setLoading(true)
    },
    tvDashPage () {
      this.$root.$emit('hide-new-card')
      if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen()
          // mozzila
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        }
        return false
      }

      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    },
    toggleCustomizedDashboard () {
      this.showCustomizedDashboard = !this.showCustomizedDashboard
    },
    changeModeGraphs (name) {
      this.setLoading()

      if (!this.defaultCardsQuestions.length) { this.setLoading(true) }

      if (name === 'dashboardCustom') {
        return this.showDashboardCustom()
      }

      this.$store.commit('dashboard/updateCurrentPage', name)

      this.chosenView = name

      this.buttons.forEach(o => {
        if (o.view === name) {
          o.isActive = true
        } else {
          o.isActive = false
        }
      })

      if (name === 'overtime' && Object.keys(this.allQuestionsOvertime).length === 0) {
        return this.getOvertime()
      }

      if (name === 'overall' && Object.keys(this.allQuestionsOverall).length === 0) {
        return this.getOverall()
      }

      this.showCustomizedDashboard = false

      this.formatDataCard()
    },
    formatDataCard () {
      if (!this.defaultCardsQuestions.length) return

      this.cardsQuestionsFormatted = []

      this.defaultCardsQuestions.forEach(item => {
        const matchingQuestion = this.currentSurvey.questions.find(question => question._id === item.key)

        if (matchingQuestion) {
          const card = {
            average: item.average,
            overall: item.overall,
            overtime: item.overtime,
            question: matchingQuestion,
            _id: matchingQuestion._id,
            type: matchingQuestion.type,
            ui: matchingQuestion.ui,
            title: matchingQuestion.title,
            viewType: this.currentPage
          }

          if (matchingQuestion.type === 'text') { card.viewType = 'text' }
          if (matchingQuestion.type === 'matrix') { card.viewType = this.currentPage === 'overall' ? 'average' : this.currentPage }

          this.cardsQuestionsFormatted.push(card)
        }
      })

      this.cardsQuestionsFormatted.map(card => {
        const idx = this.allQuestionsSynthetic[0].questions.findIndex(q => q.question._id === card.question._id)
        if (idx > -1) { card.total = this.allQuestionsSynthetic[0].questions[idx].total }
      })

      this.setLoading(true)
    }
  }
}
</script>

<style lang="scss">
.loading-card-controller {
  background: #EFF3F890;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 36;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner-card-controller {
    width: 2.1vw !important;
    height: 2.1vw !important;
    border-width: 3px !important;
    border-radius: 35px;
    border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
  }
}
.separateTop {
  margin-top: 3%;
}
.notSeparateTop {
  margin-top: -6%;
}
.toggleDash > .ui-switch {
  position: absolute !important;
}
.addCardIcon {
  font-size: 14rem !important;
  color: #b79be8;
  margin-top: 6rem;
  margin-left: 4rem;
}
.addCard {
  & .card {
    border: 3px dashed !important;
    color: #b79be8 !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
.pointer {
  cursor: pointer;
}
.toggleDash {
  & h6 {
    font-size: 17px !important;
    margin-left: 50px !important;
    margin-top: 1px !important;
  }
  & .ui-switch-track {
    background-color: #d5d5d5 !important;
  }
}

.myCanvas {
  max-width: 98% !important;
  margin: 0 auto;
  display: block;
  }
.title-question-card {
  padding-top: 10px;
  h6 {
    margin-top: 15px !important;
    width: 300px;
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h4 {
    line-height: 30px !important;
  }
}
.fixH6 {
  font-size: 0.9em !important;
}
.overview-card {
  margin: auto;
  height: 102px !important;
  .overview-card-row {
    display: flex;
    justify-content: space-between;
  }
  & h3 {
    font-size: 1.9em !important;
    font-weight: 600 !important;
  }
  & h6 {
    color: #6c6c6c !important;
    margin-left: 1px !important;
  }
}
.dashboard-content-stats {
  display: flex;
  padding-top: 18px;
  padding-left: 25px;
  width: 50%;
  div {
    margin-right: 8%;
  }
}
.dashboard-content-options {
  display: flex;
  padding-top: 15px;
  padding-right: 10px;
  width: 42%;
  justify-content: space-between;
  .options1-dash {
    // border-right: 1px solid $color-gray-light;
    width: 40%;
  }
  .options2-dash {
    padding: 0 20px;
    box-sizing: border-box;
  }
  .options2-dash, .options1-dash {
    display: flex;
    width: 50%;
    justify-content: space-around;
  }
}
.question-matrix-card {
  grid-column-end: span 2;
}
.enum-overtime {
  @extend %animated-to-slow;
  @extend %fadeIn;
  grid-column-end: span 2;
}
.question-text-card {
  grid-column-end: span 1;
}
.question-like-card {
  grid-column-end: span 2
}
.question-card-wrapp {
  margin: 0 0 15px 15px;
  height: 553px;
  .card {
    width: 100% !important;
    height: 100% !important;
  }
  .setLabel {
    margin-top: -25% !important;
  }
  .total-answered {
    width: 50%;
    height: 5%;
    box-sizing: border-box;
    margin-top: 5px;
    margin-left: 5.5%;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    .answered {
      color: #3f3356;
    }
  }
}
.questions-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  grid-gap: 10px;
  grid-auto-rows: minmax(20px, auto);
  grid-auto-flow: dense;
  padding: 10px;
  max-width: 95%;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 30px;
}
.min {
  margin-top: 18px !important;
  margin-left: 5px !important;
}
@media (max-width: 1147px) {
  .overview-card {
    margin: auto;
    height: auto !important;
    .overview-card-row {
      display: block;
      padding: 20px;
      box-sizing: border-box;
    }
  }
  .dashboard-content-stats {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 30px;
  }
  .dashboard-content-options {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 30px;
    margin-top: 30px;
    .options1-dash {
      border:none;
    }
    .options2-dash {
      padding: 0;
    }
  }
}

@media (min-width: 1800px) {
  .question-text-card {
    grid-column-end: span 2;
  }
  .question-like-card {
    grid-column-end: span 2;
  }
  .question-matrix-card {
    grid-column-end: span 2;
  }
  .enum-overtime {
    grid-column-end: span 2;
  }
  .export-button-container div {
    display: grid;
    & span {
      margin-top: 14px !important;
    }
  }
}

@media (min-width: 1400) {
  .question-text-card {
    grid-column-end: span 2;
  }
  .question-like-card {
    grid-column-end: span 4;
    .content-graphic {
      background: red !important;
    }
  }
  .question-matrix-card {
    grid-column-end: span 2;
  }
  .enum-overtime {
    grid-column-end: span 2;
  }
  .export-button-container div {
    display: grid;
    & span {
      margin-top: 14px !important;
    }
  }
}
@media (min-width: 1200) {
  .question-text-card {
    grid-column-end: span 2;
  }
  .question-like-card {
    grid-column-end: span 4;
  }
  .question-matrix-card {
    grid-column-end: span 2;
  }
  .enum-overtime {
    grid-column-end: span 2;
  }
  .export-button-container div {
    display: grid;
    & span {
      margin-top: 14px !important;
    }
  }
}
@media (min-width: 1024) {
  .question-text-card {
    grid-column-end: span 2;
  }
  .question-like-card {
    grid-column-end: span 4;
  }
  .question-matrix-card {
    grid-column-end: span 2;
  }
  .enum-overtime {
    grid-column-end: span 2;
  }
  .export-button-container div {
    display: grid;
    & span {
      margin-top: 14px !important;
    }
  }
}

@media (max-width: 780px) {
  .questions-view {
    display: block;
  }
  .question-like-card {
    grid-column-end: span 4;
  }
  .question-text-card {
    grid-column-end: span 2;
  }
  .question-matrix-card {
    grid-column-end: span 2;
  }
  .enum-overtime {
    grid-column-end: span 2;
  }
}

@media (max-width: 648px) {
  #card {
    padding: 9px !important;
  }
  .question-card-wrapp {
    margin-left: 0px !important;
  }
  .overview-card {
    margin: 13px !important;
    max-width: auto !important;
  }
  .dashboard-content-stats {
    display: flex;
    div {
      margin-bottom: 20px;
      margin-left: 20px;
    }
    h3 {
      font-size: 20px !important;
    }
  }
  .dashboard-content-options {
    display: block;
    padding: 0;
    .options2-dash {
      margin: 0 auto;
      margin-top: 30px;
      width: 80%;
    }
    .options1-dash {
      margin: 0 auto;
      width: 80%;
      border-bottom: 1px solid $color-gray-light
    }
  }
}
.export-button {
  & .binds-button.medium {
    background-color: #ff607d !important;
    width: 15px !important;
    height: 37px !important;
    font-size: 14px !important;
  }
}
.tv-text {
  width: 50px;
}
#pdf-print {
  background-color: #eff3f8;
}
@media print
  {
      .hide-when-print {
        display: none !important;
      }
      @page {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: -2px;
        size: a5;
      }
      .chartjs-render-monitor {
        width: 100% !important;
        height: 100% !important;
      }
      .matrix-related-rows {
        padding: 7px !important;
      }
      .matrix-content-side {
        width: 80% !important;
      }
      .matrix-related-labels {
        width: 15% !important;
      }
      .question-card-wrapp{
        // padding: 1cm;
        margin-left: 1cm !important;
        & .card {
          margin-top: 1cm;
        }
      }
      .question-card-wrapp {
        height: 100% !important;
      }
      .overview-card {
        margin-left: 1.5cm !important;
      }
      .questions-view {
        display: block;
      }
      #pdf-print {
        background-color: #ffff;
      }
      body  {
        width: 100%;
        padding: 0px;
        background-color: #ffff;
        color-adjust: exact;
        -webkit-print-color-adjust: exact !important;
      }
      body, #main-container {
        height: auto;
      }
      .dashboard-content-stats {
        margin-left: 1cm !important;
        display: flex !important;
      }
      .overview-card-row {
        padding: 0px;
      }
  }
  .button-expand-card {
    position: absolute;
    top:16px;
    right: 4px;
    transform: rotate(90deg);
    width: 53px;
    .material-icons {
      font-size: 34px;
      color: #b5b5b5;
    }
    &:hover {
      .material-icons {font-weight: bold;}
    }
  }
  .button-expand-off {
    transform: rotate(-90deg);
  }
</style>
