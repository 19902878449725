export default {
  Dashboard: 'Panel',
  Home: 'Inicio',
  activity: {
    interpolate: {
      resend: 'un reenvío',
      tag: 'una etiqueta',
      annotation: 'una nota',
      user: 'el usuario',
      survey: 'la búsqueda',
      ticket: 'un servicio',
      role: 'un grupo',
      message: 'un mensaje',
      category: 'la categoría',
      account: 'la cuenta',
      seedBatch: 'un lote'
    },
    filter_tooltip_today: 'Filtrar por hoy',
    action: 'Acción',
    displaying: 'Mostrando',
    of_today: 'de hoy',
    from_yesterday: 'de ayer',
    sending_expired: 'Enfoque caducado',
    sending_unexpired: 'Enfoque activado',
    sending: 'un acercamiento',
    annotation: 'Anotaciones',
    has_expired: 'Ha expirado',
    has_unexpired: 'Activado',
    has_created: 'Creado',
    has_added: 'Agregado',
    has_removed: 'Remoto',
    has_deleted: 'Excluido',
    has_sent: 'Enviaste',
    has_updated: 'Cambió',
    user_updated: 'Usuario cambiado',
    user_created: 'Usuario creado',
    user_deleted: 'Usuario eliminado',
    role_updated: 'Grupo Cambiado',
    role_created: 'Grupo creado',
    role_deleted: 'Grupo eliminado',
    survey_updated: 'Encuesta cambiado',
    survey_created: 'Encuesta creada',
    survey_deleted: 'Encuesta eliminada',
    message_updated: 'Mensaje cambiada',
    message_created: 'Mensajem creada',
    message_deleted: 'Mensajem eliminada',
    tag_updated: 'Tag cambiado',
    tag_created: 'Tag creada',
    tag_deleted: 'Tag eliminada',
    tag_error_add: 'Error al agregar la etiqueta',
    survey_send: 'Encuesta Enviada',
    seedBatch_sent: 'Lote enviado',
    title: 'Atividades',
    label: 'atividades',
    seedBatch: 'Lote',
    no_activity: 'Aún no hay actividades!',
    ticket_updated: 'Ticket cambiado',
    ticket_created: 'Ticket creada',
    ticket_deleted: 'Ticket eliminado',
    ticketCategory_updated: 'Categoria cambiado',
    ticketCategory_created: 'Categoria creada',
    ticketCategory_deleted: 'Categoria eliminado',
    addTag_updated: 'Etiquetas Marcada / Desmarcada',
    addTag: 'Etiquetas en la respuesta',
    annotation_updated: 'Anotaciones cambiado',
    annotation_created: 'Anotaciones creada',
    annotation_deleted: 'Anotaciones eliminado',
    account_updated: 'Cuenta cambiada',
    account_created: 'Cuenta creada',
    account_deleted: 'Cuenta eliminada',
    BrandOnBindsVoiceScore_created: 'Marca creada',
    BrandOnBindsVoiceScore_added: 'Marca añadida',
    BrandOnBindsVoiceScore_removed: 'Marca eliminada',
    ResendSending_created: 'Reenvío creado',
    Scheduled_deleted: 'Reenvío cancelado',
    yesterday: 'el dia de ayer',
    today: 'hoy dia',
    tomorrow: 'mañana',
    last: 'Última',
    when: 'Cuando',
    time: 'Hora',
    no_logs: 'Parece que tu cuenta aún no tiene registros de actividad.',
    no_log_day: 'Parece que no hay actividades ese día.'
  },
  Sendings: 'Enfoques',
  sendings: {
    pick_older_date: 'Desde',
    sendings: 'enfoques',
    deliveries: 'entregas',
    delivery_rate: 'tasa de entrega',
    openings: 'aberturas',
    clicks: 'clics',
    responses: 'Respuestas',
    response_rate: 'Tasa de respuesta',
    softBounces: 'rebote suave',
    bounces: 'rebote',
    spam: 'devoluciones de SPAM',
    without_sendings: 'Ops! Aún no tenemos datos de enfoques para mostrar.',
    deleted: 'Enfoque eliminado!',
    error_deleted: 'Error al eliminar enfoque',
    archived: 'Enfoque archivado!',
    resolved: 'Resuelto'
  },
  'bar-filter': {
    older_date: 'Desde',
    status: 'Status'
  },
  annotation: {
    annotation: 'Notas',
    description: 'Cree notas sobre las especificaciones de respuesta y anote la información sobre la asistencia.',
    go_top: 'Ve arriba',
    notes: 'Notas',
    max_length: 'Caracteres máximos',
    yes: 'Sí',
    no: 'No',
    delete_annotation: 'Quieres eliminar esta anotación ?',
    create: 'Crear anotaciones',
    type_note: 'Escribe la notación',
    delete: 'Eliminar'
  },
  exceeded: {
    upgrade: 'Es hora de actualizar.',
    limit: 'Ha alcanzado el límite de envíos gratuitos. Para enviar más, debe elegir el plan que mejor se adapte a su demanda.',
    keep: 'sigue enviando',
    notshow: 'no me vuelvas a mostrar',
    thanks: 'Gracia!',
    process: 'procesando su pedido, espere a que uno de nuestros asistentes se pondrá en contacto.'
  },
  account_details: {
    title: 'Configuraciones de cuenta',
    subtitle: 'Configuración general',
    default: 'Estándar',
    account_name: 'Nombre de la cuenta',
    type_id_or_name: 'Ingrese el ID o el nombre de la cuenta',
    type_url: 'Ingrese la URL',
    updated: '¡Actualizado!',
    updating_error: 'Error al intentar actualizar',
    enter_account: 'Iniciar sesión en esta cuenta',
    no_name: 'Sin nombre',
    no_email: 'Sin correo electrónico',
    no_phone: 'Sin teléfono',
    already_sent: 'Ya envió surveys',
    didnt_send_yet: 'Aún no he enviado encuestas',
    account: {
      name: {
        value: 'Nombre',
        description: 'El nombre de la cuenta se utiliza como el nombre del remitente en los mensajes de correo electrónico.'
      },
      url: {
        value: 'Url',
        description: 'La URL del sitio web de la cuenta'
      },
      dashboardPeriod: {
        value: 'Fecha predeterminada para filtros',
        description: 'Usaremos este campo para configurar la hora predeterminada en los filtros de fecha a través de la plataforma'
      }
    },
    send_preferences: {
      title: 'Configuración de envío',
      name: {
        value: 'Campo de nombre',
        description: 'Este es el campo, en la integración, que contiene el nombre del destinatario.'
      },
      email: {
        value: 'Campo de correo electrónico',
        description: 'Este es el campo, en la integración, que contiene el correo electrónico del destinatario.'
      },
      phone: {
        value: 'Campo telefónico',
        description: 'Este es el campo, en la integración, que contiene el teléfono del destinatario.'
      },
      sending_interval: {
        value: 'Intervalo de envío',
        description: 'Evita el envío al mismo destinatario durante el intervalo de tiempo ',
        pregunte: '¿Intervalo por búsqueda?'
      },
      expiration_time: {
        value: 'Hora de vencimiento de la búsqueda',
        description: 'Establece el límite de tiempo máximo para responder una encuesta después del envío'
      }
    },
    features: {
      sms_enabled: 'Enfoques SMS',
      sms_metadata: 'Habilitar el envío de SMS con metadatos',
      tickets: 'Asistencia (cierre el ciclo)',
      edit_survey_response: 'Editar encuesta respondida',
      associate_notes: 'Asignar notas al servicio',
      sheet_export: 'Exportar archivos por correo electrónico',
      has_invoice: 'Cliente que paga (liberar el bloqueo de disparo)',
      require_category: 'Forzar la categorización de la atención',
      text_analytics: 'Análisis de texto',
      comparative_dash: 'Reporte de desempeño',
      tags: 'Tags',
      two_way_auth: 'Autenticación de dos factores',
      v2: 'Habilitar la transición del sistema de enlaces (v1 / v2)',
      allow_to_v2: 'El botón solo aparecerá para el propietario de la cuenta',
      allow_to_v2_description: 'O botão só irá aparecer para o dono da conta',
      confirm_optout_title: 'Pantalla para confirmar la baja del correo electrónico',
      confirm_optout_description: 'Al hacer clic en dejar de recibir correos electrónicos de la empresa, aparecerá una pantalla para confirmar esta acción.',
      binds_voice_score: 'Habilitar puntuación de voz de enlaces',
      binds_voice_score_description: 'Dashboard que analiza textos basados ​​en fuentes de datos como redes sociales y hojas de cálculo.',
      whatsapp: {
        title: 'Habilitar WhatsApp',
        phone_id: 'Identificación del número de teléfono',
        business_id: 'Identificación de la cuenta de WhatsApp Business',
        token: 'Token de acceso permanente',
        limit_per_day: 'Límite de envío por día',
        limit_per_day_label: 'Cantidad de envíos por día',
        send_interval_title: 'Intervalo de entrega telefónica',
        send_interval_description: 'Impide el envío al mismo destinatario durante el intervalo de tiempo. Usar un período más largo mejora la experiencia del usuario y aumenta las posibilidades de respuesta.',
        template_text_simple_title: 'Mensaje de texto simple',
        template_text_simple_description: 'Plantilla de tipo texto simple',
        template_text_button_title: 'Mensaje con botón',
        template_text_button_description: 'Plantilla de tipo texto + botón',
        template_text_button_image_title: 'Mensaje con botón e imagen',
        template_text_button_image_description: 'Plantilla de tipo texto + botón + imagen'
      },
      sso: {
        title: 'Habilitar o SSO',
        client_id: 'Especifica el Id. De cliente de la aplicación que está registrada en Azure Active Directory',
        client_secret: 'Secreto utilizado para establecer la propiedad del ID de cliente',
        tenant: 'Identificador de inquilino de Azure AD'
      }
    }
  },
  sendingsBar: {
    metadata: 'Metadados'
  },
  date_filter: {
    from: 'En',
    to: 'Hasta',
    empty: 'Seleccionar una fecha',
    periods: {
      day: '24 HORAS',
      week: '7 DIAS',
      month: '30 DIAS',
      semester: '6 MESES',
      year: '12 MESES'
    }
  },
  login: {
    welcome: 'Bienvenido',
    password: 'Contraseña',
    enter: 'Iniciar sesión',
    back: 'Regreso',
    send: 'Mandar',
    reset: 'Olvidé la contraseña',
    new_at_binds: 'nuevo en Binds.co?',
    new_account: 'Registrarse',
    login_error: 'El usuario o la contraseña son incorrectos',
    complete_solution: 'La solución completa para su servicio al cliente y encuestas',
    custon_indicators: 'Indicadores personalizados',
    beyond_NPS: 'Más allá del NPS',
    managers_notifications: 'Notificaciones para gestores',
    in_depth_reports: 'Informes completos',
    easy_integrations: 'Integración fácil',
    language_select: 'Seleccione el idioma',
    alert: '¡Advertencia importante!',
    alert_v1_redirect: 'Esta cuenta está utilizando nuestra versión anterior de la plataforma, haga clic en "Redirigir" para acceder a la ubicación correcta.',
    redirect: 'Redirigir',
    ready: 'Todo listo para que empieces. ¡Acceso!'
  },
  resetpass: {
    title: 'Nueva contraseña para',
    pass1: 'Nueva contraseña',
    pass2: 'Confirmar nueva contraseña',
    email_sent: 'Enviamos la contraseña a su correo electrónico',
    fill_in: 'Complete los campos correctamente.',
    code_expired: 'El enlace para restablecer la contraseña ha caducado. Por favor, solicite uno nuevo.',
    save: 'Ahorrar'
  },
  signup: {
    form_title: 'Crea tu cuenta en segundos',
    company: 'Nombre de empresa',
    phone: 'Teléfono',
    email: 'Correo electrónico',
    password: 'Contraseña',
    name: 'Nombre',
    enter: 'Entrar',
    incorrect_field_value: 'Por favor complete este campo con un valor válido',
    create_account: 'Crea cuenta',
    have_account: '¿Ya tienes una cuenta?',
    agreement: 'Al hacer clic en este botón, está de acuerdo con nuestra',
    use_terms: 'Términos del servicio',
    draft_saved: '¡Borrador salvo!',
    save_draft: 'Guardar borrador',
    draft_not_saved: 'Se produjo un error al guardar',
    save_and_send: 'Guardar y enviar',
    congratulation: '¡Felicitaciones, su cuenta ha sido creada! Enviamos un correo electrónico para configurar su contraseña.'
  },
  metadata: {
    title_resend_metadata: 'Reenvio',
    manageFields: 'Administrar campos personalizados',
    title: 'Campos personalizados',
    description: 'Administra los campos personalizados de tu cuenta',
    text_type: 'Texto',
    field: 'Campo',
    label: 'Etiqueta',
    type: 'Tipo',
    groupable: 'Hacer clasificable',
    enable_insight: 'Insights',
    error_enable_insight: 'Solo puedes tener 3 campos con insights activos',
    ranking: 'Por ejemplo: imagina que creas un campo personalizado llamado "ciudad", luego podrías ver una clasificación de respuestas por "ciudad" si usaste este campo al activar tu encuesta por correo electrónico / teléfono.',
    new: 'Nuevo campo personalizado',
    add_field: 'Agregue campo',
    error_add: 'Ya existe un campo con este nombre'
  },
  tickets: {
    description: 'Cierra el ciclo de atención al cliente con negociaciones vía email.',
    open_onsurvey: 'Apertura automática del asistencia',
    no_ticket: 'Sin asistencia seleccionada.',
    first_contact: 'Primer intento de contacto',
    attendants_history: 'Historial de asistentes',
    select_survey: 'Seleccione la búsqueda',
    category: {
      send_now: 'Ahora',
      send_24h: '24 Horas',
      send_7d: '7 Días',
      send_30d: '30 Días',
      head_title: 'Categoría',
      add: 'Añadir categoría',
      new_category: 'Nombre',
      created: 'Categoría creada',
      created_error: 'Error =(',
      deleted: 'categoría eliminada',
      to_edit: 'Editar',
      select: 'Seleccione',
      add_sub: 'Sub Categoría',
      edited_success: 'Categoría editada',
      no_category: 'No hay categoría'
    },
    title: 'Asistencia',
    questions: 'Preguntas',
    select_answer: 'Seleccione una respuesta',
    no_message: 'Sin mensaje',
    error_create_auto_message: 'El título y el cuerpo del mensaje son obligatorios.',
    error_delete_msg: 'No se puede eliminar el mensaje.',
    no_categories: 'Sin categoría',
    no_attendant: 'Sin asistente',
    attendant: 'Asistente',
    apply: 'Aplicar',
    cancel: 'Cancelar',
    filled: 'Lleno',
    unfilled: 'No llenado',
    create: 'Crear asistencia',
    choose_attendant: 'Elige al asistente',
    new_ticket: 'Nueva asistencia',
    success_msg: '¡Asistencia creada!',
    error_msg: 'Error al crear la asistencia',
    by: 'Por',
    closed: 'Asistencia cerrada',
    opened: 'Asistencia abierta',
    history: 'Historial de asistentes',
    see_ticket: 'Ver asistencia',
    all_categories: 'Categorías',
    no_items: 'No hay asistencias para mostrar',
    no_more: 'No más asistencias',
    load_more: 'Carga más',
    status: {
      all: 'Todos estados',
      opened: 'Abierto',
      assigned: 'Asignado',
      unassigned: 'Sin asignar',
      closed: 'Cerrado'
    },
    search: 'Buscar por nombre o correo electrónico',
    any_attendant: 'Cualquier asistente',
    close_ticket: 'Cerrar asistencia',
    open_ticket: 'Abrir asistencia',
    confirm_action_text: '¿Estás seguro de que quieres cerrar este servicio?',
    confirm_action: 'Confirmar acción',
    select_category: 'Seleccione categoría',
    change_attendant: 'Cambiar asistente',
    change_category: 'Cambiar categoría',
    transfer: 'Transferir',
    last: 'Último status',
    events: {
      created: 'asistencia creado',
      transfered: 'transfirió la asistencia a',
      opened: 'abrió la asistencia',
      closed: 'cerró la asistencia',
      categorized: 'categorizó la asistencia como',
      no_category: 'categorizó la asistencia como: sin categoria'
    }
  },
  profile: {
    title: 'Mi perfil',
    change_pass: 'Cambia la contraseña',
    name: 'Nombre:',
    email: 'Correo electrónico:',
    phone: 'Teléfono:',
    password: 'Contraseña:',
    confirm: 'Confirmar la contraseña:',
    wrong_pass: 'Las contraseñas no coinciden.',
    sms: 'Acepto recibir alertas por SMS definidos por el administrador',
    news: 'Notificarme sobre actualizaciones de la herramienta',
    save: 'Guardar',
    success: '¡Éxito!',
    error: 'Algo salió mal. Verifica los datos',
    delete_metadata: '¿Realmente desea eliminar este campo personalizado?',
    smsAlert: 'Alerta de SMS no activada. Ingrese su número telefónico',
    personalInfo: 'Informaciones personales',
    otherInfo: 'Otras informaciones'
  },
  sendConfig: {
    success: '¡Éxito!',
    error: '¡Uy! Ha ocurrido un error inesperado.',
    seconds: 'Segundos',
    minutes: 'Minutos',
    hours: 'Horas',
    days: 'Dias',
    weeks: 'Semanas',
    months: 'Meses',
    interval_title: 'Configuración de envío',
    period: 'Período',
    interval_subtitle: 'Enviar bloqueo',
    expiration_title: 'Hora de vencimiento de la búsqueda',
    expiration_subtitle: 'Establece el límite de tiempo máximo para responder una encuesta después del envío',
    select: 'Seleccione',
    interval: 'Intervalo',
    description: 'Impide el envío al mismo destinatario durante el intervalo de tiempo',
    block: 'Bloque de búsqueda',
    block_tooltip: 'El bloqueo se puede activar por búsqueda o por defecto para todos los envíos.'
  },
  role: {
    new: 'Crear nuevo grupo',
    user_title: 'Usuários',
    groups: 'Grupos',
    name_group: 'Nombre',
    search: 'Búsqueda',
    value: 'Valor',
    add_field: 'Agregue campo',
    delete_field: 'Borrar campo',
    group_updatedAt: 'Última actualización',
    group_createdAt: 'Fecha de creación',
    search_group: 'Grupo de búsqueda',
    can_send_title: 'Encuestas que este grupo puede enviar',
    can_send_description: 'déjelo en blanco si no desea filtrar por fuente',
    can_edit_title: 'Búsquedas a las que puede acceder este grupo',
    can_edit_description: 'déjelo en blanco si no desea filtrar por búsquedas',
    filter_title: 'Filtro de contenido',
    filter_description: 'cuando se define, restringe el acceso al contenido que coincide con este filtro',
    create: 'Crea un grupo',
    delete: 'Eliminar grupo',
    update: 'Actualizar grupo',
    confirm_action: 'Confirma esta acción',
    delete_item: 'Estas seguro?',
    delete_group_confirm: 'Quieres eliminar el grupo ?',
    select_users: 'Seleccionar usuarios para este grupo',
    deleted: 'Grupo Excluido',
    empty_group: 'Todavía no tienes un grupo',
    empty_group_msg: 'Cree reglas de uso personalizadas para grupos de usuarios específicos.',
    error_group: 'No se puede eliminar un grupo con usuarios relacionados'
  },
  menu: {
    panel: 'Mi panel',
    surveys: 'Encuestas',
    user_role: 'Usuario / Permiso',
    reports: 'Informes',
    textual_analysis: 'Analisis textual',
    approaches: 'Enfoques',
    my_profile: 'Mi perfil',
    my_account: 'Mi cuenta',
    send_setting: 'Configuraciones de envío',
    users_group: 'Grupos de usuarios',
    metadata: 'Campos personalizados',
    help: 'Ayuda',
    help_sub: 'Soporte de cuenta',
    help_user: 'usuario',
    exit: 'Salida',
    accounts: 'Listado de cuentas',
    change_version: 'Accede a la versión antigua',
    help_chat: 'Soporte de Chat',
    help_email: 'Soporte de Email',
    tickets: 'Atenciones',
    auto_messages: 'Mensajes automáticos'
  },
  searchMenu: {
    advanced_filters: 'Filtros avanzados',
    export_data: 'Exportar datos'
  },
  export: {
    user: 'Exportar usuarios',
    title: 'Exportar',
    generate_pdf: 'Generar PDF',
    cancel: 'Cancelar',
    subtitle: 'La transferencia de archivos puede tardar unos minutos, según la cantidad de registros.',
    time_zone: 'Seleccione la zona horaria',
    delimiter: 'Separador de columnas',
    download: 'Su archivo se está procesando en segundo plano, pronto tendrá acceso a él.',
    download_on_email: 'La exportación se está procesando. Lo recibirás por correo electrónico una vez completado.',
    custom_delimiter: 'Ingrese el separador personalizado',
    without_fields: 'Exportar sin campos personalizados',
    select_fields: 'Seleccione uno o más campos',
    choose_fields: 'Elija campos personalizados para exportar',
    show_ticket_info: 'Exportar con información de servicio',
    choose_field_metadata: 'Seleccione el campo que corresponda al CPF.',
    subtitle_bacen: 'Seleccione el mes y el año para exportar.',
    mounth: 'Mes:',
    year: 'Año:',
    dates_utc: 'Fechas en zona horaria (UTC)',
    dates_local: 'Fechas en la zona horaria local',
    mounth_selected: {
      jan: 'Ene',
      feb: 'Feb',
      mar: 'Mar',
      apr: 'Abr',
      may: 'May',
      jun: 'Jun',
      jul: 'Jul',
      aug: 'Ago',
      sep: 'Sep',
      oct: 'Oct',
      nov: 'Nov',
      dec: 'Dic'
    },
    separators: {
      comma: 'Coma (predeterminado)',
      semicolon: 'Punto y coma',
      tab: 'Pestaña',
      custom: 'Separador personalizado'
    }
  },
  real_time: {
    graphics: 'Gráficos',
    responses: 'Respostas',
    fullscreen: 'Pantalla completa',
    not_answered: 'Esta pregunta no tiene respuestas para mostrar.',
    see_at_responses: 'Haga clic para ver en las evaluaciones',
    auto_scroll: 'Desplazamiento automático',
    see_details: 'Haga clic para ver detalles'
  },
  sentimentals: {
    title: 'Distribución de sentimientos de respuesta',
    data_not_found: 'Datos no encontrados',
    feelings: 'Sentimientos',
    negative: 'Negativo',
    positive: 'Positivo',
    neutral: 'Neutral',
    negatives: 'negativos',
    positives: 'positivos',
    neutrals: 'neutrals',
    total: 'Total',
    answer_text: 'Respuestas de texto',
    gauge_legend: '% de los comentarios son positivos',
    check_date_filters: 'Revisar filtros de fecha',
    comments: 'comentarios',
    gauge_chart_title: 'Puntuación de sentimiento',
    doughnut_chart_title: 'Distribución de sentimientos',
    bar_chart_title: 'Sentimientos a lo largo del tiempo',
    line_chart_title: 'Puntuación de sentimiento a lo largo del tiempo',
    total_comments: 'Comentarios totales',
    comments_up: 'Ha subido el número de comentarios con respecto al período anterior',
    comments_down: 'Ha bajado el número de comentarios con respecto al período anterior'
  },
  account_config: {
    company_name: 'Nombre de la empresa',
    from: 'Remitente',
    email_signature: 'Firma de email',
    colors_settings: 'Personaliza colores',
    blend_mode: 'Eliminar el fondo blanco del logotipo principal',
    text_light: {
      label: 'Color de texto',
      light: 'Claro',
      dark: 'Oscuro'
    },
    reset_password: 'Se envió el correo electrónico de restablecimiento de contraseña.',
    change_password: 'Redefinir Contraseña',
    type_user: 'Tipo de usuario',
    validate_email: 'Email inválido o ya registrado',
    error_create_account: 'Nombre o correo electrónico no válido',
    email: 'Email',
    name: 'Nombre',
    phone: 'Telefone',
    language: 'Idioma',
    user_saved: 'Permisos de usuario actualizados',
    main_image: 'Imagen principal de la empresa',
    main_logo: 'Logotipo principal',
    small_logo: 'Logotipo pequeño',
    created_date: 'Fecha de creación',
    delete_user: 'Quieres eliminar al usuario?',
    delete_confirm: {
      yes: 'Sí',
      no: 'No'
    },
    user_deleted: 'Usuario eliminado',
    error_delete: 'Error al eliminar usuario',
    fromSystem: 'Del sistema',
    fromSurvey: 'De la investigacion',
    primaryColor: 'Color primario',
    secondaryColor: 'Color secundario',
    myCompanyInfo: 'Información de mi empresa',
    configImg: 'Configurar imágenes',
    colors: {
      superiorMenu: 'Menú superior, botones',
      icons: 'Iconos, textos de botos, detalles',
      surveyMenu: 'Menú superior de investigacion'
    },
    phone_input: {
      country_code: 'Código de país',
      choose_country: 'Elige país',
      phone_number: 'Número de teléfono',
      example: 'Ejemplo :'
    }
  },
  isAnonymous: {
    is_anonymous: 'Búsqueda anónima',
    anonymous_auth: 'Solicitar autorización de datos analíticos',
    anonymous_question: 'Autorizar la visualización de respuestas para el análisis de datos',
    anonymous_title: 'Autorización'
  },
  dashboard: {
    survey_deleted_alert: 'Esta encuesta ha sido eliminada.',
    survey_deleted: 'Encuesta Eliminada',
    send_type: 'Tipo de envío',
    option: 'Opción',
    views: {
      average: 'Métrico',
      overtime: 'A tiempo',
      overall: 'Distribución'
    },
    no_elegible_component: 'La encuesta no tiene ningún componente elegible para mostrarse en este informe.',
    add_filter: 'Agregar un filtro',
    include_fav: 'Agregar a favoritos',
    save_and_apply: 'Guardar y aplicar filtro',
    favorites: 'Favoritos',
    use_filter: 'Utilizar filtro',
    clear_filters: 'Limpiar filtros',
    apply_filters: 'Aplicar filtros',
    apply_query: 'Agregar filtros',
    type_value: 'Escribelo el valor de campo',
    type_filter_title: 'Escribelo el título del filtro',
    no_referrals: 'La encuesta seleccionada no tiene indicaciones para mostrar.',
    no_responses: 'No se encontraron respuestas para el período seleccionado.',
    overview: 'Visión General',
    real_time: 'Dashboard',
    individual_responses: 'Evaluaciones',
    individual_response: 'Evaluación',
    no_responses_label: 'Sin reseñas',
    has_filter: 'tiene un filtro',
    config_graphic: 'Para configurar',
    loading_graphic: 'Cargando gráfico...',
    loading_data: 'Cargando ...',
    individual_answers: 'Respuestas',
    referrals: 'Indicaciones',
    referral: 'Recomendación',
    ranking: 'Ranking',
    performance: 'Actuación',
    referred_by: 'Referido por',
    word_cloud: 'Nuvem de palavras',
    therm_not_found: 'Termo no encontrado',
    bubbles: 'Burbujas',
    list: 'Lista',
    period: {
      '24hours': '24 horas',
      '7days': '7 dias',
      '30days': '30 dias',
      '6months': '6 meses',
      '12months': '12 meses'
    },
    legends: 'Leyendas',
    success_update: '¡Actualizado!',
    error_update: 'Error al actualizar',
    update_card: 'Actualizar gráfico',
    missing_question_and_graphic: 'Tienes que elegir tanto la pregunta como el gráfico',
    error_clone_card_text: 'No es posible duplicar tarjetas de tipo texto.',
    create_card: 'Crear gráfico',
    create_new_card: 'Nuevo gráfico',
    choose_title: 'Título',
    add_filter_advanced: 'agregar filtro personalizado',
    filter_advanced: 'Filtro avanzado',
    not_edit_question: 'No se puede editar la pregunta',
    change_what_display: 'Editar gráfico',
    choose_question: 'Elija una pregunta',
    add_new_card: 'Agregar nuevo gráfico',
    save_card: 'Guardar la tarjeta',
    success_delete_card: '¡Tarjeta eliminada con éxito!',
    error_delete_card: 'Error al eliminar la tarjeta.',
    success_clone_card: '¡Tarjeta duplicada con evento!',
    error_clone_card: 'Error al duplicar la tarjeta.',
    you_can: 'Puede crear una tarjeta personalizada a partir de esto',
    no_customization: 'Aún no tiene un panel personalizado para esta encuesta',
    no_customization_subtitle: '¡Personalízalo y vuelve aquí!',
    success_save_config: 'Exito',
    error_save_config: 'Error al guardar la personalización.',
    no_data_to_show: {
      title: 'No hay respuestas para mostrar',
      subtitle: 'Las opiniones de sus clientes se mostrarán aquí.',
      unnamed: 'Sin nombre'
    },
    graphics: {
      delete_graphic: '¿Quieres eliminar este gráfico?',
      clone_graphic: '¿Quieres duplicar este gráfico?',
      actions: 'Comportamiento',
      showing_responses: 'Por respuesta',
      showing_average: 'Por media',
      average: 'Media',
      nvs_op1: 'Significativamente mejor',
      nvs_op2: 'Un poco mejor',
      nvs_op3: 'Ni mejor, ni peor',
      nvs_op4: 'Un poco peor',
      nvs_op5: 'Significativamente peor',
      no_overtime_matrix: 'No hay gráfico a lo largo del tiempo para el componente de la matriz.',
      vu: 'Muy insatisfecho',
      un: 'Insatisfecho',
      ind: 'Indiferente',
      sat: 'Satisfecho',
      satisf: 'Satisfactorio',
      vs: 'Muy satisfecho',
      vb: 'Muy malo',
      bd: 'Malo',
      st: 'Bueno',
      vg: 'Muy bueno',
      ex: 'Excelente',
      le: 'Pequeño esfuerzo',
      me: 'Mucho esfuerzo',
      ee: 'Esfuerzo esperado',
      op1: 'Sí  ',
      op2: 'No',
      op3: 'No lo sé',
      op4: 'Quizás',
      op: 'Opción',
      detractors: 'Detractores',
      promoters: 'Promotores',
      neutral: 'Neutral',
      detractor: 'Detractor',
      promoter: 'Promotor',
      neutralSing: 'Neutral',
      neutralString: 'Neutral',
      generated_palette: 'Paleta de colores generada:',
      delete_card: 'Eliminar gráfico',
      clone_card: 'Clonar gráfico',
      choose: 'Seleccione un gráfico',
      choose_display: 'Elija el tipo de pantalla',
      choose_display_graphics: 'Elija el tipo de visualización del gráfico:',
      by_responses: 'Para obtener respuestas',
      by_metric: 'Por métrica',
      change_question: 'Elige la pregunta base:',
      options: 'Opciones de gráficos:',
      choose_color: 'Elige el color principal de tu gráfico:',
      percentage_distribution: 'Distribución porcentual',
      online_distribution: 'Distribución en línea',
      gauge: 'Calibre',
      percentage_bar: 'Barra com porcentaje',
      single_line: 'Línea',
      lines: 'Líneas',
      bar: 'Barras',
      horizontal_bar: 'Barra horizontal',
      vertical_bar: 'Barra Vertical',
      pie: 'Pizza',
      percentage: 'Porcentaje',
      percentage_pie: 'Pizza con porcentaje',
      bubbles: 'Burbujas',
      stacked: 'Barras apiladas',
      table: 'Tabla',
      text_color: 'Elige el color del texto:',
      customized_dash: 'Personalizado',
      selected_option: 'Opción seleccionada:',
      total: 'total',
      likes_in_time: 'de me gusta en el tiempo'
    },
    textUi: {
      choices: 'Numero de opciones:',
      option: 'Opción más elegida:'
    },
    gauge_analytics: {
      labels: {
        terrible: 'Terrible! Recomendamos acciones rápidas para cambiar esta percepción en sus clientes',
        bad: '¡Malo! Hay muchas oportunidades de mejora.',
        good: 'Bueno! Pero posiblemente haya muchas oportunidades para mejorar',
        great: 'Genial! Su empresa ya tiene una gran percepción con los clientes.',
        excellent: 'Excelente! Estás en un grado de excelencia con tus clientes'
      },
      labels_nvs: {
        bad: 'Malo, necesita mejorar',
        moderate: 'Moderado, puede mejorar',
        good: 'Bien',
        great: 'Genial',
        excellent: 'Excepcional'
      },
      metrics: {
        NPS: {
          bad2: '-51 a -100',
          bad1: '-1 a -50',
          good: '0 a 49',
          great: '50 a 74',
          excellent: '75 a 100'
        },
        CSAT: {
          oneTo5: {
            bad: '0 a 29.9',
            good: '30 a 49.9',
            great: '50 a 79.9',
            excellent: '80 a 100'
          },
          zeroTo10: {
            bad: '0 a 29.9',
            good: '30 a 49.9',
            great: '50 a 79.9',
            excellent: '80 a 100'
          }
        }
      }
    },
    survey_overview: 'Resumen de sus encuestas',
    recent_surveys: 'Encuestas recientes',
    see_all: 'Ver todas las encuestas',
    see_more: 'Ver Más',
    sendings: 'Envío',
    openings: 'Abierto',
    drafts: 'Borradores',
    paused: 'Pausada',
    surveys: 'Encuestas',
    responses: 'Respuestas',
    unsubscribes: 'Darse de baja',
    spam: 'Spam',
    clicks: 'Clicks',
    conclusion_rate: 'Tasa de finalización',
    average_time: 'Tiempo medio',
    title: 'Elija una encuesta del menú de arriba',
    subtitle: '¡y analiza las respuestas con nuestros gráficos y listas!',
    y: 'Si',
    n: 'No',
    mb: 'Talvez',
    not_found: 'Gráfico no encontrado, revisar filtros',
    most_choosen: {
      1: 'Esta fue la alternativa más elegida entre las opciones, el ',
      2: ' de los usuarios optó por ella.',
      3: 'Este fue el valor más elegido entre las opciones, con un total de ',
      4: ' opciones.'
    },
    no_response: 'Sin respuesta.',
    msg_no_response: 'Parece que aún no ha habido respuestas.',
    no_name: 'No name informed',
    no_attendant: 'Asistente no encontrado',
    no_email: 'Nombre no informado',
    no_phone: 'Telefone no informado',
    not_finished: 'Sin terminar',
    mail_not_opened: 'Correo electrónico no abierto',
    mail_not_send: 'Correo electrónico no enviado',
    attend: 'Contestar',
    send: 'Envío',
    answer: 'Respuesta',
    opening: 'Apertura',
    done: 'Finalizado',
    matrix_component: 'Componente de matriz'
  },
  dashboard_card: {
    anwsers: 'Respuesta del | Respuestas del',
    sendings: '{count} enfoque | {count} enfoques',
    average_time: 'Tiempo medio: {count} min',
    anwsers_link: 'Respuestas',
    edit: 'Editar',
    clone: 'Duplicar',
    tv_dash: 'Dash',
    delete: 'Eliminar',
    rate: 'Tasa de respuesta'
  },
  performance: {
    select: 'Seleccionar',
    select_two: 'Seleccionar',
    selected: 'Seleccionado',
    to_remove: 'Para eliminar',
    mark_off: 'Marcar',
    max_elements: 'Se alcanzaron el máximo de selecciones. Elimina una opción seleccionada para seleccionar otra.',
    no_result: 'Ningún resultado encontrado. Considere cambiar la consulta de búsqueda.',
    no_options: 'La lista está vacía.',
    surveys_selected: 'búsquedas seleccionadas',
    choose_period: 'Eligir período',
    choose_param: 'Eligir parámetro',
    ten_surveys: 'Elija hasta 10 búsquedas',
    ungroup: 'Ver encuestas desagrupadas',
    between_surveys: 'Rendimiento de búsqueda cruzada',
    overtime_between_surveys: 'Rendimiento entre búsquedas a lo largo del tiempo',
    sendings: 'Envíos',
    opening_rate: 'Tasa de apertura',
    answer_rate: 'Tasa de respuesta',
    complete_response_rate: 'Tasa de respuesta completa',
    abandonment_rate: 'Tasa de abandono',
    response_time: 'Tiempo de respuesta medio (en minutos)',
    day: '24 HORAS',
    week: '7 DÍAS',
    month: '30 DÍAS',
    semester: '6 MESES',
    year: '12 MESES',
    only_ten: 'Puedes seleccionar un máximo de 10 búsquedas'
  },
  survey_store: {
    input_add_label: 'Agregar indicacións',
    input_name_placeholder: 'Nombre',
    input_email_placeholder: 'Email',
    input_phone_placeholder: 'Número de teléfono',
    input_url_placeholder: 'URL',
    input_textarea: 'Digite aquí',
    input_name: 'Escriba su nombre',
    options: {
      name: 'Nombre',
      email: 'Correo electrónico',
      phone: 'Teléfono',
      other: 'Otro'
    }
  },
  start: {
    from_stratch: 'Empezar desde cero',
    from_template: 'Elegir un modelo',
    survey_title: 'Título de la encuesta',
    survey_title_placeholder: 'Título de la encuesta',
    title: 'Vamos a empezar su primera encuesta',
    description: 'Usted puede iniciar una encuesta desde cero y construir cada detalle o através de un modelo valida por nuestros expertos',
    modal_survey_title: 'Ingrese el nombre de búsqueda',
    confirm_email: 'Por favor, confirme su correo electrónico para poder enviar la búsqueda',
    confirm_email_first: 'Confirme su cuenta a través del correo electrónico que enviamos para usted, es posible reenviar la confirmación haciendo clic en este botón abajo',
    confirm_email_title: 'Confirme su cuenta para continuar',
    confirm_email_resend: 'Adelante!',
    confirm_email_resended: 'Correo electrónico reenviado!',
    create: 'Crear',
    error_creating: 'Error al crear encuesta.',
    survey_templates: 'Modelos de Encuesta',
    template_title_error: 'Título de búsqueda obligatorio.'
  },
  survey: {
    settings: {
      set_question: 'Configurar pregunta',
      set_messages: 'Configurar mensajes',
      set_theme: 'Configurar tema',
      set_responses: 'Configurar respuestas',
      question: 'Pregunta',
      conditions: 'Condiciones',
      messages: 'Mensajes',
      theme: 'Tema',
      responses: 'Respuestas',
      colors: 'Colores',
      logos: 'Logotipos',
      animations: 'Animaciones',
      locks: 'Bloqueos',
      anonymity: 'Anonimato',
      start_msg: 'Mesaje inicial',
      end_msg: 'Mesaje final',
      answered_msg: 'Mesaje ya respondido',
      no_condition_end_message: 'El mensaje predeterminado no se puede reposicionar ni condicionar.'
    },
    pt: 'Portugués',
    en: 'Inglés',
    es: 'Español',
    matrix: 'matriz',
    text: 'texto',
    referral: 'recomendación',
    enum: 'personalizado',
    exit_whithout_save: '¿Seguro que quieres salir?',
    exit_confirm: 'Continuar sin guardar',
    exit_message: 'Es posible que los cambios realizados no se guarden.',
    save_and_open_ticket: 'Guarde una búsqueda antes de comenzar la configuración.',
    save_and_continue: 'Guardar y continuar',
    bold: 'Negrita',
    italic: 'Itálico',
    underlined: 'Subrayada',
    metadata: 'Metadato',
    title_on_reports: 'Título de la pregunta en los informes',
    custom_title: 'Título personalizado',
    show_progress_bar: 'Mostrar la barra de progresión',
    hide_button_previous: 'Ocultar botón Atrás',
    details: 'Detalles',
    metadata_value: 'Valor de campo',
    display_rules: 'Mostrar cuando',
    all_rule: 'Todas las condiciones son verdaderas',
    any_rule: 'Cualquier condición es verdadera',
    is_op: 'si es',
    is_not_op: 'si no es',
    hide_logo: 'Ocultar logo',
    hide_title: 'Ocultar título',
    conditions_title: 'Condiciones',
    for_metadata: 'Para campos personalizados',
    for_responses: 'Para respuestas',
    add_condition: 'Agregar condición',
    new_condition: 'Nueva condición',
    confirm: 'Confirmar',
    count_of_conditions: 'Condiciones totales',
    condition_title: 'Esta pregunta tiene respuesta en el titulo',
    condition_title2: 'Eliminar la indicación de respuesta para mover la pregunta',
    condition_position: '¡Tienes condiciones que dependen de la posición de esta pregunta!',
    condition_position2: 'Configuró flujos condicionales para esta encuesta que involucran la posición de esta pregunta. Si elige mover la pregunta a esta posición, necesitamos que nos permita excluir dichas condiciones. ¿Desea continuar?',
    conditions_msg: '¡Tienes condiciones que dependen de esa pregunta!',
    conditions_msg2: 'Ha configurado flujos condicionales para esta encuesta que involucran esta pregunta. Si elige cambiar la pregunta, se eliminarán todas las condiciones que dependen de ella.',
    auto_messages_title: 'Al eliminar o cambiar el componente de la pregunta, se perderán todas las configuraciones vinculadas a él.',
    auto_messages_msg: '¿Desea continuar?',
    continue: 'Seguir',
    cancel: 'Cancelar',
    lock_time: 'Bloqueo de respuesta por un tiempo',
    send_lock_time: 'Bloqueo de envíos durante un tiempo',
    send_block_description: 'Este bloqueo de envío por búsqueda siempre se considerará si está activo, aunque en la configuración de la cuenta ya haya configurado un bloqueo de envío.',
    expire_lock_time: 'Hacer caducar el envío durante un tiempo',
    expire_block_description: 'Esta regla configura que el encuestado tenga un plazo para responder a la encuesta.',
    config_blocking: 'Configurar bloqueos',
    unique_key: 'Llave única para cerradura',
    unique_key_desc: 'Correo electrónico o teléfono, o campo personalizado',
    config_anonymous: 'Configurar el anonimato',
    seconds: 'Segundos',
    minutes: 'Minutos',
    hours: 'Horas',
    days: 'Dias',
    weeks: 'Semanas',
    months: 'Meses',
    email_or_phone: 'Email o teléfono',
    add_http: 'Agregue http: // o https: // a la url',
    url_redirect: 'URL para redirección',
    time_for_redirect: 'Tiempo para redirigir (en segundos)',
    redirect: 'Redirigir',
    no_animation: 'Sin animación',
    components: {
      csat: 'Monitorear la satisfacción en puntos estratégicos del viaje.',
      nps: 'Monitorear la probabilidad y la lealtad de las recomendaciones',
      kpi: 'Monitorear el desempeño de varios aspectos organizacionales.',
      matrix: 'Evaluar varios elementos a la vez',
      ces: 'Monitorear el esfuerzo del cliente y las posibles dificultades. ',
      text: 'Componentes de texto para su búsqueda',
      referral: 'Solicite referencias de sus clientes',
      enum: 'Presentar una lista de opciones con componentes de opción múltiple',
      nvs: 'Ver la opinión del cliente en comparación con la competencia',
      cev: 'Evalúe el vínculo emocional del cliente con su marca'
    },
    gradient: 'Usar botones de colores (degradado)',
    add_question: 'Agregar pregunta',
    bread_edit: 'Edición de la encuesta',
    bread_setting: 'Configuración de envío',
    bread_send: 'Envío de la encuesta',
    navbar_move: 'Mover',
    navbar_clone: 'Copiar',
    navbar_delete: 'Eliminar',
    select_question: 'Seleccionar mi pregunta',
    back_button: 'Vueltar',
    draft_saved: 'Borrador guardado!',
    save_draft: 'Guardasr borrador',
    draft_not_saved: 'Hubo un error al intentar guardar',
    question_error: 'Seleccione el tipo de pregunta',
    save_and_send: 'Guardar y enviar',
    toggle_title: 'Mostrar titulo',
    toggle_description: 'Mostrar descripcion',
    toggle_action_button: 'Mostrar botón de acción',
    toggle_use_start_message: 'Usar mensaje de inicio',
    toggle_random: 'Random',
    toggle_image_on_top: 'Imagen en la parte superior',
    toggle_logo_on_top: 'Logo en la parte superior',
    toggle_image_in_footer: 'Imagen en el pie de página',
    toggle_allow_come_back: 'Permitir volver después de responder',
    toggle_save_as_template: 'Guardar como modelo',
    toggle_required: 'Respuesta requerida',
    toggle_others: 'Opción de otros',
    template_title_input: 'Título de lo modelo',
    template_description_input: 'Descripción de lo modelo',
    select_template_category: 'Categoría de lo template',
    limit_of_sending: 'Su voz ha excedido el límite de envío gratuito',
    start_button_text: 'Texto del botón',
    embedLink: 'Insertar enlace',
    linkLabel: 'Etiqueta del enlace',
    linkUrl: 'URL',
    language: 'Idioma de la pregunta',
    general_config: 'General',
    messages: 'Mensajes',
    start_message_config: 'Mensaje inicial',
    end_message_config: 'Mensaje final',
    current_question_config: 'Pregunta',
    survey_settings: 'Configuración de la búsqueda',
    survey_already_sent: 'No es posible realizar esa acción. Esta encuesta ya tiene respuestas',
    field_required: 'Campo requerido',
    min_length: 'Mínimo de caracteres:',
    max_length: 'Máximo de caracteres:',
    define_placeholder: 'Instrucción:',
    min_options: 'Mínimo de opciones:',
    max_options: 'Máximo de opciones:',
    limit_answer: 'Limite el número de opciones',
    previous_question_label: 'Etiqueta del botón de pregunta anterior:',
    multiple_button: 'Botón',
    label_others: 'Otra etiqueta',
    answered_message_config: 'Encuesta ya respondida',
    referral_options: 'Opciones de indicación',
    ne_option: 'Nombre + Email',
    np_option: 'Nombre + Teléfono',
    nep_option: 'Nombre + Email + Teléfono',
    nepu_option: 'Nombre + Email + Teléfono + URL',
    change_placeholders: 'Cambiar declaración de campo',
    left_label: 'Etiqueta izquierda',
    right_label: 'Etiqueta derecha',
    choose_survey: 'Elige una búsqueda',
    survey_not_found: 'Búsqueda no encontrada',
    questions_error: 'Error al recuperar las preguntas de la encuesta',
    colors_settings: 'Configuración de las colores',
    logos_settings: 'Configurar logotipos',
    logos_title: 'justo en la parte inferior',
    logos_description: 'Esta imagen se utilizará al final de cada pregunta.',
    add_input: 'Agregue campo',
    show_input: 'Mostrar Campo',
    required_field: 'Campo requerido',
    instruction: 'Instrucción',
    mapping_type: 'Seleccione el tipo de campo',
    delete_input: 'Eliminar campo',
    copy_of: 'Cópia de',
    option_1: 'opción 1',
    option_2: 'opción 2',
    option_3: 'opción 3',
    option_added: 'opción añadida ',
    option_other_added: 'Otra opción añadida',
    others: 'Otros',
    placeholder: 'Placeholder',
    error_saving: 'Error al guardar la búsqueda.',
    required_question_sing_1: 'El campo de pregunta',
    required_question_sing_2: 'es obligatorio.',
    required_question_plu_1: 'Campos de pregunta',
    required_question_plu_2: 'son obligatorios.'
  },
  questionsNav: {
    message_title: 'Inserta el mensaje aquí',
    start_message: 'Mensaje inicial',
    end_message: 'Mensaje final',
    answered_message: 'Ya respondido',
    answered_message_value: 'Esta encuesta ya ha sido respondida',
    add_question: 'Agregar pregunta',
    add_end_message: 'Agregar mensaje final',
    details: {
      value: 'Agregar detalles del mensaje aquí.',
      link: {
        label: 'Texto del enlace',
        value: 'URL'
      }
    },
    sms_default: ': Cuéntanos tu experiencia y ayúdanos a mejorar nuestros servicios.',
    survey: 'Encuesta',
    nextLabel: 'Próximo',
    question_title: 'Título de la pregunta aquí',
    question_value: 'Pregunta aquí'
  },
  theme: {
    background: 'Fondo',
    header: 'Encabezamiento',
    header_text: 'Texto de encabezamiento',
    survey_background: 'Fondo de la encuesta',
    survey_text: 'Texto de la encuesta',
    buttons: 'Botones',
    buttons_text: 'Texto de botones',
    footer: 'Pie de página',
    footer_buttons: 'Botones de pie de página',
    hover_buttons: 'Sombra dos botões',
    footer_text: 'Texto de pie de página',
    clear_theme: 'Limpiar tema',
    save: 'Tema salvo'
  },
  analytics: {
    send_analytics: 'Analizar respuestas',
    sendings: 'Envíos',
    answers: 'Respuestas',
    time: 'Tiempo medio',
    anonymous_reply: 'Respuesta anónima'
  },
  components: {
    csat_satisfied: 'Satisfecha',
    csat_not_satisfied: 'Insatisfecho',
    nes_big_effort: 'Mucho esfuerzo',
    nes_little_effort: 'Poco esfuerzo',
    nps_very_likely: 'Indicaría',
    nps_unlikely: 'No indicaría',
    text: 'Comenta tu respuesta',
    ces: {
      totally_disagree: 'Desacuerdo totalmente',
      disagree: 'Estoy en desacuerdo',
      partially_disagree: 'Parcialmente en desacuerdo',
      neutral: 'Neutral',
      partially_agree: 'Parcialmente de acuerdo',
      agree: 'Estoy de acuerdo',
      totally_agree: 'Concuerdo totalmente'
    }
  },
  sendhub: {
    generate_qrcode: 'Generar QR CODE',
    new_qrcode: 'Generar nuevo QR CODE',
    copy_code: 'Copiar',
    success_copy: 'Código copiado con éxito.',
    error_copy: 'No se pudo copiar.',
    show_code: 'Ver código',
    hide_code: 'Código de cierre',
    yes: 'Sí',
    no: 'No',
    limit: 'Límite de 20 mil líneas, sugerimos enviar por planilla en este caso.',
    limit_wpp: 'Límite de 5000 líneas alcanzado',
    code_to_embed: 'Código para insertar en su sitio web',
    column: 'Columna',
    site_url: 'URL del sitio web',
    widget_closePermanently: 'Cerrar permanentemente',
    widget_position: 'Posición del widget',
    widget_top_left: 'Arriba a la izquierda',
    widget_middle_left: 'Centro izquierda',
    widget_bottom_left: 'Abajo a la izquierda',
    widget_top_right: 'Arriba a la Directo',
    widget_middle_right: 'Centro directo',
    widget_bottom_right: 'Abajo a la derecha',
    widget_bottom: 'Abajo',
    widget_popup: 'Popup',
    widget_button: 'Botón de acción (derecha)',
    widget_buttonB: 'Botón de acción (izquierda)',
    show_close_button: 'Mostrar botón de cierre',
    widget_size: 'Talla (px)',
    height: 'Altura (px)',
    widget_time_to_open: 'Tiempo para abrir (segundos)',
    widget_colors: 'Colores',
    background_color: 'Fondo',
    text_buttons_color: 'Textos de los botones',
    buttons_color: 'Botones',
    texts_color: 'Textos',
    email_colors: 'Colores de correo electrónico',
    type_one_by_one: 'Escriba uno por uno',
    import_contacts: 'Importar contactos',
    file_types: 'Archivo .csv',
    go_to_dashboard: 'Ir al panel',
    emails_with_error: 'Correos electrónicos de error',
    phones_with_error: 'Teléfonos con error',
    validating_survey: 'Enviando la informacion',
    contacts_select: 'Selección de contactos',
    sent_processing: 'El archivo está siendo procesado',
    sent_success: 'Envío procesado!',
    explain_select: 'Puedes agregar contactos uno por uno o importarlos usando una de las opciones al lado. Si lo prefiere, copie y pegue su tabla de contactos aquí. Los contactos se agregarán automáticamente aquí.',
    column_select: 'Seleccione una columna',
    add_line: 'Agregar linea',
    add_column: 'Agregar columna',
    fix_contacts: 'Insertar datos correctos',
    some_contacts: 'No fue enviado para algunos contactos.',
    save: 'Salvar',
    clear: 'Limpiar todo',
    is_to_clean: 'Quieres limpiar todo?',
    copy: 'Copiar',
    name: 'Nombre',
    phone: 'Teléfono',
    email: 'Email',
    from_input: 'Remitente',
    subject_input: 'Asunto',
    email_text: 'Haga clic aquí para añadir el texto del correo electrónico.',
    set_image_text: 'Haga clic aquí para agregar una foto',
    toggle_embedded_question: 'Insertar primera pregunta',
    toggle_image_on_top: 'Imagen superior',
    action_button_text: 'Texto del botón siguiente',
    type_email: 'Por correo electrónico personalizado',
    type_link: 'Generar enlace para enviar como quieras',
    type_sms: 'Enviando un enlace de búsqueda por SMS',
    sms_warn: 'Comuníquese con soporte para habilitar esta opción',
    type_widget: 'Usando nuestro widget en su sitio',
    type_email_and_sms: 'Envío por correo electrónico y SMS',
    type_totens: 'Mostrando terminales físicos',
    toten: 'Enfoque tótem',
    toten_hub_text: 'Es muy fácil ofrecer la encuesta a los visitantes de su tienda o evento, incluso sin registrarse. Simplemente haga que la siguiente dirección esté disponible en un dispositivo conectado a Internet.',
    link: 'Enlace de envío',
    link_survey: 'Enlace de la encuesta',
    duplicate: 'Doble',
    invalid: 'Inválido',
    send_phone: 'Teléfono',
    status: 'Estado',
    sendings_total: 'Envíos totales',
    failures_total: 'Fallos totales',
    duplicates_total: 'Duplicados totales',
    view_more: 'Ver más',
    view_less: 'Ver menos',
    sendings_precessed: 'Envío procesado',
    images: 'Imágenes',
    upload: {
      riskTitle: 'Ya hay un envío en proceso',
      riskMessage: 'Estamos procesando un envío de hoja de cálculo para esta encuesta, si se realiza un nuevo envío, el anterior aún se procesará.',
      riskButton: 'Enviar de todos modos'
    },
    linkHub: {
      allowMoreThanOne: {
        title: 'Permita más de un encuestado',
        description: 'Permite que más de una persona responda a su búsqueda. Es ideal cuando desea publicar su búsqueda en un sitio o en una red social sin recopilar información de los encuestados.'
      },
      showEndSurveyButton: {
        title: 'Mostrar la opción de búsqueda de terminar',
        description: 'Muestra el botón de finalización, para que pueda finalizar la búsqueda en cualquier momento.'
      },
      endSurveyByInactivity: {
        title: 'Salir automáticamente de la búsqueda inactiva',
        description: 'Pausa automáticamente una búsqueda inactiva después de un intervalo de tiempo.'
      },
      colors: {
        button: 'Elija el color del botón',
        background: 'Elige color de fondo',
        font: 'Elija el color de la fuente'
      }
    },
    smsHub: {
      title: 'Editar',
      label: 'Enlace de búsqueda',
      label2: 'Responder',
      charactersLimit: 'Límite de caracteres',
      charactersRemaining: 'Número de caracteres restantes',
      smsMetadata: 'No tienes permiso para firmar metadatos',
      showEndSurveyButton: {
        title: 'Mostrar la opción de búsqueda de terminar',
        description: 'Muestra el botón de finalización, para que pueda finalizar la búsqueda en cualquier momento.'
      },
      endSurveyByInactivity: {
        title: 'Salir automáticamente de la búsqueda inactiva',
        description: 'Pausa automáticamente una búsqueda inactiva después de un intervalo de tiempo.'
      },
      error_messages: 'Error al buscar SMS. Inténtalo de nuevo.'
    },
    emailHub: {
      input_error: 'Complete el campo del remitente y el asunto del correo electrónico',
      input_success: 'Email settings was saved'
    },
    emailSmsHub: {
      step_one: 'Paso 1: Configurar el correo electrónico',
      step_two: 'Paso 2: Configurar el SMS'
    },
    totenHub: {
      shut_in: 'Finalizar encuesta en:',
      seconds: 'segundos'
    },
    empty_table: 'Escriba los contactos en la tabla',
    existing_column: 'Columna existente',
    widget_action_button: {
      startOpen: 'Mostrar al inicio',
      closeActionButton: 'Boton cerrar',
      customizedImage: 'Imagen personalizada'
    },
    configLayout: 'Configurar diseño y tamaño',
    configTitle: 'Configurar título',
    configWidget: 'Configurar Widget',
    font: 'Tipografía',
    textProps: {
      bold: 'Negrita',
      underline: 'Subrayada',
      italic: 'Itálico'
    },
    ofTitle: 'De título',
    ofMessage: 'De mensaje',
    sendInfo: 'Información de envío',
    borders: 'Bordes',
    hints: {
      widgetPosition: 'Posición en la que aparecerá el widget en la pantalla',
      startOpen: 'El contenido del widget aparecerá tan pronto como se cargue la página',
      closeActionButton: 'Botón de acción botón cerrar',
      customizedImage: 'Icono de botón de acción',
      customizedImageLink: 'Vínculo del icono del botón de acción',
      backgroundColorActionButton: 'Color de fondo del botón de acción',
      width: 'Ancho del widget',
      height: 'Altura del widget',
      widgetTimeToOpen: 'Tiempo de apertura del widget',
      borders: 'Curvatura de bordes y botón de cierre',
      showCloseButton: 'Cerrar pantalla de botón',
      closePermanently: 'Al hacer clic en el botón, se cierra el widget de forma permanente',
      font: 'Tipo de fuente, tamaño y decoración',
      backgroundColorTitle: 'Color de fondo del título',
      textButtonColor: 'Color del texto del botón',
      buttonColor: 'Color de fondo del botón',
      textsColor: 'Color del texto del título',
      backgroundColorMessage: 'Color de fondo del mensaje',
      textColorMessage: 'Color del texto del mensaje'
    }
  },
  date_send: {
    when_send: '¿Cuándo deberíamos enviar?',
    send_now: 'Enviar ahora',
    select_date: 'Programar envío',
    date: 'Fecha:',
    schedule: 'Hora:',
    send: 'enviar',
    valid_date: 'Selecciona una fecha válida',
    date_time: 'Seleccione la fecha y hora'
  },
  seeds: {
    copy_data: 'Copiar',
    copy_success: 'Copiados con éxito.',
    copy_fail: 'No se pudieron copiar.',
    schedule_title: 'Si lo desea, programe el envío.',
    schedule_placeholder: 'Programar envío',
    success: 'Envío procesado con éxito.',
    csv_duplicated_headers: 'Hay columnas con nombres repetidos, corríjalo e intente cargarlo nuevamente.',
    csv_special_character_info: 'Los caracteres especiales se convertirán en guiones bajos si se encuentran en los encabezados de las columnas.',
    csv_null_headers: 'Hay columnas sin nombre, corríjalas e intente subirlas de nuevo.',
    csv_column_error: 'SIN NOMBRE',
    csv_empty_headers: 'Mapear las columnas en el 3er paso.',
    spreadsheet_import: 'Importar hoja de cálculo',
    inform_1: 'Con esto puedes activar masivamente la búsqueda.',
    inform_2: 'Importe su hoja de cálculo de carga, confirme que los datos son correctos en la pantalla y luego continúe con el siguiente paso. (Aceptamos solo archivos .csv .xls .xlsx con formato UTF-8)',
    encode_error: 'Solo aceptamos archivos con formato UTF-8',
    download_model: 'Descargar plantilla de hoja de cálculo',
    import_file: 'Importar archivo',
    map_headers: 'Columnas del mapa',
    step: 'Paso',
    step_1: 'Descargar modelo',
    step_2: 'Subir archivo',
    step_3: 'Configurar y enviar',
    next_step: 'Proximo paso',
    previous_step: 'Paso anterior',
    send: 'Enviar',
    preview: 'Esta vista es solo una vista previa',
    count_lines: 'Líneas totales en archivo:',
    map_inform_1: 'Configure qué campos en su hoja de cálculo se refieren a Nombre, Correo electrónico y / o teléfono.',
    map_email: 'Columna de correo electrónico',
    map_phone: 'Columna de teléfono',
    map_name: 'Columna de nombre',
    ignore_column: 'Ignorar columna',
    select: 'Seleccione ...',
    your_file: 'TU ARCHIVO',
    your_match: 'JUNTAR CON',
    file_size: 'El tamaño del archivo debe ser inferior a ',
    file_type: 'Formato de archivo incorrecto, solo aceptamos .CSV / .XLS / .XLSX',
    new_metadata: '+ Crear nuevo campo personalizado',
    fill: 'Rellena los campos'
  },
  templates: {
    error_getting_templates: 'Error al buscar modelos.',
    error_saving_template: 'Error al guardar modelo',
    error_using_template: 'Error al usar modelo.',
    survey_title_template: 'Ingrese el título de búsqueda',
    use_template: 'Usar plantilla',
    edit_template_details: 'Editar los detalles de la plantilla',
    template_title: 'Título de la plantilla',
    template_description: 'Descripción de la plantilla',
    popular: 'Define lo popular',
    draft: 'Sequía'
  },
  emptyState: {
    title: 'No hay respuestas para mostrar',
    description: 'Las opiniones de sus clientes serán mostradas aquí.'
  },
  expired: {
    title: 'Oops!',
    token_expired: 'Su token de recuperación ha caducado',
    try_again: 'Inténtalo de nuevo',
    know_more_binds: 'Conozca binds.co',
    signin: 'Entrar'
  },
  resetPassword: {
    title: 'Nueva contraseña para',
    action: 'Guardar',
    newPass: 'Nueva contraseña',
    confirmPass: 'Confirmar nueva contraseña'
  },
  msg: {
    account: 'Cuenta',
    account_description: 'Descrição da la cuenta'
  },
  global: {
    pick_date: 'Elige la fecha',
    link_copied: 'Enlace copiado',
    unknown_error: 'Se produjo un error desconocido',
    load_more: 'Cargar más'
  },
  http: {
    notFound: 'No se encontró la página a la que intentó acceder :(',
    you_cant: 'Lo siento, pero no tienes permiso para acceder a esta página',
    unmapped: 'Un error desconocido acaba de ocurrir en el servidor'
  },
  surveyList: {
    filter_options: {
      no_filter: 'Sin Filtro',
      title_az: 'Título A-Z',
      title_za: 'Título Z-A',
      recent_date_send: 'Última fecha de envío',
      old_date_send: 'Fecha de envío más temprana',
      most_sent: 'Más Envíos',
      least_sent: 'Menos Envios',
      most_answered: 'Más Respuestas',
      least_answered: 'Menos Respuestas',
      average_time_ascending: 'Tiempo Medio Creciente',
      average_time_descending: 'Tiempo Medio Decreciente',
      expired: 'Venció',
      not_expired: 'No caducado',
      health_ascending: 'Salud Creciente',
      health_descending: 'Salud Decreciente'
    },
    health_survey: 'Buscar salud',
    id_copied: 'ID de pesquisa copiado.',
    expired: 'Esta búsqueda ha caducado. Póngase en contacto con el soporte si desea reactivarlo.',
    my_surveys: 'Mis Encuestas',
    search_by_title: 'Buscar por título',
    active_since: 'Activa desde',
    last_send: 'Último envío',
    copy_id: 'Copiar ID',
    not_send: 'Encuesta no enviada',
    created_since: 'Fecha de creación',
    delete_error: 'Error al eliminar la búsqueda',
    delete_question: '¿Está seguro de que desea eliminar esta encuesta?',
    delete_survey: 'Eliminar encuesta',
    delete_success: '¡Búsqueda eliminada!',
    filter_author: 'Filtrar por autor',
    filter_category: 'Filtrar por categoría',
    filter_type: 'Filtrar por tipo',
    new_survey: 'Nueva Encuesta',
    templates: 'Plantillas',
    error_go_to: 'Algo salió mal. Inténtalo de nuevo.',
    you_cant: 'No tienes permiso para ello.',
    survey_cloned: 'Encuesta clonada.',
    survey_cloned_error: 'Error en la encuesta clonada.',
    no_surveys_msg: 'Aún no tienes encuestas.',
    create_first_survey: '¡Crea tu primera encuesta!',
    click_add_title: 'Haga clic aquí para agregar título',
    click_add_description: 'Haga clic aquí para agregar una descripción',
    clone_survey: 'Duplicar la búsqueda',
    type_title: 'Ingresa el titulo',
    go_templates: 'Ir para plantillas',
    modal_templates_title: '¿Precisando de inspiración?',
    modal_templates_msg: 'Hemos preparado varios ejemplos para usted. Elija una plantilla para personalizar y comience a recopilar respuestas en poco tiempo.',
    expire: 'expirar',
    activate_capital: 'Activar',
    activate: 'activar',
    expire_in: 'Expira en',
    expire_survey: 'Expirar la búsqueda',
    activate_survey: 'Eliminar la fecha de caducidad de la búsqueda',
    survey_expired: 'Búsqueda caducada',
    are_you_sure: '¿Estas seguro que quieres',
    this_survey: 'esta búsqueda?',
    success_expire: 'Fecha de caducidad ingresada con éxito',
    select_date: 'Seleccione una fecha para caducar la encuesta',
    error_expire: 'Error al expirar la búsqueda',
    success_activate: 'Búsqueda activada con éxito',
    error_activate: 'Error al activar la búsqueda'
  },
  advanced_filters: {
    delete_filter: '¿Estas seguro que quieres borrarlo?',
    select_type: 'Seleccione tipo',
    select_option: 'Seleccionar opción',
    select_conditional: 'Seleccionar condicional',
    title: 'Filtros avanzados',
    filter_by_tag: 'Filtro de etiquetas',
    mark_as_solved: '¿Marcado como resuelto?',
    done_at: '¿Respondido hasta el final?',
    response_type_text: 'Respuestas de texto',
    response_text_true: 'Respuestas de texto que se han completado',
    annotations: '¿Contiene anotaciones?',
    contact_name: 'Nombre de contacto',
    contact_email: 'Correo electrónico de contacto',
    contact_phone: 'Teléfono de contacto',
    contact_code: 'Código de contacto',
    contact_cpf: 'Contactar con CPF',
    attendances_created: '¿Asistencias creadas?',
    attendant_name: 'Nombre del asistente',
    ticket_status: 'Estado de la asistencia',
    responded_at: '¿Respondido?',
    clean_empty_questions: 'No muestre preguntas sin respuesta',
    attendant_user: 'Representante de servicio al cliente',
    filter_category: 'Categoría del servicio',
    filter_subcategory: 'Subcategoría del servicio',
    placeholder_category: 'Seleccionar categoría',
    placeholder_subcategory: 'Seleccionar subategoría',
    fields: {
      contains: 'Contiene',
      does_not_contain: 'No contiene',
      is_equal: 'Es lo mismo',
      is_different: 'Es diferente',
      is_define: 'Está definido',
      is_not_define: 'No está definido',
      is_equal_to: 'Igual a',
      is_diffrent_from: 'Es diferente de',
      promoters: 'Promotores',
      neutrals: 'Neutrales',
      detractors: 'Detractores',
      satisfied: 'Satisfecho',
      unsatisfied: 'Insatisfecho',
      less_effort: 'Poco esfuerzo',
      expected_effort: 'Esfuerzo moderado',
      much_effort: 'Muito Esforço',
      yes: 'Si',
      no: 'No',
      sent: 'Enviados',
      rejected: 'Rechazado',
      deferred: 'Aplazado',
      spam: 'Spam',
      bounced: 'Devuelto',
      soft_bounced: 'Devuelto temporalmente',
      Unsub: 'Inscripción cancelada',
      gte: 'Mayor o igual a',
      lte: 'Menos o igual a',
      gt: 'Mayor que',
      lt: 'Menos de'
    },
    categories: {
      tags: 'Tags',
      responses: 'Respostas',
      attendance: 'Atendimento',
      contact: 'Contato',
      metadata: 'Campo personalizado',
      questions: 'Questões',
      survey: 'Pesquisa é',
      channel: 'Canal de envío',
      date_send: 'Fecha de envío'
    }
  },
  tags: {
    open_tag_manager: 'Administrador de etiquetas',
    add_tag: 'Añadir etiqueta',
    edit_tag: 'Editar etiquetas',
    exist_tag: 'Esta etiqueta ya existe',
    confirm: 'Confirmar',
    remove_tag: 'Remover etiqueta',
    create_new_tag: 'Crear nueva etiqueta',
    create: 'crear',
    new_tag_name: 'Nuevo nombre de etiqueta',
    new_tag_color: 'Nuevo color de etiqueta',
    tag_name: 'Nombre de etiqueta',
    tag_color: 'Color de etiqueta',
    update_tag: 'actualizar',
    see_all: 'ver todos',
    see_more: 'ver más',
    alert_remove: '¿Realmente desea eliminar esta etiqueta?',
    subtitle_alert: 'Está a punto de eliminar esta etiqueta.',
    see_more_responses: 'Ver todas las respuestas'
  },
  approaches: {
    status: 'Estado',
    rejected: 'Rechazado',
    deferred: 'Diferida',
    sendings: 'envíos',
    deliveries: 'entregas',
    delivery_rate: 'tasa de entrega',
    openings: 'aperturas',
    clicks: 'clics',
    answers: 'respuestas',
    answer: 'Respuesta',
    non_existing_data: 'Datos inexistentes',
    mark_solved: 'Marca como resuelto',
    response_rate: 'tasa de respuesta',
    bounces: 'Devolucione',
    softBounces: 'Devolucione temporale',
    spam: 'Quejas de spam',
    no_results: 'No hay nada para mostrar. Verifique os filtros.',
    copy_link: 'Copiar enlace',
    archive: 'Archivo',
    delete: 'Eliminar',
    already_archived: 'Ya está arquivada.',
    copied: 'Enlace copiado.',
    profile: 'Perfil del usuario',
    active: 'Activo',
    archived: 'Archivado',
    all: 'Todas',
    user_not_found: 'Usuario no encontrado.',
    unarchive: 'Desarchivar',
    not_answered: 'Sin respuesta',
    not_opened: 'No abierto',
    confirm_delete: 'Está seguro que desea borrar envío y todas sus respuestas?'
  },
  pageUsers: {
    name: 'Nombre',
    user: 'Usuario',
    users: 'Usuarios',
    delete_user: 'Borrar usuario',
    admin: 'Administrador',
    new_user: 'Nuevo usuario',
    type_user: 'Tipo de usuario',
    user_features: 'Características del usuario',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    created_at: 'Fecha de creación',
    activity_at: 'Última actividad',
    search_by_name_email: 'Buscar por nombre o correo electrónico',
    search_group: 'grupo de búsqueda',
    message_group: 'El usuario que no esté en ningún grupo no tendrá restricciones de contenido.',
    portuguese: 'Portugués',
    english: 'Inglés',
    spanish: 'Español',
    all_groups: 'Todos',
    marked_groups: 'Marcado',
    unchecked_groups: 'Desenfrenado',
    new_account_info: 'Información de cuenta nueva',
    account_info: 'Información de la cuenta',
    profile_config: 'Ajustes de perfil',
    group_config: 'Configuração de grupo',
    request_password: 'Pide nueva contraseña',
    cancel: 'Cancelar',
    tip_password: 'Se definirá una nueva contraseña a través de su correo electrónico registrado',
    editUser: 'Edit user',

    tooltip_contact: 'Si se activa la opción "Ocultar contactos", la opción "Llamadas" se desactivará automáticamente.',
    tooltip_metadata: 'Si se activa la opción "Ocultar metadatos", la opción "Edición de metadatos" se desactivará automáticamente.'
  },
  ranking: {
    moreAnswered: 'La mayoría respondió',
    lessAnswered: 'Menos respondió',
    highlights: 'Destacados',
    search: 'Buscar por metadatos',

    hint_nps: 'Evaluación por métrica',
    hint_enum: 'Número de opciones seleccionadas',

    best: 'Mejor calificado',
    worse: 'Peor calificado',
    movimentations: 'Movimientos',
    comparison: 'En comparación con el último período',
    metadata: 'Metadato',
    quantity: 'Ctdad',
    responses: 'Respuestas',
    nothing_to_show: 'No tenemos artículos para mostrar aquí. ¡Revisa los filtros!',
    custom_field: 'Campo personalizado',
    question: 'Pregunta',
    min_sample: 'Muestra mínima',
    first_metadata1: 'Aún no tienes campos personalizados',
    first_metadata2: '(con la opción "clasificable" activa)',
    redirect_to_metadata: 'Crear campo clasificable',
    noQuestions: 'La encuesta no tiene preguntas que se puedan mostrar en el ranking',
    cannotExport: 'La encuesta no se puede exportar, verifique los filtros',

    // export
    positionVariation: 'Variación de posición',
    scoreVariation: 'Variación',
    score: 'Puntuación',
    position: 'Posición',
    average: 'Promedio',
    beforeResult: 'Resultado anterior',
    // metrics
    abbreviation: {
      detractor: 'D',
      neutral: 'N',
      promoter: 'P',
      yes: 'S',
      no: 'N',
      maybe: 'T',
      insatisfied: 'INSAT',
      satisfied: 'SAT',
      mutch_effort: 'ME',
      expected_effort: 'EE',
      less_effort: 'LE',
      don_t_know: 'NS',
      excellent: 'EX',
      very_good: 'MB',
      bad: 'MA',
      very_bad: 'MR',
      significantly_better: 'SM',
      a_little_better: 'PM',
      neither_better_nor_worse: 'N',
      a_little_worse: 'PP',
      significantly_worse: 'SP'
    }
  },
  notifications: {
    notify_settings: 'Configuración de notificaciones',
    notify_settings_health: 'Configurar notificaciones de salud',
    notifications: 'Notificaciones',
    no_group: 'No hay grupos registrados',
    apply: 'Aplicar',
    cancel: 'Cancelar',
    roles: 'Grupos',
    users: 'Usuarios',
    smsDisabled: 'Envío de SMS está inhabilitado para este usuario'
  },
  redirect: {
    redirect: 'Redirigir',
    time_Redirect: 'Tiempo para redirigir (en segundos)',
    url_redirection: 'URL para redireccionamiento',
    erro_redirect: 'Adicione http: // ou https: // na url'
  },
  TWO_FACTOR: {
    auth: 'Autenticación de dos factores',
    verification_code: 'Código de verificación',
    we_send_a_code: 'Enviamos un código de verificación a',
    inside_account: 'registrado',
    inform_the_code: ', ingrese el código para continuar.',
    send_new_code_by: 'Enviar nuevo código por',
    wait_to_send_again: 'Esperar para enviar de nuevo',
    dialog: {
      expired_code_message: 'Su código ha expirado, hemos enviado uno nuevo, por favor espere.',
      fill_in: 'Complete todos los campos para continuar.',
      success_send: 'Código reenviado correctamente'
    }
  },
  userFeatures: {
    questionsMetadata: 'Editor avanzado',
    seedBatches: 'Envíos en lote',
    metadataEditing: 'Edición de metadatos',
    sendingsSurveyLink: 'Envíos del enlace de encuesta',
    sendings: 'Envíos',
    bindsSupport: 'Suporte Binds',
    trackTerms: 'Binds Voice Score',
    surveyLink: 'Compartir el enlace de la encuesta',
    tickets: 'Llamadas',
    surveyEdit: 'Edición de búsquedas',
    loginWithAzure: 'Iniciar sesión con SSO',
    hiddenSendingContacts: 'Ocultar contactos',
    hiddenSendingMetadata: 'Ocultar metadatos',
    disableExports: 'Deshabilitar exportación'
  },
  pageTitles: {
    login: 'Acceso',
    dashboard: 'Crea tu encuesta',
    surveys: 'Búsquedas',
    template: 'modelos de investigacion',
    send: 'Presentación de investigación',
    email: 'E-mail - Presentación de investigación',
    sms: 'SMS - Presentación de investigación',
    widget: 'Widget - Presentación de investigación',
    link: 'Link - Presentación de investigación',
    totem: 'Totem - Presentación de investigación',
    panel: 'Mi tablero',
    overview: 'Resumen de la encuesta',
    individual: 'Respuestas a la encuesta',
    referrals: 'Indicaciones',
    ranking: 'Clasificación',
    performance: 'Rendimiento de la investigación',
    realtime: 'Resultado en tiempo real',
    approaches: 'Enfoques de investigación',
    cloud_words: 'Nube de palabras',
    sentimental: 'Sentimiento',
    ticket: 'Atenciones',
    users: 'Usuarios',
    roles: 'Grupo de usuario',
    account_list: 'Lista de cuentas'
  },
  preview: {
    view: 'Visualización',
    add_fields: 'Agregar los campos personalizados para probar el flujo condicional',
    field_value: 'Valor del campo',
    field: 'Campo',
    clear_all: 'Borrar todo',
    name: 'Nombre',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    added_message: 'Este campo ya ha sido agregado, pero puedes cambiar su valor aquí.',
    value_changed: '¡El valor ha sido cambiado!',
    add_tooltip: 'Agregar campo',
    select_field: 'Seleccione el campo',
    simulate_survey: 'Simular búsqueda',
    reload_survey: 'Recargar búsqueda'
  },
  bvs: {
    name: 'Nombre',
    deselect_all: 'Deseleccionar todo',
    search_on_twitter: 'Buscar en twitter',
    current_brands: 'Marcas actuales',
    no_data: 'No hay datos para mostrar, verifique los filtros.',
    binds_voice_score: 'Binds Voice Score',
    date_to: 'Hasta',
    date_from: 'Seleccione el período',
    filter_sentiment: 'Sentimiento de filtro',
    filter_sentiment_placeholder: 'Todo',
    search_text: 'Buscar texto',
    search_text_placeholder: 'Buscar texto',
    filter_user: 'Filtrar por usuario',
    user_selecteds: 'usuarios seleccionados',
    all_users_selecteds: 'Usuarios',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    search: 'Buscar',
    sentiment_overtime_title: 'Menciones a lo largo del tiempo',
    sentiment_synthetic_title: 'Binds Voice Score',
    sentiment_mentions_title: 'Menciones',
    sentiment_word_cloud_title: 'Etiquetas',
    sentiment_bvs: 'BVS',
    sentiment_nps: 'NPS',
    sentiment_mentions: 'MENCIONES',
    word_cloud_quote: 'Cita',
    word_cloud_amount: 'Cantidad',
    word_cloud: 'nube de palabras',
    word_cloud_list: 'Lista',
    modal_title: 'Binds Voice Score',
    modal_step1: 'Buscando tu marca...',
    modal_step2: 'Extrayendo de twitter y otros lugares...',
    modal_step3: 'Analizando sentimientos...',
    modal_step4: 'Preparando el primer grupo de menciones...',
    modal_step5: 'Ajuste de los gráficos...',
    modal_step6: 'Ya casi está listo, vuelve en unos minutos...',
    search_not_found: 'Usuario no encontrado o ya existe en la lista de sugerencias.',
    theme: 'Claro',
    type_overtime: 'Cantidad',
    delete_term: 'Eliminar',
    updating_data: 'Buscando datos...',
    config_users: 'Configurar usuarios',
    social_media: 'Redes sociales',
    document_title: 'Mis fuentes',
    placeholder: 'Introduzca su nombre de usuario',
    not_found: 'No encontrado',
    user_exist: 'Ya esta en tu lista',
    create_custom_brand: 'Crea tu propia marca personalizada',
    create_success: 'Creado con éxito',
    verified_user: 'Usuario verificado',
    create_date: 'Fecha de creación',
    public_brand: 'Marca pública',
    custom_brand: 'Marca personalizada',
    has_terms: 'Tiene términos',
    looking_for_posts: 'Estamos buscando publicaciones para esta marca.',
    delete: 'Borrar',
    add: 'Agregar',
    change: 'Alterar',
    edit: 'Editar',
    want_to_add: '¿Quieres agregar esta etiqueta?',
    subtitle_to_add: 'Está a punto de agregar esta etiqueta a su cuenta.',
    want_to_remove: '¿Realmente desea eliminar esta etiqueta?',
    subtitle_to_remove: 'Está a punto de eliminar esta etiqueta de su cuenta.',
    limit_reached: 'Límite de cuenta alcanzado',
    change_name_title: '¿Quieres cambiar el nombre de tu marca?',
    change_name_subtitle: 'Está a punto de cambiar el nombre de su marca.',
    sentiment: {
      all: 'Todo',
      positive: 'Comentarios positivos',
      neutral: 'Comentarios neutrales',
      negative: 'Comentarios negativos'
    },
    share: {
      comments: 'Comentarios',
      retweets: 'Retweets',
      likes: 'Gustos',
      view_on_page: 'Ver en la página'
    },
    tab_yours_brands: 'Tus marcas',
    tab_upload: 'Upload',
    tab_history: 'Histórico',
    history: {
      title: 'Histórico de uploads',
      total_registers: 'Registros',
      all: 'Todo',
      total: 'Total',
      done: 'Concluido',
      processing: 'Procesando',
      pending: 'Pendiente',
      error: 'Error',
      title_list_user: 'Usuario',
      title_list_brand: 'Marca',
      title_list_date: 'Fecha',
      title_list_status: 'Estado',
      title_list_rows: 'Número de líneas',
      title_list_rows_created: 'Ultima línea creada'
    },
    upload: {
      title: 'Al importar su hoja de cálculo para enviarla, confirme que los datos son correctos.',
      title_date: 'El formato de fecha debe estar en:',
      title_format: {
        _1: 'Solo aceptamos archivos',
        _2: '.csv .xls .xlsx',
        _3: 'con formato',
        _4: 'UTF-8'
      },
      import_file: 'Importar archivo',
      max_size_file: 'Tamaño máximo de archivo:',
      fields_required: 'Campos obligatórios:',
      text: 'Texto',
      save: 'Ahorrar',
      back: 'Volver',
      next: 'Próximo'
    }
  },
  whatsapp: {
    hello: 'Hola',
    text_default: 'Cuéntanos tu experiencia y ayúdanos a mejorar nuestros servicios:',
    text_footer: 'Su opinión es muy importante para nosotros',
    text_footer_2: '- es rápido -',
    text_button: 'Responder',
    explain_dynamic_text: 'Texto dinámico obligatorio, sujeto a cambios ocasionales según nuestras plantillas META.',
    today: 'Hoy',
    title_template: 'Seleccione su plantilla',
    config_template: 'Configurar plantilla',
    config_name: 'Configurar nombre para mostrar',
    config_img: 'Configurar imagen de visualización',
    name_account: 'Nombre de la cuenta',
    name_user: 'Nombre de usuario',
    tooltip_help_name: 'Nombre utilizado en la pantalla de mensajes, límite de caracteres: 120.',
    tooltip_help_image: 'Imagen utilizada en la visualización del cuerpo del mensaje',
    limit_exceeded: 'Límite de envío superior al permitido',
    alert_title_empty: 'Nombre para mostrar vacío. El mensaje no ha sido actualizado.'
  },
  resend: {
    tooltip_info: 'Reenviar sólo considera búsquedas activas y abordajes como E-mail, SMS y Whatsapp.',
    label_resend: 'Reenviar',
    resend: 'Reenviar',
    title_schedule: 'Horarios',
    label_schedule: 'horarios',
    search_by_title: 'Buscar por título',
    select_resend: 'Seleccione Envios',
    selected_surveys: 'Envios seleccionadas',
    config_resend: 'Configurar',
    advance: 'Avance',
    cancel_resend: 'Cancelar envío',
    total: 'Total',
    type: {
      email: 'E-mail',
      sms: 'SMS',
      whatsapp: 'Whatsapp'
    },
    survey: 'Pesquisa',
    type_resend: 'Tipos de envío',
    details_resend: 'Resumen de envío',
    total_approaches: 'Enfoques totales',
    close: 'Cerrar',
    cancel: 'Cancelar',
    continue: 'Continuar',
    back: 'para volver',
    order_by: 'Ordenar por',
    select_field: 'Seleccione el campo',
    create: 'Crear',
    field: 'Campo',
    label: 'Etiqueta',
    value: 'Valor',
    remove: 'Para eliminar',
    confirm: 'Confirmar',
    field_already_exists: 'Este campo ya existe',
    created_success: 'Creado con éxito',
    select_date: 'Seleccione fecha',
    write_here: 'Digite aquí',
    title_resend: '¿Quieres agregar un título para esta recarga?',
    title_cancel_resend: '¿Realmente deseas cancelar este reenvío?',
    label_cancel_resend: 'Estás a punto de cancelar este reenvío.',
    no_data_list: 'No hay horarios para mostrar en este momento.',
    title_date: '¿Quieres programar un reenvío?',
    title_metadata: 'Agregar campos personalizados',
    getting_informations: 'Consiguiendo información',
    created_at: 'Creado en',
    scheduled_to: 'Programado para',
    count: 'Cantidad',
    send_for: 'envío a través de',
    success_delete: '¡Envío cancelado exitosamente!',
    error_delete: '¡Ups! Inténtalo de nuevo.',
    success_resend_title: 'Reenvío completado/programado exitosamente.',
    filter_options: {
      no_filter: 'Sin filtro',
      title_az: 'Título A-Z',
      title_za: 'Título Z-A',
      created_gt: 'Fecha de creación - ⬆️',
      created_lt: 'Fecha de creación - ⬇️',
      schedule_gt: 'Fecha de programacion - ⬆️',
      schedule_lt: 'Fecha de programacion - ⬇️'
    }
  },
  automatic_message: {
    title_config: 'Ajustes',
    subtitle: 'Configura tus mensajes automáticos',
    title_delete_message: '¿Quieres eliminar este mensaje?',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    add: 'Agregar',
    create: 'Crear',
    save: 'Ahorrar',
    title_placeholder: 'Ingrese el título del mensaje aquí',
    delete_tooltip: 'Borrar mensaje'
  },
  insights: {
    no_insights: 'Parece que no tienes investigaciones que puedan generar ideas.'
  },
  health_score: {
    title_graph_average: 'Salud promedio',
    title_graph_approach: 'Salud del enfoque',
    title_evolution_health: 'Evolución de la salud',
    title_approaches_health: 'Salud del destinatario',
    see_details_in_approaches: 'Ver detalles del acercamiento',
    conversion: 'Conversión',
    score: 'Promedio',
    filter_churn: 'Probabilidad de abandono',
    filter_score: 'Salud',
    lh_to_lh: 'De menor a menor',
    lh_to_mh: 'Bajo a promedio',
    lh_to_hh: 'De menor a mayor',
    mh_to_lh: 'Medio a bajo',
    mh_to_mh: 'Promedio a promedio',
    mh_to_hh: 'Medio a alto',
    hh_to_lh: 'De mayor a menor',
    hh_to_mh: 'Alto a medio',
    hh_to_hh: 'De mayor a mayor',
    health: {
      health_very_high: 'Salud muy alta',
      health_high: 'Alta salud',
      health_mid: 'Salud promedio',
      health_low: 'Baja salud',
      health_very_low: 'Salud muy baja'
    },
    churn: {
      churn_very_high: 'Muy alta',
      churn_high: 'Alta',
      churn_mid: 'Promedio',
      churn_low: 'baja',
      churn_very_low: 'Muy baja'
    },
    abbreviated: {
      very_high: 'M. ALTA',
      high: 'ALTA',
      mid: 'PROM',
      low: 'BAJA',
      very_low: 'M. BAJA'
    },
    tooltip_churn: 'Este gráfico representa la probabilidad promedio de abandono de los enfoques de esta investigación.',
    tooltip_score: 'Este gráfico representa la salud promedio de los enfoques en esta investigación.',
    title_card_score: 'Salud',
    title_card_churn: 'Probabilidad de abandono',
    tooltip_hide_health: 'Ocultar salud de la búsqueda',
    tooltip_show_health: 'Ver estado de búsqueda',
    tooltip_addressed: 'Este valor refleja el promedio de todos los enfoques respondidos por este contacto, en una escala de 0 a 100. Proporciona una descripción general del estado de las interacciones a lo largo del tiempo.',
    tooltip_approach: 'Este valor representa la salud de este enfoque específico en una escala de 0 a 100. Se calcula en función del promedio de las respuestas proporcionadas durante esta interacción.',
    tooltip_probability_churn: 'La probabilidad de abandono es una estimación calculada por Binds, que considera todas las respuestas dadas por este contacto para predecir la posibilidad de cancelación o retiro.'
  }
}
