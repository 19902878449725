<template>
  <div class="container-individual-card" :class="`container-individual-card-${cardPayload.sendingId}`">
    <div class="content-loading-individual-card" :id="`load-card-${cardPayload.sendingId}`" v-show="loadIndividualCard">
      <Circle2 class="spinner-social-individual-card"/>
    </div>
    <div class="content-individual-card">
      <i class="material-icons icon-detail-individual-card" v-title="$t('survey.details')" @click="openDetails(cardPayload, null)">open_in_new</i>
      <div class="content-tags-individual-card">
        <TagSelect :dataTag="cardPayload" :tags="cardPayload.accountTags" @set-loading="setLoading($event)" v-show="showContainerTags" />
      </div>
      <div class="section-details-individual-card" @click="openDetails(cardPayload, null)">
        <div class="section-info-individual-card">
          <div class="content-solved-individual-card" :id="`is-solved-${cardPayload.sendingId}`">
            <i class="material-icons icon-solved-individual-card" v-title="$t('sendings.resolved')">task_alt</i>
          </div>
          <IndividualCardInfo :cardInfo="cardPayload.userInfo" />
        </div>
        <div class="section-info-individual-card">
          <IndividualSendType :sendInfo="cardPayload.sendInfo" />
        </div>
        <div class="section-info-individual-card">
          <IndividualResponseCard :responseData="cardPayload" />
        </div>
        <div class="section-info-individual-card" @click.stop>
          <div class="section-overtime-health" v-if="cardPayload.health?.overtime">
            <IndividualChartHealth :chartData="cardPayload" from="individual" @open-detail-in-time="openDetails(cardPayload, $event)"/>
          </div>
        </div>
        <div class="section-info-individual-card">
          <IndividualTicketCard :ticketData="cardPayload?.ticket"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import TagSelect from '../../tags/TagSelect.vue'
import IndividualCardInfo from './IndividualCardInfo.vue'
import IndividualSendType from './IndividualSendType.vue'
import IndividualResponseCard from './IndividualResponseCard.vue'
import IndividualTicketCard from './IndividualTicketCard.vue'
import IndividualChartHealth from './IndividualChartHealth.vue'

export default {
  name: 'IndividualCard',
  props: ['cardPayload', 'hasSendingSidebar'],
  components: {
    TagSelect,
    IndividualCardInfo,
    IndividualSendType,
    IndividualTicketCard,
    IndividualChartHealth,
    IndividualResponseCard,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      loadIndividualCard: false
    }
  },
  mounted () {
    this.showIsSolved(this.cardPayload.userInfo.isSolved)
  },
  watch: {
    'cardPayload.userInfo.isSolved': {
      immediate: true,
      handler (newVal) {
        setTimeout(() => { this.showIsSolved(newVal) })
      }
    }
  },
  computed: {
    seedType () { return { type: this.cardPayload.type, createdAt: this.cardPayload.createdAt, respondedAt: this.cardPayload.respondedAt } },
    showContainerTags () { return (this.cardPayload.sendingId === this.hasSendingSidebar) || !this.hasSendingSidebar }
  },
  methods: {
    showIsSolved (value) {
      const divIsSolved = document.getElementById(`is-solved-${this.cardPayload.sendingId}`)
      let opacity = 0
      let width = '0vw'
      let display = 'none'

      if (value) {
        opacity = 1
        width = '1.5vw'
        display = 'flex'
        gsap.set(divIsSolved, { display })
      }

      gsap.to(divIsSolved, {
        width,
        opacity,
        duration: 0.2,
        onComplete: () => {
          if (!value) {
            gsap.set(divIsSolved, { display })
          }
        }
      })
    },
    openDetails (card, sendingClicked) {
      let toSend = card

      if (sendingClicked) { toSend = sendingClicked }

      this.$emit('open-details-sending', toSend)

      this.setFocusCard(toSend)
    },
    setFocusCard (toSend) {
      let idSending = toSend.sendingId

      if (typeof toSend === 'string') { idSending = toSend }

      const removeFocusCard = () => {
        const allContainerCards = document.querySelectorAll('.container-individual-card')

        allContainerCards.forEach(container => {
          gsap.to(container, {
            opacity: 0.5,
            borderColor: '#e1e1e1',
            duration: 0.3
          })
        })
      }

      removeFocusCard()

      const containerCard = document.querySelector(`.container-individual-card-${idSending}`)

      if (!containerCard) { return removeFocusCard() }

      gsap.to(containerCard, {
        opacity: 1,
        borderColor: '#919191',
        duration: 0.3
      })
    },
    setLoading (e) {
      if (e) { this.loadIndividualCard = e }

      const opacity = e ? 1 : 0
      const divLoading = document.getElementById(`load-card-${this.cardPayload.sendingId}`)
      gsap.to(divLoading, {
        opacity,
        duration: 0.5,
        onComplete: () => {
          if (!e) { this.loadIndividualCard = e }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-individual-card {
  position: relative;
  height: 9vw;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 15px;
  box-shadow: 0 5px 7px 0 #30303029;
  padding: 0.4vw;
  display: flex;
  .content-loading-individual-card {
    z-index: 99;
    opacity: 0;
    position: absolute;
    border-radius: 15px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner-social-individual-card {
      width: 2.1vw !important;
      height: 2.1vw !important;
      border-width: 3px !important;
      border-radius: 35px;
      border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
    }
  }
  .content-individual-card {
    max-width: 100%;
    min-width: 97%;
    position: relative;
    transition: width 0.6s;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.1vw;
    .icon-detail-individual-card {
      position: absolute;
      padding: 0.2vw;
      top: 0;
      left: 0;
      background: #f7f7f7;
      color: #5e5e5e;
      border: 1px solid #e1e1e1;
      border-radius: 6px;
      font-size: 1vw;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      cursor: pointer;
    }
    .content-tags-individual-card {
      height: 1.6vw;
    }
    .section-details-individual-card {
      cursor: pointer;
      flex: 1;
      border-radius: 8px;
      height: 5.5vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .section-info-individual-card {
        width: 15%;
        height: 100%;
        padding: 0.5vw;
        .content-solved-individual-card {
          width: 0;
          opacity: 0;
          background: #17af3520;
          border-radius: 5px;
          border: 1px solid #17af35;
          display: none;
          align-items: center;
          justify-content: center;
          .icon-solved-individual-card {
            color: #17af35;
            font-size: 0.9vw;
          }
        }
      }
      .section-info-individual-card:first-child {
        width: 25%;
        border-right: 1px solid #e1e1e1;
        display: flex;
        gap: 0.5vw;
        padding-left: 0;
      }
      .section-info-individual-card:last-child {
        width: 10%;
        padding: 0;
      }
      .section-info-individual-card:nth-child(2) {
        width: 20%;
      }
      .section-info-individual-card:nth-child(3) {
        width: 20%;
      }
      .section-info-individual-card:nth-child(4) {
        padding: 0;
        width: 25%;
        .section-overtime-health {
          width: 100%;
          height: 100%;
          border: 1px solid #e1e1e1;
          border-radius: 3px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
        }
      }
    }
  }
}
</style>
