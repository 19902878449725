import { callApiService } from '@/_services'
import 'whatwg-fetch'

const API_URL = process.env.VUE_APP_URL
function insertToken () {
  const token = localStorage.getItem('bindsUser') ? JSON.parse(localStorage.getItem('bindsUser')) : ''
  if (token) {
    return 'Bearer ' + token
  }
  logout()
  throw new Error('Invalid token')
}

function logout () {
  localStorage.removeItem('bindsAccount')
  localStorage.removeItem('bindsUser')
  window.location.replace('/')
}
export const accountService = {
  getAccountSendings,
  getAccount,
  getAccounts,
  getUsers,
  getUser,
  getRoles,
  getImages,
  setUser,
  updateUser,
  changePassword,
  updateAccount,
  searchAccount,
  loginFromV1,
  saveImageOnAccountGallery,
  opportunities,
  deleteUser,
  getActivities
}

async function deleteUser (userId) {
  const accountsUrl = API_URL + 'users/' + userId
  return await callApiService.callApi(accountsUrl, 'delete')
}

async function getImages (context) {
  return await callApiService.callApi(API_URL + 'assets?context=' + context)
}

async function saveImageOnAccountGallery (body, context) {
  const accountsUrl = API_URL + 'assets?context=' + context
  return await callApiService.callApi(accountsUrl, 'post', body)
}

async function getAccounts (rangeEnd = 9) {
  const accountsUrl = API_URL + 'accounts'
  return await ownCallApiService({ url: accountsUrl, method: 'get', headers: { Range: 'items=0-' + rangeEnd } })
}

async function getAccount (id) {
  const accountsUrl = API_URL + 'accounts/' + id
  return await callApiService.callApi(accountsUrl, 'get')
}

async function searchAccount (nameOrId) {
  const accountsUrl = `${API_URL}accounts?text=${nameOrId}`
  return await ownCallApiService({ url: accountsUrl, method: 'get' })
}

async function getUsers () {
  const accountsUrl = API_URL + 'users'
  const result = await callApiService.callApi(accountsUrl, 'get')
  return result
}
async function getUser (id) {
  const accountsUrl = API_URL + 'users/' + id
  const result = await callApiService.callApi(accountsUrl, 'get')
  return result
}
async function updateUser (body) {
  const accountsUrl = API_URL + 'users/' + body._id
  const result = await callApiService.callApi(accountsUrl, 'put', body)
  return result
}
async function setUser (body) {
  const accountsUrl = API_URL + 'users'
  const result = await callApiService.callApi(accountsUrl, 'post', body)
  return result
}

async function getRoles () {
  const userUrl = API_URL + 'roles'
  const result = await callApiService.callApi(userUrl, 'get')
  return result
}

async function getActivities (startRange, endRange) {
  const userUrl = API_URL + 'activities'
  const result = await callApiService.callApi(userUrl, 'get', null, endRange, false, startRange)
  return result
}

async function updateAccount (id, body) {
  const accountSettingsUrl = API_URL + 'accounts/' + id
  const result = await callApiService.callApi(accountSettingsUrl, 'put', body)
  return result
}

async function opportunities (body) {
  const accountSettingsUrl = API_URL + 'opportunities'
  const result = await callApiService.callApi(accountSettingsUrl, 'post', body)
  return result
}

async function changePassword (body) {
  const account = API_URL + 'passwordResetRequests'
  const result = await callApiService.callApi(account, 'post', body)
  return result
}

async function getAccountSendings (id) {
  const linkSettingsUrl = API_URL + 'accounts/' + id + '/sendings'
  const result = await callApiService.callApi(linkSettingsUrl, 'get')
  return result
}

async function loginFromV1 (params) {
  const changeVersionUrl = `${API_URL}change-version/`
  const body = { user: { _id: params.user, account: params.account } }
  return await callApiService.callApi(changeVersionUrl, 'post', body)
}

async function ownCallApiService (requestConfig) {
  try {
    const headers = {
      Authorization: insertToken(),
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      ...requestConfig.headers && { ...requestConfig.headers }
    }
    return await window.fetch(requestConfig.url, {
      method: requestConfig.method,
      headers: headers,
      ...requestConfig.body && { body: JSON.stringify(requestConfig.body) }
    }, 30000).then(res => {
      return res.json()
    }).then(jsonResponse => {
      return jsonResponse
    }).catch(error => {
      console.log('Error inside accountService', error)
    })
  } catch (error) {
    console.log('Error to call DB on accountService', error)
    return []
  }
}
