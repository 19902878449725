export const callApiService = {
  callApi
}

function insertToken () {
  const token = JSON.parse(localStorage.getItem('bindsUser'))
  if (token) {
    return 'Bearer ' + token
  }
  logout()
  throw new Error('Invalid token')
}

function logout () {
  localStorage.removeItem('bindsAccount')
  localStorage.removeItem('bindsUser')
  window.location.replace('/')
}

/**
 * Call API project
 *
 * @param {String} url contains ,the url of requisition
 * @param {String} method contains the method of requisition
 * @param {Object | Null} body contains the body of requisition
 * @param {Integer | Null} newRangeEnd contains the <end-range> for the Range request header || null.
 * @param {Integer | Null} newRangeStart contains the <start-range> for the Range request header || null.
 * @param {Boolean | Null} returnMessage says if it should return de error object from req, but when it is turned off, it triggers an alert.
 * @returns {Object}
 */

async function callApi (url, method, body, newRangeEnd, isFile, newRangeStart, returnMessage = null) {
  const headers = {
    Authorization: () => 'Bearer ' + localStorage.getItem('bindsUser'),
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  }
  if (newRangeEnd) {
    if (!newRangeStart) {
      headers.Range = 'items=0-' + newRangeEnd
    } else {
      headers.Range = `items=${newRangeStart}-${newRangeEnd}`
    }
  }
  headers.Authorization = insertToken()
  const request = {
    method,
    headers
  }
  if (isFile) {
    request.headers = { Authorization: headers.Authorization }
  }

  if (method !== 'get' && body !== '') {
    request.body = isFile ? body : JSON.stringify(body)
  }
  try {
    const result = await fetch(
      url, request
    ).then(async function (r) {
      if (r && [200, 201, 206].indexOf(r.status) !== -1) {
        const range = r.headers.get('Content-Range')
        const conditions = [
          'sending-responses',
          'sendings',
          'insights'
        ]
        const canReturnNewRangeObject = conditions.some(el => url.includes(el))
        if (newRangeEnd !== undefined && range && canReturnNewRangeObject) {
          return { data: r.json(), range }
        }
        return r.json()
      } else if (r.status === 401) {
        logout()
      }

      const errorMessage = await r.json()
      if (errorMessage.status === 400 && returnMessage) {
        return errorMessage
      }
    })
    return result
  } catch (error) {
    console.log('error at callApi.service', error)
  }
}
