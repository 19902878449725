<template>
  <div :key="controlAllComponents">
    <div v-if="!isMobile" id="containerRealTime" :style="[fullscreen ? {'height': '93vh'} : { 'height': `${currentHeight <= 700 ? 80 : currentHeight >= 1435 ? 88 : 84}vh`}]">
      <div style="width: 75%;">
        <Graphics class="realtime-graphics" :defaultCardsQuestions="defaultCardsQuestions" :realTimeQuestions="realTimeQuestions" :matrixData="matrixData" :realTimeTexts="realTimeTexts"></Graphics>
      </div>
      <div class="responses-grid-view size-resp" :class="{ 'sidebar-index': showDetailsOn }">
        <IndividualResponses :realTimeQuestions="realTimeQuestions" :responses="responses"></IndividualResponses>
      </div>
    </div>
    <div v-else>
      <div id="cardRealViews" class="shadowMe">
        <div :class="{ shadowMe: !showResponses }" @click="showResponses = false" class="buttonChangeView">
          <i class="gradientIcon material-icons tag-icon">dashboard</i>
          {{ $t('real_time.graphics') }}
        </div>
       <div :class="{ shadowMe: showResponses }" @click="showResponses = true" class="buttonChangeView">
          <i class="gradientIcon material-icons tag-icon">view_stream</i>
          {{ $t('real_time.responses') }}
        </div>
      </div>
      <div v-show="!showResponses" :class="{ animateThere: !showResponses }" class="responses-grid-view" style="margin-left: 6px;">
        <Graphics class="realtime-graphics" :defaultCardsQuestions="defaultCardsQuestions" :realTimeQuestions="realTimeQuestions" :matrixData="matrixData" :realTimeTexts="realTimeTexts"></Graphics>
      </div>
      <div :class="{ animateThere: showResponses }" style="margin-left: 14px;">
        <IndividualResponses id="grid-responses" :realTimeQuestions="realTimeQuestions" :responses="responses"></IndividualResponses>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { dashboardMaker } from '@/_helpers'
import Graphics from './Graphics'
import IndividualResponses from './IndividualResponses'

export default {
  name: 'RealTimeGrouper',
  props: ['responses', 'survey', 'realTimeQuestions', 'controlAllComponents', 'realTimeTexts', 'matrixData', 'fullscreen', 'isScrollAllowed'],
  components: {
    Graphics,
    IndividualResponses
  },
  data () {
    return {
      isMobile: false,
      showResponses: false,
      defaultCardsQuestions: [],
      showDetailsOn: false,
      scrollInterval: null
    }
  },
  beforeDestroy () {
    this.$root.$off('filter-updated')
    this.$root.$off('show-details-sidebar')
    document.documentElement.style.overflow = 'auto'
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  mounted () {
    // check if is mobile
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
    this.$parent.$on('update', () => {
      this.updateKeys()
    })
    document.documentElement.style.overflow = 'hidden'

    this.$parent.$on('update', () => {
      this.$nextTick(() => {
        this.defaultCardsQuestions = dashboardMaker.makeDefaultDash(
          this.survey.questions, true
        )
      })
    })

    this.$root.$on('show-details-sidebar', (value) => {
      this.showDetailsOn = value
    })

    this.$watch(
      vm => [vm.fullscreen, vm.isScrollAllowed],
      () => {
        this.pageScroll(this.fullscreen, this.isScrollAllowed)
      },
      {
        immediate: true,
        deep: true
      }
    )
  },
  computed: {
    ...mapGetters({
      currentSurvey: 'dashboard/getCurrentSurvey',
      account: 'account/getAccount'
    }),
    lang () {
      return this.$store.getters['account/getLang']
    },
    currentHeight () {
      return window.innerHeight
    }
  },
  methods: {
    updateKeys () {
      this.defaultCardsQuestions.forEach((element, index) => {
        const id = new Date()
        element.key = element.key + id.getSeconds()
      })
    },
    onResize () {
      this.isMobile = window.innerWidth < 1032
    },
    pageScroll (fullpage, isScrollAllowed) {
      if (fullpage && isScrollAllowed) {
        const realTimeContainer = document.querySelector('#containerRealTime')

        this.scrollInterval = window.setInterval(() => {
          // If at page bottom, scroll by to top
          if ((realTimeContainer.scrollTop + realTimeContainer.clientHeight) >= realTimeContainer.scrollHeight) {
            realTimeContainer.scrollTo(0, 0)
          } else {
            // Scroll 1px down
            realTimeContainer.scrollBy(0, 1)
          }
        }, 25)
      } else {
        window.clearInterval(this.scrollInterval)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#containerRealTime {
  display: flex;
  overflow: scroll;
}
.gradientIcon {
  background-color: #0a0a0a;background-image: linear-gradient(45deg, #8e8e8e, #fff);background-size: 100%;background-repeat: repeat;-webkit-background-clip: text;-webkit-text-fill-color: transparent;-moz-background-clip: text;-moz-text-fill-color: transparent;
}
.buttonChangeView {
  font-size: 0.9em;display: grid;text-align: center;margin: 10px;
}
#cardRealViews {
 color: #cacaca;margin: 0 auto;margin-bottom: 9px;display: flex;justify-content: center;background: white;width: 80%;padding: 4px 0px;border-radius: 5px;
}
.shadowMe {
  box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.03), 0 5px 4px 0 rgba(0, 0, 0, 0.01) !important;
}
#containerRealTime > div > .questions-grid-view > .question-card-wrapp {
  margin: 0px 19px 15px 0px !important;
}
@media (max-width: 1032px) {
  .responses-grid-view {
    height: 70vh;
    overflow: hidden scroll;
  }
  #grid-responses {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important;
    grid-gap: 46px !important;
    grid-auto-flow: dense !important;
    margin-left: -5px !important;
  }

}
.cardsContainerScroll {
  height: 76vh;
}
@media (max-width: 646px) {
  .questions-grid-view {
    display: block;
  }
  #card {
    padding: 30px !important;
  }
  .responses-grid-view {
    height: 70vh;
    overflow: hidden scroll;
    padding: 10px;
  }
}
.animateThere {
  @extend %animated-to-slow;
  @extend %fadeInRight;
}
</style>

<style lang="scss">
.realtime-graphics {
  .numberBarMetric {
    margin-top: -40px !important;
  }
  .emoticonBarMetric {
    margin-top: 20px !important;
  }
}
.size-resp {
  width: 22%;
  right: 15px;
  position: fixed;
}
.sidebar-index {
  z-index: 9999999 !important;
}
</style>
