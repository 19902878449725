var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sentimental-aside"},[_c('app-card',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"list-text-analytics",attrs:{"id":"list-responses"}},_vm._l((_vm.responses),function(item,index){return _c('div',{key:index,staticClass:"box-cite-analytics hvr-float-shadow",class:[
            { 'border-promotor' : item.responses.score >= 0.5 && item.responses.score <= 1 },
            { 'border-neutral' : item.responses.score >= 0 && item.responses.score < 0.5  },
            { 'border-detractor' : item.responses.score < 0 }
          ],on:{"click":function($event){return _vm.getIndividualResponses(item)}}},[(item?.seedData && (item?.seedData?.from?.email || item?.seedData?.from?.name))?_c('div',{staticClass:"contact-answered"},[_c('div',{staticStyle:{"margin-bottom":"5px"}},[(item?.seedData?.from?.name)?_c('app-heading',{staticClass:"contact-name",attrs:{"level":"h5"}},[_vm._v(_vm._s(item?.seedData?.from?.name))]):_vm._e()],1),_c('div',[(item?.seedData?.from?.email)?_c('app-heading',{staticClass:"contact-email",attrs:{"level":"h6"}},[_vm._v(_vm._s(item?.seedData?.from?.email))]):_vm._e()],1)]):_c('div',{staticClass:"contact-answered"},[_c('div',{staticStyle:{"margin-bottom":"5px"}},[_c('app-heading',{staticClass:"contact-name",attrs:{"level":"h5"}},[_vm._v(_vm._s(_vm.$t('analytics.anonymous_reply')))])],1)]),_c('div',{staticClass:"response-analytics"},[_c('p',{class:{
              'sentiment-light-text': item.responses.magnitude < 1.2,
              'sentiment-medium-text': item.responses.magnitude >= 1.2 && item.responses.magnitude <= 3,
              'sentiment-hevy-text': item.responses.magnitude > 3
              }},[_vm._v("\" "+_vm._s(item.responses.text)+" \"")])]),_c('div',{staticClass:"to-text-footer"},[_c('span',{staticClass:"text-date"},[_vm._v(_vm._s(_vm._f("moment")(item.responses.createdAt,"from", "now")))]),_c('div',[(item.responses.score >= 0.5 && item.responses.score <= 1)?_c('app-iconify',{attrs:{"name":"heart","color":"#00aaa7","size":"40"}}):_vm._e(),(item.responses.score >= 0 && item.responses.score < 0.5)?_c('app-iconify',{attrs:{"name":"heartHalf","color":"#ffb500","size":"40"}}):_vm._e(),(item.responses.score < 0)?_c('app-iconify',{attrs:{"name":"heartDislike","color":"#ff607d","size":"40"}}):_vm._e()],1)])])}),0),_c('div',{staticClass:"row center-xl center-lg center-md center-sm load-more-btn"},[(!_vm.loadingMore && !_vm.noMorePages)?_c('i',{directives:[{name:"title",rawName:"v-title",value:(_vm.$t('tickets.load_more')),expression:"$t('tickets.load_more')"}],staticClass:"material-icons more-button",on:{"click":function($event){return _vm.loadMore()}}},[_vm._v("more_horiz")]):_vm._e(),(_vm.loadingMore && !_vm.noMorePages)?_c('Circle2',{staticClass:"circle-responses"}):_vm._e()],1)])]),(_vm.showDetails)?_c('Details',{class:{'expand-details': _vm.showDetails},attrs:{"id":_vm.sendingId},on:{"closeSendings":_vm.closeSidebar}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }