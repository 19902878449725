import { constants } from '@/_helpers'

export default {
  methods: {
    getIndexByValue (value) {
      const ranges = [
        { min: 0, max: 19, idx: 0 },
        { min: 20, max: 39, idx: 1 },
        { min: 40, max: 59, idx: 2 },
        { min: 60, max: 79, idx: 3 },
        { min: 80, max: 100, idx: 4 }
      ]

      const range = ranges.find(r => value >= r.min && value <= r.max)

      if (!range) return 'N/A'

      return range.idx
    },
    getColorForRanking (value) {
      const idx = this.getIndexByValue(value)
      const colorsRanking = ['#EA484D', '#EF874C', '#F8C43D', '#9fcd35', '#57ad26']

      return colorsRanking[idx]
    },
    getColorForHealth (value, type) {
      const idx = this.getIndexByValue(value)
      const colorsScore = ['#EA484D', '#EF874C', '#F8C43D', '#9fcd35', '#57ad26']
      const colorsChurn = [...colorsScore].reverse()
      const colors = type === 'churn' ? colorsChurn : colorsScore

      return colors[idx]
    },
    getLabelChurn (value) {
      const idx = this.getIndexByValue(value)
      const labelsChurn = [
        constants.health.churn.VERY_LOW,
        constants.health.churn.LOW,
        constants.health.churn.MID,
        constants.health.churn.HIGH,
        constants.health.churn.VERY_HIGH
      ]

      return labelsChurn[idx]
    },
    getLabelScore (value) {
      const idx = this.getIndexByValue(value)
      const labelsChurn = [
        constants.health.churn.VERY_LOW,
        constants.health.churn.LOW,
        constants.health.churn.MID,
        constants.health.churn.HIGH,
        constants.health.churn.VERY_HIGH
      ]

      return labelsChurn[idx]
    },
    getIconHealth (value) {
      const idx = this.getIndexByValue(value)
      const icons = ['\uE608', '\uE607', '\uE606', '\uE600', '\uE603']
      return icons[idx]
    },
    getColorHeartSurvey (value) {
      const idx = this.getIndexByValue(value)
      const colors = ['#EA484D95', '#EF874C90', '#F8C43D90', '#9fcd3590', '#57ad2690']
      return colors[idx]
    }
  }
}
