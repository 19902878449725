<template>
  <div class="container-kpi">
    <div class="content-bubbles-kpi">
      <div class="bubble-kpi" v-if="showThirdBubble" :style="{'background-color': getOptionAndColor(valuesToShow[2][0]).color }">
        <p class="label-bubble-kpi">{{ $t(`dashboard.graphics.${getOptionAndColor(valuesToShow[2][0]).option}`) }}</p>
      </div>
      <div v-if="valuesToShow.length > 1" class="bubble-kpi second" :style="{'background-color': getOptionAndColor(valuesToShow[1][0]).color }">
        <p class="label-bubble-kpi">{{ $t(`dashboard.graphics.${getOptionAndColor(valuesToShow[1][0]).option}`) }}</p>
      </div>
      <div v-if="valuesToShow.length > 0" class="bubble-kpi first" :style="{'--backgroundFirst': getOptionAndColor(valuesToShow[0][0]).color }">
        <p class="label-bubble-kpi">{{ $t(`dashboard.graphics.${getOptionAndColor(valuesToShow[0][0]).option}`) }}</p>
      </div>
    </div>
    <div class="content-labels-kpi" v-if="showResultMessage">
      <p class="label-result-kpi">{{ $t('dashboard.most_choosen.1') }} <span>{{ parseInt((valuesToShow[0][1] / data.total) * 100) }}%</span> {{ $t('dashboard.most_choosen.2') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KpiYnd',
  props: ['data', 'cardData'],
  components: {},
  data () {
    return {
      valuesToShow: []
    }
  },
  created () {
    if (!this.data.data) return

    const dataWithoutLabels = this.data.data.slice(1)

    this.valuesToShow = dataWithoutLabels.sort((a, b) => b[1] - a[1])
  },
  computed: {
    showThirdBubble () { return Array.isArray(this.valuesToShow) && this.valuesToShow.length > 2 },
    showResultMessage () { return this.valuesToShow.length > 0 && (this.data.average >= 0 || this.data.question.type === 'nps') }
  },
  methods: {
    getOptionAndColor (key) {
      const keyMapping = {
        op1: [100, 95],
        op2: [0, 5],
        op3: [50],
        op4: [75]
      }

      const colorMapping = {
        op1: 0,
        op2: 1,
        op3: 2,
        op4: 2
      }

      let option = null

      for (const [opt, keys] of Object.entries(keyMapping)) {
        if (keys.includes(key)) {
          option = opt
          break
        }
      }

      if (option) {
        return {
          option: option,
          color: this.cardData.colors[colorMapping[option]]
        }
      }

      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.container-kpi {
  width: 100%;
  height: 100%;
  padding: 0.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .content-bubbles-kpi {
    position: relative;
    width: 100%;
    height: 80%;
    .bubble-kpi {
      position: absolute;
      top: 45%;
      left: 19%;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 5px #e1e1e1;
      width: 4.5vw;
      height: 4.5vw;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .label-bubble-kpi {
        color: #ffffff;
        font-size: 0.9vw;
        font-weight: 700;
      }
    }
    .second {
      width: 5.5vw;
      height: 5.5vw;
      top: 51%;
      left: 43%;
      .label-bubble-kpi {
        font-size: 1vw;
      }
    }
    .first {
      width: 6.5vw;
      height: 6.5vw;
      top: 40%;
      left: 71%;
      background: var(--backgroundFirst);

      .label-bubble-kpi {
        font-size: 1.4vw;
      }

      &::before {
        content: '';
        position: absolute;
        width: 0.15vw;
        height: 92%;
        border-radius: 0 0 35px 35px;
        background: var(--backgroundFirst);
        box-shadow: 0 0 5px #e1e1e1;
        top: 100%;
        left: 40%;
        transform-origin: top right;
        transform: translate(0%, 0%) rotate(35deg);
      }
    }
  }
  .content-labels-kpi {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    .label-result-kpi {
      text-align: center;
      font-size: 0.75vw;
      color: #454548;
      background: #ffffff;
      z-index: 2;
      span {
        font-weight: 500;
        color: #333333;
      }
    }
  }
}
</style>
