<template>
  <div class="realTimeGraphics">
    <!-- NPS, CSAT, KPI - START -->
    <div v-if="cardData.question.type !== 'text' && cardData.question.type !== 'matrix'">
      <chart-controller
        v-if="realTimeData"
        :question="cardData.question"
        :data="realTimeData"
        :cardData="cardData"
        :view="view"
      ></chart-controller>
      <not-found v-else :padding="true"></not-found>
    </div>
    <!-- TEXT -->
    <div v-if="cardData.question.type === 'text'">
      <not-found :withoutResponses="true" v-if="textData.length === 0" :padding="true"></not-found>
      <text-list
        v-else
        :data="textData"
        :question="cardData.question"
        :cardData="cardData"
        :notShowTags="true"
      ></text-list>
    </div>
    <!-- MATRIX -->
    <div v-if="cardData.question.type === 'matrix' && cardData.question.ui !== 'like' && view !== 'overtime'">
      <stacked-bar
        v-if="matrixDataDependViewType.length > 0"
        :data="matrixDataDependViewType"
        :cardData="cardData.overall"
        :question="cardData.question"
      ></stacked-bar>
      <div v-else>
        <not-found :padding="true"></not-found>
      </div>
    </div>
    <div v-if="cardData.question.type === 'matrix' && cardData.question.ui === 'like' && view !== 'overtime'">
      <like-overall
        v-if="matrixDataDependViewType.length > 0"
        :data="matrixDataDependViewType"
        :cardData="cardData.overall"
        :question="cardData.question">
      </like-overall>
      <div v-else>
        <not-found :padding="true"></not-found>
      </div>
    </div>
    <div class="card-like" v-if="cardData.question.type === 'matrix' && view === 'overtime'">
      <like-overtime
        v-if="matrixDataDependViewType.length > 0"
        :data="realTimeData"
        :cardData="matrixDataDependViewType"
        :question="cardData.question">
      </like-overtime>
      <div v-else>
        <not-found :padding="true"></not-found>
      </div>
    </div>
  </div>
</template>

<script>
import TextList from '../charts/TextList.vue'
import NotFound from '../charts/NotFound.vue'
import StackedBar from '../charts/StackedBar.vue'
import likeOverall from '../charts/likeOverall.vue'
import likeOvertime from '../charts/likeOvertime.vue'
import ChartController from '../charts/ChartController.vue'
import('lodash-es').then((lodashEs) => {
  window._ = lodashEs.default
})
export default {
  name: 'QuestionCard',
  props: ['cardData', 'realTimeQuestions', 'realTimeTexts', 'matrixData'],
  components: { ChartController, NotFound, TextList, StackedBar, likeOverall, likeOvertime },
  data () {
    return {
      matrixDataDependViewType: []
    }
  },
  created () {
    // o retorno para o gráfico de matrix será igual ao de overall para todas as views
    if (this.cardData.question.type === 'matrix' && this.view === 'overtime') {
      this.matrixDataDependViewType = this.matrixData // isso acontece pq dependendo da view já foi feita uma request para trazer os dados do matrix
    } else {
      this.matrixDataDependViewType = this.realTimeQuestions
    }
  },
  computed: {
    dataStore () {
      return this.$store.getters['graphics/getDataGraphics']
    },
    currentSurvey () {
      const survey = this.$store.getters['dashboard/getCurrentSurvey']
      return survey
    },
    view () {
      return this.$store.getters['dashboard/getRealTimeView']
    },
    realTimeData () {
      if (this.view === 'average') {
        if (this.realTimeQuestions[0] && this.realTimeQuestions[0].questions && this.cardData.question.type !== 'matrix') {
          if (['csat', 'nps', 'kpi', 'enum', 'nes', 'ces', 'nvs'].indexOf(this.cardData.question.type) > -1 && !this.realTimeQuestions[0].average) {
            const result = window._.find(this.realTimeQuestions[0].questions, { question: { _id: this.cardData.question._id } })
            if (result) {
              return result
            }
          }
        }
      }

      if (this.view === 'overtime') {
        const questionIdx = this.realTimeQuestions.findIndex(item => item.question === this.cardData.question._id)
        if (questionIdx >= 0) {
          return this.realTimeQuestions[questionIdx]
        }
      }

      if (this.view === 'overall') {
        if (this.realTimeQuestions[0] && this.realTimeQuestions[0].questions) {
          if (['csat', 'nps', 'kpi', 'enum', 'nes', 'ces', 'nvs'].indexOf(this.cardData.question.type) > -1) {
            return this.realTimeQuestions
          }
        }
      }

      return false
    },
    textData () {
      /**
       * tenta achar na primeira resposta se a question respondida é a mesma que está sendo iterada no foreach de cards
       * caso encontra ela retorna os dados para exibir no componente de texto
      */
      const self = this
      let findTheQuestionData = []
      this.realTimeTexts.forEach(fetchedData => {
        if (findTheQuestionData.length <= 0 && fetchedData.length > 0 && fetchedData[0].question._id === self.cardData.question._id) {
          findTheQuestionData = fetchedData
          return true
        }
      })
      return findTheQuestionData
    }
  }
}
</script>

<style lang="scss">
.icons-options {
  position: absolute;
  bottom: -5px;
  width: 90% !important;
}
.realTimeGraphics {
  height: 90%;
  flex: 1;
  max-height: 360px;
  div {
    width: 100%;
    height: 100%;
  }
  .barMetric {
    margin-top: -69px !important;
    .metric-container {
      height: 120px;
    }
  }
  .card-like {
    height: 385px;
    box-sizing: border-box;
    margin-top: 20px;
  }
}
</style>
