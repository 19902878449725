import i18n from '../translations/i18n'
import { isEmpty } from 'lodash-es'

const moment = require('moment')
const API_URL = process.env.VUE_APP_URL

export const formatters = {
  formatGetBySurveyIdUrls,
  formatApproachesGetUrl,
  formatDateByLang,
  analyticsHeadersKey,
  footerKeysForExport,
  formatDateByAddTimezone,
  formatDateByMongoTimeZone,
  formatDateByLangAndFixTimeZone,
  insertSendingIdOnQuery
}

/**
 * Formats the params and mounts the URL needed to get data by a survey's id.
 * @param {String} place it's where you want to get data, like surveys, responses, questions, etc.
 * @param {String} surveyId it's the survey's id
 * @param {Object || Null} dateFilters contains or not the filter options with date time
 */
function formatGetBySurveyIdUrls (place, surveyId, dateFilters) {
  let params = `{"$and": [{"survey": {"$eq": "${surveyId}"}}]}`
  const filtersReceived = dateFilters.respondedAt
  const areFiltersEmpty = isEmpty(filtersReceived)

  if (!areFiltersEmpty) {
    if (filtersReceived.gte && filtersReceived.lte) {
      params = `{"$and": [{"survey": {"$eq": "${surveyId}"}}, {"respondedAt": {"$gte": "${filtersReceived.gte}"}}, {"respondedAt": {"$lte": "${filtersReceived.lte}"}}]}`
    }
    if (filtersReceived.gte && !filtersReceived.lte) {
      params = `{"$and": [{"survey": {"$eq": "${surveyId}"}}, {"respondedAt": {"$gte": "${filtersReceived.gte}"}}]}`
    }
  }

  const encondedParams = encodeURI(params)
  const url = `${API_URL}${place}?q=${encondedParams}`

  return url
}

/**
 * Formats the params and mounts the URL needed to get approaches data.
 * @param {String} place sendings route - it can be "sendings" or "sendingStats".
 * @param {Object || Null} dateFilters contains or not the filter options with date time
 * @param {String} approachType it can be "active", "archived" or "all"
 */
function formatApproachesGetUrl (place, dateFilters, approachType) {
  let archived = false
  if (approachType === 'archived') {
    archived = true
  }
  let params = `{"$and":[{"archivedAt":{"$exists":${archived}}}]}`

  const filtersReceived = dateFilters.respondedAt
  const areFiltersEmpty = isEmpty(filtersReceived)

  // Se filtros de data estão vazios e é para mostrar todas as abordagens:
  if (areFiltersEmpty && approachType === 'all') {
    params = '{"$and":[]}'
  }

  // Se filtros NÃO estão vazios e é para mostrar ativas OU arquivadas:
  if (!areFiltersEmpty && approachType !== 'all') {
    // Ativas OU arquivadas
    if (filtersReceived.gte && filtersReceived.lte) {
      params = `{"$and":[{"archivedAt":{"$exists":${archived}}}, {"respondedAt": {"$gte": "${filtersReceived.gte}"}}, {"respondedAt": {"$lte": "${filtersReceived.lte}"}}]}`
    }
    if (filtersReceived.gte && !filtersReceived.lte) {
      params = `{"$and":[{"archivedAt":{"$exists":${archived}}}, {"respondedAt": {"$gte": "${filtersReceived.gte}"}}]}`
    }
  }

  // Se filtros NÃO estão vazios e é para mostrar ativas E arquivadas:
  if (!areFiltersEmpty && approachType === 'all') {
    if (filtersReceived.gte && filtersReceived.lte) {
      params = `{"$and":[{"respondedAt": {"$gte": "${filtersReceived.gte}"}}, {"respondedAt": {"$lte": "${filtersReceived.lte}"}}]}`
    }
    if (filtersReceived.gte && !filtersReceived.lte) {
      params = `{"$and":[{"respondedAt": {"$gte": "${filtersReceived.gte}"}}]}`
    }
  }

  const encondedParams = encodeURI(params)
  const url = `${API_URL}${place}?q=${encondedParams}`

  return url
}

/**
 * Formats the date according to the user's language and the format chosen.
 * @param {Date} date Date object to format
 * @param {String} lang The current language has to be passed by the vue component using this function.
 * @param {String} format The way you want to show the date is defined by strings (you can look at: https://momentjs.com/).
 */
function formatDateByLang (date, lang, format) {
  moment.locale(lang)
  return moment(date).utc().format(format)
}

/**
 * Formats the date according to the user's language and the format chosen.
 * @param {Date} date Date object to format
 * @param {String} lang The current language has to be passed by the vue component using this function.
 * @param {String} format The way you want to show the date is defined by strings (you can look at: https://momentjs.com/).
 */
function formatDateByLangAndFixTimeZone (date, lang, format) {
  moment.locale(lang)
  return moment(date).subtract(3, 'hours').utc().format(format)
}

function formatDateByMongoTimeZone (date, lang) {
  moment.locale(lang)
  return moment(date).utc().local().format('lll:s')
}

/**
 * Formats the date to our mongoDB timezone +3 hours
 * @param {string} query object string to format
 * @param {Number} quantity int value to add on hour
 * @param {String} metric contains the type of the quantity that we want to add on hours (days, hours, minutes)
 */
function formatDateByAddTimezone (query, quantity, metric) {
  const parsedQuery = typeof query === 'string' ? JSON.parse(query) : query
  if (parsedQuery && parsedQuery.$and) {
    parsedQuery.$and.forEach(role => {
      ['createdAt', 'respondedAt'].forEach(keyField => {
        if (role[keyField]) {
          const operators = ['$lte', '$gte'] // caso precise adicionar mais operadores, só encaixar ai, como $eq etc
          operators.forEach(key => {
            if (role[keyField][key]) {
              role[keyField][key] = moment(role[keyField][key], 'YYYY-MM-DD HH:mm ZZ').add(quantity, metric).utc().format()
            }
          })
        }
      })
    })
  }
  return JSON.stringify(parsedQuery)
}

/**
 * Inserts the sendingId on the query object
 * @param {string} query object string to format
 * @param {string} sendingId string value to insert on query
 * @returns {string} the query object with the sendingId inserted
 */
function insertSendingIdOnQuery (query, sendingId) {
  const parsedQuery = typeof query === 'string' ? JSON.parse(query) : query
  parsedQuery.$and.push({ _id: { $eq: sendingId } })
  return JSON.stringify(parsedQuery)
}

function analyticsHeadersKey (key) {
  let header = ''
  switch (key) {
    // NPS
    case 'pr':
      header = `${i18n.t('dashboard.graphics.promoters')}`
      break
    case 'nt':
      header = `${i18n.t('dashboard.graphics.neutral')}`
      break
    case 'dt':
      header = `${i18n.t('dashboard.graphics.detractors')}`
      break
      // CSAT 5EMO
    case 'vu':
      header = `${i18n.t('dashboard.graphics.vu')}`
      break
    case 'un':
      header = `${i18n.t('dashboard.graphics.un')}`
      break
    case 'ind':
      header = `${i18n.t('dashboard.graphics.ind')}`
      break
    case 'sat':
      header = `${i18n.t('dashboard.graphics.sat')}`
      break
    case 'vs':
      header = `${i18n.t('dashboard.graphics.vs')}`
      break
      // CSAT NUM
    case 'unsatisfied':
      header = `${i18n.t('dashboard.graphics.un')}`
      break
    case 'satisfied':
      header = `${i18n.t('dashboard.graphics.sat')}`
      break
      // KPI 5EMO
    case 'vb':
      header = `${i18n.t('dashboard.graphics.vb')}`
      break
    case 'bd':
      header = `${i18n.t('dashboard.graphics.bd')}`
      break
    case 'st':
      header = `${i18n.t('dashboard.graphics.st')}`
      break
    case 'vg':
      header = `${i18n.t('dashboard.graphics.vg')}`
      break
    case 'ex':
      header = `${i18n.t('dashboard.graphics.ex')}`
      break
      // KPI YND YN
    case 'y':
      header = `${i18n.t('dashboard.graphics.op1')}`
      break
    case 'mb':
      header = `${i18n.t('dashboard.graphics.op4')}`
      break
    case 'n':
      header = `${i18n.t('dashboard.graphics.op2')}`
      break
      // NES
    case 'le':
      header = `${i18n.t('dashboard.graphics.le')}`
      break
    case 'ee':
      header = `${i18n.t('dashboard.graphics.ee')}`
      break
    case 'me':
      header = `${i18n.t('dashboard.graphics.me')}`
      break
  }
  return header
}

function footerKeysForExport (type, ui, rating) {
  let result = { text: '', color: '' }

  if (type === 'nps') {
    if (rating >= 90) {
      result.text = `${i18n.t('dashboard.graphics.promoters')} - ${(rating / 10)}`
      result.color = '#00aaa7'
    }
    if (rating >= 70 && rating < 90) {
      result.text = `${i18n.t('dashboard.graphics.neutralString')} - ${(rating / 10)}`
      result.color = '#ffb500'
    }
    if (rating < 70) {
      result.text = `${i18n.t('dashboard.graphics.detractor')} - ${(rating / 10)}`
      result.color = '#ff607d'
    }
  }

  if (ui === 'yn') {
    if (rating === 100) {
      result.text = i18n.t('dashboard.graphics.op1')
      result.color = '#00aaa7'
    }
    if (rating === 0) {
      result.text = i18n.t('dashboard.graphics.op2')
      result.color = '#ff607d'
    }
  }

  if (ui === 'ynd') {
    if (rating === 100) {
      result.text = i18n.t('dashboard.graphics.op1')
      result.color = '#00aaa7'
    }
    if (rating === 0) {
      result.text = i18n.t('dashboard.graphics.op2')
      result.color = '#ff607d'
    }
    if (rating === 50) {
      result.text = i18n.t('dashboard.graphics.op3')
      result.color = '#ff607d'
    }
  }

  if (type === 'nes') {
    if (rating <= 25) {
      result.text = i18n.t('dashboard.graphics.le')
      result.color = '#00aaa7'
    }
    if (rating === 50) {
      result = i18n.t('dashboard.graphics.ee')
      result.color = '#ffb500'
    }
    if (rating >= 75) {
      result = i18n.t('dashboard.graphics.me')
      result.color = '#ff607d'
    }
  }

  if (type === 'csat' && ui === '10num') {
    if (rating >= 70) {
      result.text = i18n.t('dashboard.graphics.sat')
      result.color = '#00aaa7'
    }
    if (rating < 70) {
      result = i18n.t('dashboard.graphics.un')
      result.color = '#ff607d'
    }
  }

  if (type === 'csat' && ui === '5emo') {
    if (rating === 100) {
      result.text = i18n.t('dashboard.graphics.vs')
      result.color = '#49BB59'
    }
    if (rating === 75) {
      result = i18n.t('dashboard.graphics.sat')
      result.color = '#37B0E4'
    }
    if (rating === 50) {
      result = i18n.t('dashboard.graphics.ind')
      result.color = '#9E9E9E'
    }
    if (rating === 25) {
      result = i18n.t('dashboard.graphics.un')
      result.color = '#F26C3C'
    }
    if (rating === 0) {
      result = i18n.t('dashboard.graphics.vu')
      result.color = '#EE2E35'
    }
  }

  if (type === 'kpi' && ui === '5emo') {
    if (rating === 100) {
      result.text = i18n.t('dashboard.graphics.ex')
      result.color = '#49BB59'
    }
    if (rating === 75) {
      result = i18n.t('dashboard.graphics.vg')
      result.color = '#37B0E4'
    }
    if (rating === 50) {
      result = i18n.t('dashboard.graphics.satisf')
      result.color = '#9E9E9E'
    }
    if (rating === 25) {
      result = i18n.t('dashboard.graphics.bd')
      result.color = '#F26C3C'
    }
    if (rating === 0) {
      result = i18n.t('dashboard.graphics.vb')
      result.color = '#EE2E35'
    }
  }

  return result
}
