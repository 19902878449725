<template>
  <div class="container-filter-modal">
    <div class="content-filter-modal">
      <div class="header-filter-modal">
        <p class="title-filter-modal">{{ $t('advanced_filters.title') }}</p>
        <i class="material-icons icon-close-filter" @click="closeModal()" v-title="'Fechar'">close</i>
      </div>
      <div class="content-new-filter">
        <p class="title-new-modal">Criar filtro</p>
        <div class="buttons-filter">
          <button class="btn-new-filter" @click="addNewField()">{{ $t('dashboard.add_filter') }}</button>
          <InputNewFavorite :entity="entity" :fields="inputDefault" />
          <div class="content-checkbox-questions" @click="updateCheckBox('box')">
            <input type="checkbox" class="checkbox-empty-questions" v-model="cleanEmptyQuestions" @input="updateCheckBox($event.target.checked)">
            <p class="title-checkbox-questions">{{ $t('advanced_filters.clean_empty_questions') }}</p>
          </div>
        </div>
        <div class="section-filters">
          <ShimmerLoading v-if="isLoading" />
          <div class="cards-filters" v-else>
            <CardFilter
              v-for="(field, idx) in inputDefault"
              :key="idx"
              :idx="idx"
              :field="field"
              :entity="entity"
              :optionsCategoryList="optionsCategoryList"
              :optionsSubCategoryList="optionsSubCategoryList"
              :listAttendants="listAttendants"
              @delete-field="removeField"
            />
          </div>
        </div>
      </div>
      <FavoriteFilters :entity="entity" @loaded-filter="closeModal()" @loaded-filter-custom="applyQueryCustomDashboard($event)" />
      <div class="content-aplly-filter">
        <button class="clear-filter" @click="cleanFilter()">{{ $t('dashboard.clear_filters') }}</button>
        <button class="apply-filter" @click="apply()">{{ $t('dashboard.apply_filters') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import InputNewFavorite from './InputNewFavorite.vue'
import CardFilter from '@/components/filters/CardFilter'
import FavoriteFilters from '@/components/filters/FavoriteFilters'
import ShimmerLoading from '../customLoading/ShimmerLoading.vue'
import { filterFunctions } from '@/_helpers'
import('lodash-es').then((lodashEs) => { window._ = lodashEs.default })

export default {
  name: 'FilterModal',
  props: ['entity', 'queryCard'],
  components: {
    CardFilter,
    FavoriteFilters,
    ShimmerLoading,
    InputNewFavorite
  },
  data () {
    return {
      isLoading: true,
      modalToDelete: false,
      inputDefault: [this.defaultLayout()],
      optionsCategoryList: [],
      optionsSubCategoryList: [],
      listAttendants: []
    }
  },
  async mounted () {
    setTimeout(() => {
      const container = document.querySelector('.container-filter-modal')
      container.style.opacity = '1'
    })
    this.getData()
  },
  computed: {
    cleanEmptyQuestions () { return this.$route.query.cleanEmptyQuestions === 'true' },
    approachType () { return this.$store.getters['approaches/getSelectedType'] },
    options () { return this.$store.getters['filters/getDashFilter'].query.options },
    user () { return this.$store.getters['account/getUser'] },
    selecteds () {
      const result = window._.map(this.$store.getters['filters/getDashFilter'].query.$and, o => {
        if (['survey', 'respondedAt'].indexOf(Object.keys(o)[0]) >= 0 && this.entity !== 'sendings') { return [] }
        if (['createdAt', 'archivedAt'].indexOf(Object.keys(o)[0]) >= 0 && this.entity === 'sendings') { return [] }
        return o
      })
      return window._.reject(result, window._.isEmpty)
    },
    checkFieldMetadata () {
      let pass = true
      this.inputDefault.forEach(field => {
        if ((field.category === 'metadata' && field.middle.key !== '$exists') && !field.end.length) {
          pass = false
        }
      })
      return pass
    }
  },
  methods: {
    async getData () {
      try {
        let data = await filterFunctions.getFields(this.entity)

        if (this.entity === 'customDashboard' && this.queryCard) {
          data = await filterFunctions.getFields(this.entity, JSON.parse(this.queryCard))
        }

        if (data && data.close) { return this.closeModal() }

        this.inputDefault = data?.fields?.length > 0 ? data.fields : [this.defaultLayout()]
        this.optionsCategoryList = data.optionsCategoryList
        this.optionsSubCategoryList = data.optionsSubCategoryList
        this.listAttendants = data.listAttendants
      } catch (error) {
        console.error('Error during filter loading:', error)
      } finally {
        this.isLoading = false
      }
    },
    closeModal () {
      const container = document.querySelector('.container-filter-modal')
      const modal = document.querySelector('.content-filter-modal')
      container.style.opacity = '0'
      modal.style.opacity = '0'
      setTimeout(() => { this.$emit('close') }, 700)
    },
    defaultLayout () {
      return {
        category: '',
        start: '',
        middle: '',
        end: ''
      }
    },
    updateCheckBox (e) {
      if (e === 'box') {
        const checkboxInput = document.querySelector('.checkbox-empty-questions')
        const divCheckbox = document.querySelector('.content-checkbox-questions')
        checkboxInput.checked = !checkboxInput.checked

        divCheckbox.style.borderColor = checkboxInput.checked ? '#5897fb' : '#cacaca'

        this.$route.query.cleanEmptyQuestions = `${checkboxInput.checked}`
        return
      }

      this.$route.query.cleanEmptyQuestions = `${e}`
    },
    async cleanFilter () {
      if (this.entity === 'customDashboard') { return this.clearFilterCardCustomDashboard() }

      const result = await filterFunctions.clear(this.entity)
      if (result) {
        this.inputDefault = [this.defaultLayout()]

        if (this.entity === 'insights') {
          const query = { ...this.$route.query }
          delete query.q
          this.$router.push({ name: 'Insights', query })
            .catch(err => { if (err.name !== 'NavigationDuplicated') console.log('error when updating url with filters', err) })
        } else {
          this.$route.query.q = result
          this.updateCurrentUrl()
        }

        setTimeout(() => { this.$root.$emit('filter-updated'); this.closeModal() }, 600)
      }
    },
    removeField (index) {
      this.inputDefault.splice(index, 1)
    },
    addNewField () {
      this.inputDefault.push(this.defaultLayout())
    },
    updateCurrentUrl () {
      this.$router.push({ query: { ...this.$route.query, q: JSON.stringify(this.$route.query.q) } })
        .catch(err => { if (err.name !== 'NavigationDuplicated') console.log('error when updating url with filters', err) })
    },
    clearFilterCardCustomDashboard () {
      this.$emit('remove-filter-dash-card')
      this.inputDefault = [this.defaultLayout()]

      this.closeModal()
    },
    async applyQueryCustomDashboard (queryCustom) {
      try {
        let filterToApply = queryCustom

        if (!queryCustom) {
          filterToApply = await filterFunctions.apply(this.$route, this.inputDefault, true)
        }

        this.$emit('query-to-custom-card', filterToApply)

        this.$store.commit('alerts/alert', {
          message: 'Filtro aplicado',
          position: 'bottomRight',
          showAlert: true
        })
      } catch (error) {
        console.error('Error during filter loading:', error)
      } finally {
        this.isLoading = false
        this.closeModal()
      }
    },
    async apply () {
      if (!this.checkFieldMetadata) { return }

      if (this.entity === 'customDashboard') { return this.applyQueryCustomDashboard() }

      const queryFormatted = await filterFunctions.apply(this.$route, this.inputDefault)
      if (queryFormatted) {
        this.$route.query.q = queryFormatted
        this.updateCurrentUrl()
        this.closeModal()
        setTimeout(() => {
          this.$root.$emit('filter-updated')
          this.isLoading = false
        }, 700)
      }
    },
    statusOption () {
      const status = [
        {
          label: this.$i18n.t('advanced_filters.fields.sent'),
          value: 'sent'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.rejected'),
          value: 'rejected'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.deferred'),
          value: 'deferred'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.spam'),
          value: 'spam'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.bounced'),
          value: 'bounced'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.soft_bounced'),
          value: 'soft-bounced'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.Unsub'),
          value: { optedOutAt: { $exists: true } }
        }
      ]
      return status
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
}
.container-filter-modal {
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  padding: 1rem;
  background: #00000035;
  transition: opacity 0.5s;
  overflow-y: auto;
  .content-filter-modal {
    position: relative;
    width: 100%;
    min-height: 27rem;
    background: #ffffff;
    padding: 1rem;
    padding-bottom: 4rem;
    border-radius: 0.3rem;
    border: 1px solid #cacaca;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    transition: opacity 0.6s;
    .header-filter-modal {
      position: relative;
      width: 100%;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .title-filter-modal {
        font-size: 1.7rem;
        color: #454548;
        font-weight: bold;
        line-height: 1.7rem;
      }
      .icon-close-filter {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1.8rem;
        cursor: pointer;
        color: var(--accent-color);
      }
    }
    .content-new-filter {
      width: 100%;
      min-height: 5rem;
      .title-new-modal {
        font-size: 1.1rem;
        font-weight: bold;
        color: #454548;
        line-height: 1.7rem;
        margin-bottom: 0.5rem;
      }
      .buttons-filter {
        position: relative;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .btn-new-filter {
          width: 8rem;
          height: 2rem;
          border-radius: 0.3rem;
          background: var(--accent-color);
          color: var(--default-text-color);
          box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.070);
          font-size: 0.9rem;
          background-image: linear-gradient(
            -60deg,
            transparent, transparent 40%,
            #ffffff44 40%, #ffffff44 60%,
            transparent 60%, transparent 100%
          );
          background-size: 200% 100%;
          background-repeat: no-repeat;
          background-position-x: 150%;
        }
        .btn-new-filter:hover {
          background-position-x: -150%;
          transition: background-position-x 1s;
        }
        .content-checkbox-questions {
          position: absolute;
          height: 2rem;
          cursor: pointer;
          border-radius: 0.3rem;
          padding: 0.3rem;
          border: 0.1rem solid #5897fb;
          box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.070);
          right: 0;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .title-checkbox-questions {
            font-size: 0.8rem;
            color: #454548;
          }
          .checkbox-empty-questions {
            width: 0.9rem;
            height: 0.9rem;
          }
        }
      }
      .section-filters {
        .cards-filters {
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
      }
    }
    .content-aplly-filter {
      position: absolute;
      bottom: -1rem;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      .apply-filter, .clear-filter {
        width: 7rem;
        height: 2rem;
        font-size: 0.9rem;
        border-radius: 0.3rem;
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.070);
        background: var(--accent-color);
        color: var(--default-text-color);
        background-image: linear-gradient(-60deg, transparent, transparent 40%, #ffffff44 40%, #ffffff44 60%, transparent 60%, transparent 100%);
        background-size: 200% 100%;
        background-repeat: no-repeat;
        background-position-x: 150%;
      }
      .clear-filter {
        background: #ffffff;
        border: 0.1rem solid var(--accent-color);
        color: var(--accent-color);
      }
      .apply-filter:hover, .clear-filter:hover {
        background-position-x: -150%;
        transition: background-position-x 1s;
      }
    }
  }
}
</style>
