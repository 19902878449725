import { tagsService, accountService, ticketsService, filtersService } from '@/_services'
import { formatters, constants } from '@/_helpers'
import i18n from '../translations/i18n'
import store from '../store'
const moment = require('moment')
import('lodash-es').then((lodashEs) => { window._ = lodashEs.default })

export const filterFunctions = {
  apply,
  clear,
  getFields,
  selecteds,
  statusOption,
  loadFavoriteFilters,
  createFavoriteFilter
}

async function createFavoriteFilter (entity, nameFilter, route, fields) {
  const updateQuery = await apply(route, fields)

  if (!updateQuery) { return null }

  const currentFilters = store.getters['filters/getDashFilter'].query.$and
  const filtersWithoutRespondedAt = currentFilters.filter(item => !item.respondedAt)

  const filterToCreate = {
    query: {
      $and: filtersWithoutRespondedAt
    },
    entity,
    name: nameFilter
  }

  const result = await filtersService.createCustomFilter(filterToCreate)
  return result
}

async function loadFavoriteFilters (idFilter, entity) {
  const result = await filtersService.getSpecificFilter(idFilter)

  if (result.query && result.query.$and) {
    const toQuery = result.query.$and
    const dateMetric = store.state.filters.currentDateMetric

    const hasDateOnQuery = store.getters['filters/getDashFilter'].query.$and.some(item => item[dateMetric])
    const hasGteDateOnQuery = hasDateOnQuery && window._.find(store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$gte`)
    const hasLteDateOnQuery = hasDateOnQuery && window._.find(store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$lte`)

    const hasGteDateOnFavoriteQuery = window._.find(toQuery, `${dateMetric}.$gte`)
    const hasLteDateOnFavoriteQuery = window._.find(toQuery, `${dateMetric}.$lte`)

    if (hasGteDateOnQuery && !hasGteDateOnFavoriteQuery) {
      toQuery.push(hasGteDateOnQuery)
    }

    if (hasLteDateOnQuery && !hasLteDateOnFavoriteQuery) {
      toQuery.push(hasLteDateOnQuery)
    }

    const correctDateFromToUpdateFilter = window._.find(toQuery, `${dateMetric}.$gte`) || {}
    const correctDateToUpdateFilter = window._.find(toQuery, `${dateMetric}.$lte`) || {}

    const objectFilter = {
      query: {
        [dateMetric]: {
          $gte: correctDateFromToUpdateFilter[dateMetric]?.$gte || '',
          $lte: correctDateToUpdateFilter[dateMetric]?.$lte || ''
        }
      }
    }

    if (entity !== 'customDashboard') {
      store.commit('filters/updateFilterAnd', toQuery)
      store.commit('filters/updateDashFilter', objectFilter)
      return { $and: store.getters['filters/getDashFilter'].query.$and }
    }

    return { $and: toQuery }
  }
}

function statusOption () {
  const status = [
    {
      label: i18n.t('advanced_filters.fields.sent'),
      value: 'sent'
    },
    {
      label: i18n.t('advanced_filters.fields.rejected'),
      value: 'rejected'
    },
    {
      label: i18n.t('advanced_filters.fields.deferred'),
      value: 'deferred'
    },
    {
      label: i18n.t('advanced_filters.fields.spam'),
      value: 'spam'
    },
    {
      label: i18n.t('advanced_filters.fields.bounced'),
      value: 'bounced'
    },
    {
      label: i18n.t('advanced_filters.fields.soft_bounced'),
      value: 'soft-bounced'
    },
    {
      label: i18n.t('advanced_filters.fields.Unsub'),
      value: { optedOutAt: { $exists: true } }
    }
  ]
  return status
}

function selecteds (entity) {
  const result = window._.map(store.getters['filters/getDashFilter'].query.$and, o => {
    if (['survey', 'respondedAt'].indexOf(Object.keys(o)[0]) >= 0 && entity !== 'sendings') {
      return []
    }
    if (['createdAt', 'archivedAt'].indexOf(Object.keys(o)[0]) >= 0 && entity === 'sendings') {
      return []
    }
    return o
  })
  return window._.reject(result, window._.isEmpty)
}

async function apply (route, fields, isCustom) {
  const currentEntity = store.getters['filters/getCurrentEntity']
  const approachType = store.getters['approaches/getSelectedType']
  const toQuery = []
  const dateMetric = store.state.filters.currentDateMetric
  const findArchivedAt = window._.find(store.getters['filters/getDashFilter'].query.$and, 'archivedAt')
  const findSurveyOnFilter = window._.find(store.getters['filters/getDashFilter'].query.$and, 'survey')
  const hasGteDateOnQuery = window._.find(store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$gte`)
  const hasLteDateOnQuery = window._.find(store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$lte`)
  const hasSomeFieldUsingSurveyCategory = window._.findIndex(fields, item => item.category === 'survey')
  if (findSurveyOnFilter && hasSomeFieldUsingSurveyCategory === -1) { toQuery.push(findSurveyOnFilter) }
  if (route.name === 'Approaches' && findArchivedAt) { toQuery.push(findArchivedAt) }
  if (hasGteDateOnQuery) { toQuery.push(hasGteDateOnQuery) }
  if (hasLteDateOnQuery) { toQuery.push(hasLteDateOnQuery) }

  fields.forEach(element => {
    const containAnalysis = element.start.category?.includes('artificialAnalysis')
    if (window._.isEmpty(element.start)) { this.isLoading = false; return false }

    if ((element.start.code === 'responses' || element.start.code === 'survey') && element.start.toQuery) {
      toQuery.push({ [element.start.code]: element.start.toQuery })
    } else if (window._.isEmpty(element.end) && !window._.isEmpty(element.middle.key)) {
      let hasToQuery = { [element.middle.key]: element.middle.toQuery }

      if (!element.middle.toQuery && element.start.code !== 'respondedAt') { hasToQuery = element.middle.key }

      toQuery.push({ [element.start.code]: hasToQuery })
    } else {
      if (typeof element.end === 'string') {
        let value = element.end ? element.end : element.middle.toQuery
        if (element.middle.input && element.middle.operator) {
          element.middle.toQuery[element.middle.operator] = element.end
          value = element.middle.toQuery
        }
        if (!value && element.category === 'channel') {
          toQuery.push({ type: { [element.start.key]: element.middle.label } })
        } else if (element.category === 'status') {
          const startKey = element.start.key
          const filterState = typeof element.middle.value === 'object' ? { optedOutAt: { $exists: startKey === '$eq' } } : { state: { [element.start.key]: element.middle.value } }
          if (approachType.type === 'all') {
            toQuery.push(filterState)
          } else {
            let exists = false
            approachType.type === 'active' ? exists = false : exists = true
            toQuery.push({ archivedAt: { $exists: exists } }, filterState)
          }
        } else if (element.category === 'date.send') {
          const keyDate = element.start.code === 'date.$gte' ? '$gte' : element.start.code === 'date.$lte' ? '$lte' : element.start.code === 'date.$gt' ? '$gt' : '$lt'
          const formattedValue = moment(element.start.date).add(-3, 'hours').toISOString()
          toQuery.push({ 'sending.createdAt': { [keyDate]: formattedValue } })
        } else if ((currentEntity === 'insights' || currentEntity === 'customDashboard') && containAnalysis) {
          const objToQuery = {
            responses: {
              $elemMatch: {
                [element.start.category]: {
                  $eq: element.start.code
                }
              }
            }
          }
          toQuery.push(objToQuery)
        } else {
          toQuery.push({ [element.start.code]: { [element.middle.key]: value } })
        }
      } else {
        if (element.start.code === 'tags') {
          toQuery.push({ [element.start.code]: { [element.middle.key]: element.end } })
        }
        if (element.start.code === 'ticket.lastStatusUpdate.label') {
          toQuery.push({ [element.start.code]: { [element.middle.key]: element.end.value } })
        }
        if (element.start.code === 'ticket.category' || element.start.code === 'ticket.parentCategory') {
          toQuery.push({ [element.start.code]: { [element.end.key]: element.end.id } })
        }
        if (element.start.code === 'ticket.attendant') {
          toQuery.push({ [element.start.code]: { [element.end.key]: element.end.id } })
        }
      }
    }
  })

  if (isCustom) {
    return { $and: toQuery }
  }

  store.commit('filters/updateFilterAnd', toQuery)
  const routeQueryQ = { $and: store.getters['filters/getDashFilter'].query.$and }
  return routeQueryQ
}

async function getFields (entity, fieldsCard) {
  const options = store.getters['filters/getDashFilter'].query.options
  const accountId = store.getters['account/getUser'].account._id
  const fetchAll = [
    tagsService.getTags(),
    accountService.getUsers(),
    ticketsService.getTicketCategories(),
    accountService.getAccount(accountId)
  ]
  const [getTags, attendantUsers, allCategories, account] = await Promise.all(fetchAll)

  const data = {
    fields: [],
    listAttendants: [],
    optionsCategoryList: [],
    optionsSubCategoryList: []
  }

  allCategories.forEach(item => {
    data.optionsCategoryList.push({ label: item.title, id: item._id, key: '$eq' })
    item.subcategories.forEach(o => {
      data.optionsSubCategoryList.push({ label: o.title, id: o._id, key: '$eq' })
    })
  })

  attendantUsers.forEach(item => { data.listAttendants.push({ label: item.name, id: item._id, key: '$eq' }) })

  if (!store.getters['dashboard/getCurrentSurvey'] && entity !== 'sendings' && entity !== 'insights') {
    return { close: true }
  }
  store.commit('account/setAccount', account)
  store.commit('filters/updateOptionValues', { tags: getTags })
  const hasFilter = entity === 'customDashboard' && fieldsCard ? fieldsCard : selecteds(entity)
  if (account.fields) {
    account.fields.push(constants.metadataResend)

    account.fields.map(function (o) {
      if (o.key) { store.commit('filters/insertMetadataField', { key: o.key, label: o.label }) }
    })
  }
  if (store.getters['dashboard/getCurrentSurvey']) {
    const questions = store.getters['dashboard/getCurrentSurvey'].questions
    questions.map(function (question) {
      if (['ces', 'nes', 'nps', 'single', 'nvs', 'csat'].indexOf(question.type) > -1) { store.commit('filters/insertQuestionField', question) }
    })
  }
  if (hasFilter.length) {
    hasFilter.forEach(element => {
      const optionName = Object.keys(element)[0]
      let option = window._.find(options, { code: optionName })

      if ((entity === 'insights' || entity === 'customDashboard') && optionName === 'responses' && element[optionName].$elemMatch) {
        option = window._.find(options, { code: element[optionName].$elemMatch[Object.keys(element[optionName].$elemMatch)[0]].$eq })
      }

      if ((entity !== 'insights' && entity !== 'customDashboard') && optionName === 'responses' && element[optionName].$elemMatch) {
        option = window._.find(options, { code: 'responses', toQuery: element[optionName] })
        if (element[optionName].$elemMatch['question._id']) { option = window._.find(options, { code: 'responses', _id: element[optionName].$elemMatch['question._id'] }) }
      }

      if (optionName === 'survey' && element[optionName].$eq) {
        const hasSomeFieldUsingSurveyCategory = window._.findIndex(data.fields, item => item.category === 'survey')
        if (hasSomeFieldUsingSurveyCategory >= 0) return
        option = window._.find(options, { code: 'survey', _id: element[optionName].$eq })
      }

      if (optionName === 'optedOutAt' && typeof element[optionName] === 'object') {
        const findedIndexState = window._.findIndex(options, { code: 'state' })
        if (findedIndexState >= 0) {
          const keyToFind = element[optionName].$exists ? '$eq' : '$ne'
          option = window._.find(options[findedIndexState].select, { key: keyToFind })
        }
      }

      if (optionName === 'sending.createdAt' && element[optionName]) {
        const findedIndexState = window._.findIndex(options, { category: 'date.send' })
        if (findedIndexState >= 0) {
          const keyToFind = element[optionName].$gte ? '$gte' : element[optionName].$lte ? '$lte' : element[optionName].$gt ? '$gt' : '$lt'
          option = window._.find(options, { code: `date.${keyToFind}`, category: 'date.send' })
        }
      }

      if (optionName === 'type' && element[optionName]) { option = window._.find(options, { code: 'type', category: 'channel' }) }

      let start = ''
      let category = ''
      let middle = ''
      let end = ''

      if (option) {
        start = option
        if (element[optionName]) {
          const objectToFindOption = { key: Object.keys(element[optionName])[0] }
          if (option.code === 'responses' && option._id) {
            if (element[optionName].$elemMatch.rating) { objectToFindOption.toQuery = { rating: element[optionName].$elemMatch.rating } }
            if (element[optionName].$elemMatch.$eq) { objectToFindOption.operator = '$eq' }
            if (element[optionName].$elemMatch.$ne) { objectToFindOption.operator = '$ne' }
          }
          if (option.code === 'doneAt' || option.code === 'ticket') {
            objectToFindOption.toQuery = element[optionName][objectToFindOption.key]
            objectToFindOption.key = '$exists'
          }
          if (option.code === 'respondedAt' && option.category === 'responses') { objectToFindOption.toQuery = element[optionName][objectToFindOption.key] }

          if (option.category === 'metadata' && Object.keys(element[optionName])[0] === '$exists') { objectToFindOption.toQuery = element[optionName][objectToFindOption.key] }
          let optionSelected = window._.find(option.select, objectToFindOption)

          if (option.code === 'hasNotes' || option.code === 'isSolved') {
            const findEq = { toQuery: element[optionName][Object.keys(element[optionName])[0]] }
            optionSelected = window._.find(option.select, findEq)
          }

          if (optionSelected) { middle = optionSelected }
        }

        if (middle && element[optionName][middle.key] && typeof middle.toQuery !== 'object') { end = element[optionName][middle.key] }
        if (middle.input && option.code === 'responses' && option._id) { end = element[optionName].$elemMatch[middle.operator] }
        if (start.code === 'tags') { end = element[optionName][middle.key] }
        if (start.category) { category = start.category }
        if (start.code === 'type') {
          start = middle
          middle = { label: end }
          end = ''
        }
        if (start.code === 'state') {
          start = middle
          let selected = {}
          typeof end === 'string' ? selected = statusOption().find(o => o.value === end) : selected = { label: this.$i18n.t('advanced_filters.fields.Unsub') }
          if (selected) { middle = selected.label }
        }
        if (element.optedOutAt) {
          category = 'status'
          let selected = {}
          selected = statusOption().find(o => typeof o.value === 'object')
          if (selected) { middle = end ? selected.label : selected }
        }
        if (start.category === 'date.send') {
          const optionDate = Object.keys(element[optionName])[0]
          const date = element[optionName][optionDate]
          const formatedDate = formatters.formatDateByLang(date, this.user.language, 'YYYY-MM-DD')
          start.date = formatedDate
        }

        // COMENTADO POIS PODE SER USADO FUTURAMENTE

        // if (option.code === 'health.conversion') {
        //   const labelConversion = Object.keys(element[optionName])[0]
        //   const midConversion = element[optionName][labelConversion]
        //   end = ''
        //   middle = option.select.find(mid => mid.toQuery === midConversion)
        // }

        if (option.code === 'health.score' || option.code === 'health.churn') {
          const value = Object.keys(element[optionName])[0]
          const objConversion = element[optionName]
          end = ''
          middle = option.select.find(mid => mid.key[value] === objConversion[value])
        }

        data.fields.push({ category, start, middle, end })
      }
    })
  }
  return data
}

function clear (entity) {
  const dateMetric = store.state.filters.currentDateMetric
  const findSurveyOnFilter = window._.find(store.getters['filters/getDashFilter'].query.$and, 'survey')
  const hasGteDateOnQuery = window._.find(store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$gte`)
  const hasLteDateOnQuery = window._.find(store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$lte`)
  store.commit('filters/updateFilterAnd', [])

  if (['sending-responses', 'ranking'].indexOf(entity) > -1) {
    store.commit('filters/updateFilterAnd', [findSurveyOnFilter])
  }

  if (hasGteDateOnQuery) { store.commit('filters/createOrUpdateSpecificKey', hasGteDateOnQuery) }
  if (hasLteDateOnQuery) { store.commit('filters/createOrUpdateSpecificKey', hasLteDateOnQuery) }

  return { $and: store.getters['filters/getDashFilter'].query.$and }
}
