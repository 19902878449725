<template>
  <div class="container-modal-others">
    <div class="content-modal-others">
      <div class="header-modal-others">
        <i class="material-icons close-modal-others" @click="closeModalOthers()">close</i>
        <div class="titles-modal-others">
          <app-heading level="h4" class="title-others">{{ data.question.title }}</app-heading>
          <app-heading level="h5" class="title-others">{{ data.question.question }}</app-heading>
        </div>
        <div class="count-modal-others">
          <app-heading level="h4" class="title-others">{{ responses.length }}</app-heading>
          <app-heading level="h6" class="title-others">{{ $t('sendings.responses') }}</app-heading>
        </div>
      </div>
      <div class="search-text-other">
        <label class="label-input-other" for="input-text-other">
          <app-heading level="h7" class="title-others">{{ $t('bvs.search_text') }}</app-heading>
          <input type="text" name="" id="input-text-other" v-model="search">
        </label>
        <i class="material-icons-outlined icon-others-tag" v-title="$t('tags.open_tag_manager')" @click="showTagManager = true">sell</i>
      </div>
      <div class="responses-modal-other">
        <div class="card-text-other" v-for="resp in currentPageItems" :key="resp._id">
          <app-heading level="h7" class="contact-other" v-if="resp.contact.name">{{ resp.contact.name }}</app-heading>
          <app-heading level="h7" class="date-other" v-if="resp.createdAt">{{ moment(resp.createdAt) }}</app-heading>
          <app-heading level="h7" class="text-other">{{ resp.text }}</app-heading>
          <div class="tags-others">
            <p level="h7" class="tags-other" v-title="$t('tags.add_tag')" @click="openSelectTag(true, resp._id)">+ {{ $t('advanced_filters.categories.tags') }}</p>
            <select class="select-tag-other" :class="`tag-${resp._id}`" @change="addTag($event.target.value, resp)">
              <option value="" selected>Selecione</option>
              <option v-for="tag in tags" :key="tag._id" :value="tag.name">{{ tag.name }}</option>
            </select>
            <div class="section-tags-selecteds">
              <p v-for="tag in resp.tags" :key="tag._id"
                class="tags-other-seleted"
                :style="{'background': tag.color}"
                @click="openToDeleteTag(resp._id, tag._id)"
                >{{ tag.name }} <i class="material-icons-outlined remove-tag-other" :class="`showDeleteTag-${resp._id}${tag._id}`" @click="deleteTag(resp, tag)" v-title="$t('tags.remove_tag')">close</i>
              </p>
            </div>
          </div>
          <div class="details-other">
            <i class="material-icons-outlined icon-others" v-title="$t('tags.see_more')" @click="openDetails(resp)">turn_right</i>
          </div>
        </div>
      </div>
      <div class="pagination-list-responses">
        <app-paginate :initValue="1" :prevText="'<'" :nextText="'>'" @input="changePage" :pageRange="itemPerPage" :pageCount="dynamicPageCount"></app-paginate>
      </div>
      <modal-alert v-if="showModal" :data="tagToDelete" />
      <TagManager v-if="showTagManager" @close-tag-manager="updateTags()"></TagManager>
      <div class="loading-others" v-if="loading">
        <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
      </div>
    </div>
    <Details
      v-if="showDetails"
      :id="sendingId"
      @closeSendings="showDetails = false"
      :class="{'expand-details': showDetails}"
    ></Details>
  </div>
</template>

<script>
import { tagsService, dashboardsService } from '@/_services'
import { formatters } from '@/_helpers'
import ModalAlert from '../../alerts/ModalAlert.vue'
import TagManager from '../../tags/TagManager.vue'

export default {
  name: 'TextModalOthers',
  props: ['data'],
  components: {
    'app-paginate': () => import('@binds-tech/binds-design-system/src/components/Paginate/Paginate'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    Details: () => import('../../SideBarConfig/SidebarSendings.vue'),
    ModalAlert,
    TagManager
  },
  data () {
    return {
      loading: true,
      responses: [],
      tags: [],
      search: '',
      sendingId: '',
      showModal: false,
      tagToDelete: [],
      showTagManager: false,
      showDetails: false,
      page: 1,
      itemPerPage: 4,
      dynamicPageCount: 0
    }
  },
  async mounted () {
    this.responses = []
    setTimeout(() => {
      const content = document.querySelector('.content-modal-others')
      content.style.opacity = 1
    }, 100)

    const resultTags = await tagsService.getTags()

    if (resultTags) {
      this.tags = resultTags
    }

    const query = formatters.formatDateByAddTimezone(this.$route.query.q, 3, 'hours')
    const result = await dashboardsService.getTextDash({ q: query }, this.data.question._id, false)
    if (result) {
      result.forEach(item => { item.tagInput = '' })
      this.responses = result
      this.loading = false
      this.updatePagination()
    }

    this.$root.$on('close-modal-delete-tag', () => {
      this.showModal = false
    })
  },
  computed: {
    lang () {
      return this.$store.getters['account/getLang']
    },
    filteredResponse () {
      const filtered = this.responses.filter(resp => {
        return resp.text.toLowerCase().includes(this.search.toLowerCase())
      })
      return filtered
    },
    currentPageItems () {
      let filterIndex = this.page
      if (this.page > this.filteredResponse.length) {
        filterIndex = 1
      }
      return this.filteredResponse.slice((filterIndex * this.itemPerPage) - this.itemPerPage, filterIndex * this.itemPerPage)
    }
  },
  methods: {
    moment (eventDate) {
      this.$moment.locale(this.lang)
      return this.$moment(eventDate).fromNow()
    },
    updatePagination () {
      const itemsLength = (this.responses.length / 4)
      if (Number.isInteger(itemsLength)) {
        this.dynamicPageCount = itemsLength
      } else {
        this.dynamicPageCount = Math.trunc(itemsLength) + 1
      }
    },
    changePage (page) {
      this.page = page
    },
    openDetails (item) {
      this.showDetails = false
      this.sendingId = item.sending
      setTimeout(() => { this.showDetails = true }, 100)
      const objectToLoad = { q: this.$route.query.q, sending: item.sending }
      this.$router.push({ path: this.$route.path, query: objectToLoad }).catch(() => { })
    },
    async updateTags () {
      const resultTags = await tagsService.getTags()

      if (resultTags) {
        this.tags = resultTags
        this.showTagManager = false
      }
    },
    openToDeleteTag (id, idTwo) {
      const tagInput = document.querySelector(`.showDeleteTag-${id}${idTwo}`)
      if (tagInput.style.display === 'flex') {
        tagInput.style.display = 'none'
      } else {
        tagInput.style.display = 'flex'
      }
    },
    async deleteTag (item, tagToDelete) {
      this.showModal = true
      this.tagToDelete = { item, tagToDelete }
    },
    async addTag (tagName, item) {
      item.tagInput = tagName
      const tagsToSave = []
      const [foundTag] = this.tags.filter(i => i.name === item.tagInput)

      if (foundTag) {
        tagsToSave.push(foundTag._id)
      }

      if (item.tagInput === '' || tagsToSave.length === 0) { return false }

      if (item.tags) {
        item.tags.map(item => {
          if (!tagsToSave.includes(item._id)) {
            tagsToSave.push(item._id)
          }
        })
      }

      const add = await tagsService.updateTagToResponse(item.sending, tagsToSave)
      if (add === undefined) {
        return this.$store.commit('alerts/alert', {
          message: this.$t('activity.tag_error_add'),
          position: 'bottomRight',
          showAlert: true
        })
      } else {
        this.openSelectTag(false, item._id)
      }

      const contains = item.tags.findIndex(item => item._id === foundTag._id)
      if (contains < 0) {
        item.tags.push(foundTag)
        item.tagInput = ''
      }

      item.tagInput = ''
    },
    openSelectTag (e, id) {
      const selectInput = document.querySelector(`.tag-${id}`)
      if (e) {
        selectInput.style.opacity = '1'
        selectInput.style.width = '180px'
        selectInput.style.border = '1px solid #cacaca'
      } else {
        selectInput.style.width = '0px'
        selectInput.style.border = 'none'
        setTimeout(() => {
          selectInput.style.opacity = '0'
        }, 200)
      }
      selectInput.value = ''
    },
    closeModalOthers () {
      const content = document.querySelector('.content-modal-others')
      content.style.opacity = 0
      setTimeout(() => {
        this.$root.$emit('close-modal-others')
      }, 400)
    }
  }
}
</script>

<style lang="scss">
.container-modal-others {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #00000070;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-modal-others {
    position: relative;
    opacity: 0;
    transition: opacity 0.5s;
    width: 900px;
    height: 660px;
    background: #eff3f8;
    border-radius: 5px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.12);
    .header-modal-others {
      position: relative;
      width: 100%;
      height: 13%;
      padding: 10px;
      border-radius: 5px 5px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title-others {
        font-weight: 500 !important;
        color: #454548;
      }
      .close-modal-others {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        background: var(--accent-color);
        color: var(--default-text-color);
        border-radius: 50%;
        font-size: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .titles-modal-others {
        width: 85%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title-others:first-child {
          margin-bottom: 15px !important;
        }
      }
      .count-modal-others {
        width: 15%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .search-text-other {
      width: 100%;
      height: 10%;
      padding: 0 10px;
      border-bottom: 1px solid #cacaca;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label-input-other {
        width: 80%;
        #input-text-other {
          border: none;
          width: 50%;
          background: #f8f8f8;
          border: 1px solid #cacaca;
          height: 25px;
          border-radius: 5px;
          margin-top: 5px;
          padding-left: 5px;
        }
      }
      .icon-others-tag {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #cacaca;
        color: #cacaca;
        font-size: 24px;
        padding: 2px 0 0 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .icon-others-tag:hover {
        cursor: pointer;
        color: var(--accent-color);
        border: 1px solid var(--accent-color);
      }
    }
    .responses-modal-other {
      width: 100%;
      height: 70%;
      padding: 10px;
      border-radius: 0 0 5px 5px;
      overflow-y: auto;
      .card-text-other {
        position: relative;
        background: #f8f8f8;
        width: 100%;
        min-height: 100px;
        padding: 15px 5px 5px 5px;
        border: 1px solid #cacaca;
        border-radius: 5px;
        margin-bottom: 9px;
        display: flex;
        .contact-other {
          position: absolute;
          top: -8px;
          left: 15px;
          background: #f8f8f8;
          padding: 1px 7px;
          font-size: 13px !important;
          border: 1px solid #cacaca;
          border-radius: 5px;
          color: #454548;
        }
        .date-other {
          position: absolute;
          top: -8px;
          right: 15px;
          background: #f8f8f8;
          padding: 1px 7px;
          font-size: 13px !important;
          border: 1px solid #cacaca;
          border-radius: 5px;
          color: #454548;
        }
        .text-other {
          width: 50%;
          font-style: italic;
          padding-left: 5px;
          color: #454548;
        }
        .tags-others {
          position: relative;
          background: #eff3f8;
          padding: 12px 5px 5px 5px;
          border: 1px solid #cacaca;
          border-radius: 5px;
          width: 40%;
          height: 79px;
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          .tags-other {
            position: absolute;
            left: 15px;
            top: -10px;
            width: fit-content;
            border: 1px solid #cacaca;
            border-radius: 5px;
            margin: 0;
            font-size: 13px;
            padding: 1px 5px;
            background: #ffffff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            color: #454548;
          }
          .select-tag-other {
            position: absolute;
            top: -11px;
            right: 15px;
            opacity: 0;
            width: 0;
            transition: width 0.7s;
            outline: none;
            border: none;
            border-radius: 5px;
            font-size: 13px;
            background: #ffffff;
            color: #454548;
          }
          .section-tags-selecteds {
            width: 100%;
            height: 65px;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            overflow-y: auto;
            .tags-other-seleted {
              color: var(--default-text-color);
              border-radius: 5px;
              background: #ffffff;
              padding: 1px 5px;
              margin-right: 2px;
              margin-bottom: 2px;
              width: fit-content;
              transition: width 0.7s;
              border: 1px solid #cacaca;
              font-size: 13px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              .remove-tag-other {
                display: none;
                font-size: 13px;
                margin-left: 2px;
                color: var(--accent-color);
              }
            }
          }
        }
        .details-other {
          width: 10%;
          min-height: 60px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction: column;
          .icon-others {
            cursor: pointer;
            width: 27px;
            height: 27px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 20px;
            color: #cacaca;
            border: 1px solid #cacaca;
          }
          .icon-others:hover {
            color: var(--accent-color);
            border: 1px solid var(--accent-color);
          }
        }
      }
    }
    .pagination-list-responses {
      background: transparent !important;
      width: 100%;
      height: 7%;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      .pagination {
        height: 25px;
        .page-link-item, .prev-link-item, .next-link-item {
          padding: 2px 10px !important;
          font-size: 13px;
        }
      }
    }
    .loading-others {
      width: 100%;
      height: 100%;
      background: #00000070;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
