<template>
  <div class="container-survey-list">
    <div class="loading-survey-list" v-show="isListLoading">
      <Circle2 class="spinner-loading-survey-list"/>
    </div>
    <Menu :showSubMenu="false" />
    <div class="content-survey-list" v-if="!isListLoading">
      <div class="header-survey-list">
        <div class="titles-survey-list">
          <app-heading level="h3">{{ $t('surveyList.my_surveys') }}</app-heading>
          <app-heading level="h6" class="surveys-total" v-if="surveys.length">{{ surveys.length }} {{ labelTotal }}</app-heading>
        </div>
        <div class="options-survey-list">
          <div class="options-one-survey-list" v-if="allowedProfile">
            <button class="btn-survey-list second-btn" @click="goToTemplates()">{{ $t('surveyList.templates') }}</button>
            <button class="btn-survey-list" @click="isModalOpen = true">{{ $t('surveyList.new_survey') }}</button>
          </div>
          <div class="options-two-survey-list">
            <app-select-binds
              :options="filterOptionsData"
              :titleItem="'label'"
              id="surveys"
              :value="filterOptions"
              @model="filterOptions = $event; key++"
            />
            <input type="text" class="input-search-survey" :placeholder="$t('surveyList.search_by_title')" v-model="search" @input="updatePagination()">
          </div>
        </div>
      </div>
      <div class="section-survey-list" :key="key">
        <CardSurvey
          v-for="(item, idx) in currentPageItems"
          :key="idx"
          :dataSurvey="item"
          @delete-survey="deleteSurvey($event, idx)"
          @to-redirect="redirectTo($event)"
          @to-duplicate="cloneSurvey($event)"
          />
      </div>
      <div class="section-items-paginate" v-if="surveys.length > 0">
        <app-paginate :initValue="1" :prevText="'<'" :nextText="'>'" @input="changePage" :pageRange="itemPerPage" :pageCount="dynamicPageCount"></app-paginate>
      </div>
      <app-modal
        v-model="isModalOpen"
        :title="$t('start.modal_survey_title')"
        lightbox
        close
        :primary-button="$t('start.create')"
        @primaryButtonClick="newSurvey"
        @keyup.esc="this.isModalOpen = false"
        :class="{ 'disabled-btn': isCreationLoading }"
        class="modal-default-primary show-lg show-md show-sm hide-xs hide-xxs"
      >
        <app-form-input
          v-model="surveyTitle"
          :placeholder="$t('start.survey_title_placeholder')"
          secondary
          autofocus
          @keyup.enter="newSurvey"
          @keyup.esc="this.isModalOpen = false"
        />
        <div class="loading-survey-list" v-show="isCreationLoading">
          <Circle2 class="spinner-loading-survey-list"/>
        </div>
      </app-modal>
    </div>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import CardSurvey from '../components/inputs/CardSurvey.vue'
import { surveyService, sendhubService } from '@/_services'
import gsap from 'gsap'

export default {
  name: 'SurveyList',
  components: {
    Menu,
    CardSurvey,
    'app-select-binds': () => import('@binds-tech/binds-design-system/src/components/Select/SelectBinds'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-paginate': () => import('@binds-tech/binds-design-system/src/components/Paginate/Paginate'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      key: 0,
      surveys: [],
      page: 1,
      itemPerPage: 10,
      dynamicPageCount: 0,
      isListLoading: true,
      isModalOpen: false,
      filterOptions: {},
      filterOptionsData: [
        { label: this.$t('surveyList.filter_options.no_filter'), value: undefined },
        { label: this.$t('surveyList.filter_options.title_az'), value: 'title_az' },
        { label: this.$t('surveyList.filter_options.title_za'), value: 'title_za' },
        { label: this.$t('surveyList.filter_options.recent_date_send'), value: 'recent_date_sent' },
        { label: this.$t('surveyList.filter_options.old_date_send'), value: 'old_date_sent' },
        { label: this.$t('surveyList.filter_options.most_sent'), value: 'most_sent' },
        { label: this.$t('surveyList.filter_options.least_sent'), value: 'least_sent' },
        { label: this.$t('surveyList.filter_options.most_answered'), value: 'most_answered' },
        { label: this.$t('surveyList.filter_options.least_answered'), value: 'least_answered' },
        { label: this.$t('surveyList.filter_options.average_time_ascending'), value: 'average_time_ascending' },
        { label: this.$t('surveyList.filter_options.average_time_descending'), value: 'average_time_descending' },
        { label: this.$t('surveyList.filter_options.expired'), value: 'expired' },
        { label: this.$t('surveyList.filter_options.not_expired'), value: 'not_expired' },
        { label: this.$t('surveyList.filter_options.health_ascending'), value: 'health_ascending' },
        { label: this.$t('surveyList.filter_options.health_descending'), value: 'health_descending' }
      ],
      search: '',
      surveyTitle: '',
      isCreationLoading: false,
      showTemplatesModal: false,
      from: undefined
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => { vm.from = from.name })
  },
  created () {
    this.filterOptions = this.filterOptionsData[0]
  },
  mounted () {
    this.getAllSurveys()
  },
  computed: {
    labelTotal () {
      let label = this.$t('menu.surveys')
      if (this.surveys.length === 1) { label = this.$t('questionsNav.survey') }
      return label
    },
    getUser () { return this.$store.getters['account/getUser'] },
    lang () { return this.$store.getters['account/getLang'] },
    filteredSurveys () {
      const filtered = this.surveys.filter((survey) => {
        return survey.title.toLowerCase().includes(this.search.toLowerCase()) || survey._id.includes(this.search)
      })
      return filtered
    },
    currentPageItems () {
      let filterIndex = this.page
      // se a minha pagina selecionada for 50 e eu filtrar algo que tenha 2 paginas,
      // então eu pulo para a primeira página, pq o range de itens mudou, mas a pagina n mudou no componente
      // se eu apagar o q foi filtrado vai voltar para a pagina q estava
      if (this.page > this.filteredSurveys.length) {
        filterIndex = 1
      }
      return this.surveysWithBothFilterApplied.slice((filterIndex * this.itemPerPage) - this.itemPerPage, filterIndex * this.itemPerPage)
    },
    surveysWithBothFilterApplied () {
      let result = structuredClone(this.filteredSurveys)

      switch (this.filterOptions?.value) {
        case 'title_az':
          result.sort((a, b) => (a.title?.toLowerCase() > b.title?.toLowerCase() ? 1 : -1))
          break
        case 'title_za':
          result.sort((a, b) => (a.title?.toLowerCase() < b.title?.toLowerCase() ? 1 : -1))
          break
        case 'recent_date_sent':
          this.sortingByLastSend(result, 'recent_date_sent')
          break
        case 'old_date_sent':
          this.sortingByLastSend(result, 'old_date_sent')
          break
        case 'most_sent':
          result.sort((a, b) => (a.stats.totalSendings > b.stats.totalSendings ? -1 : 1))
          break
        case 'least_sent':
          result.sort((a, b) => (a.stats.totalSendings < b.stats.totalSendings ? -1 : 1))
          break
        case 'most_answered':
          result.sort((a, b) => (a.stats.totalAnswers > b.stats.totalAnswers ? -1 : 1))
          break
        case 'least_answered':
          result.sort((a, b) => (a.stats.totalAnswers < b.stats.totalAnswers ? -1 : 1))
          break
        case 'average_time_ascending':
          result.sort((a, b) => (a.stats.responseAverageTime < b.stats.responseAverageTime ? -1 : 1))
          break
        case 'average_time_descending':
          result.sort((a, b) => (a.stats.responseAverageTime > b.stats.responseAverageTime ? -1 : 1))
          break
        case 'expired':
          result.sort((a, b) => (a.isExpired > b.isExpired ? -1 : 1))
          break
        case 'not_expired':
          result.sort((a, b) => (a.isExpired < b.isExpired ? -1 : 1))
          break
        case 'health_ascending':
          this.filterHealth(result, 'health_ascending')
          break
        case 'health_descending':
          this.filterHealth(result, 'health_descending')
          break
        case undefined:
          result = {}
          result = this.filteredSurveys
          break
        default:
          break
      }

      return result
    },
    allowedProfile () { return ['superadmin', 'admin', 'editor'].includes(this.getUser.type) || this.getUser.features.surveyEdit }
  },
  methods: {
    goToTemplates () {
      this.$router.push({ name: 'Templates' })
    },
    async newSurvey () {
      if (!this.surveyTitle.length) {
        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('start.template_title_error'),
          position: 'bottomRight',
          showAlert: true
        })
      }

      this.isCreationLoading = true

      this.$store.commit('survey/startEmptySurvey', this.$store.state)

      try {
        const createdSurvey = await surveyService.createSurvey({ title: this.surveyTitle.trim(), lang: this.$i18n.vm.fallbackLocale.toLowerCase() })
        const settings = this.$store.getters['questionSettings/getDefaultSettings']

        createdSurvey.questions.map(item => {
          item.settings = settings
          item.title = item.title ? item.title : this.$i18n.t('surveyList.click_add_title')
          item.description = item.description ? item.description : this.$i18n.t('surveyList.click_add_description')
          item.icon = item.icon ? item.icon : 'blur_on'

          if (!item.stars) {
            item.stars = { active: false, numbers: false }
          }
        })

        const getTheme = createdSurvey.colors.reduce((acc, cur) => ({ ...acc, [cur.alias]: cur.value }), {})
        createdSurvey.colors = getTheme

        createdSurvey.questionSelected = 0
        this.$store.commit('survey/survey', createdSurvey)
        this.$router.push({ name: 'Surveys', params: { id: createdSurvey._id } })
      } catch (error) {
        console.error('Error when creating survey', error)

        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('start.error_creating'),
          position: 'bottomRight',
          showAlert: true
        })
      } finally {
        this.isCreationLoading = false
      }
    },
    async deleteSurvey (id, idx) {
      try {
        await surveyService.deleteSurvey(id)

        this.surveys.splice(idx, 1)

        const currentSurvey = this.$store.getters['dashboard/getCurrentSurvey']

        if (this.surveys.length === 0 || (currentSurvey && id === currentSurvey._id)) {
          this.$store.commit('dashboard/updateCurrentSurvey', undefined)
          this.$store.commit('dashboard/updateSelectedSurveyId', undefined)
        }

        this.key++

        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('surveyList.delete_success'),
          position: 'bottomRight',
          showAlert: true
        })
      } catch (error) {
        console.error('Error when deleting the survey', error)

        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('surveyList.delete_error'),
          position: 'bottomRight',
          showAlert: true
        })
      }
    },
    async cloneSurvey (payload) {
      this.setLoading()

      const { surveyId, name } = payload

      try {
        const survey = await surveyService.getSurvey(surveyId)

        if (!survey) {
          return this.$store.commit('alerts/alert', {
            message: this.$i18n.t('surveyList.survey_cloned_error'),
            position: 'bottomRight',
            showAlert: true
          })
        }

        const clone = await surveyService.cloneSurvey(surveyId, { title: name })

        if (!clone) return

        this.goToSurveyEdit(clone._id)
      } catch (error) {
        console.error('Error when cloning survey', error)

        return this.$store.commit('alerts/alert', {
          message: 'Erro ao clonar pesquisa',
          position: 'topRight',
          showAlert: true
        })
      } finally {
        this.setLoading(true)
      }
    },
    async goToSurveyEdit (surveyId) {
      if (this.getUser.type === 'user' && !this.getUser.features.surveyEdit) {
        const collector = await sendhubService.collectors()
        const isAgreedSend = collector.find(o => o.survey === surveyId)

        if (isAgreedSend && this.getUser.features.seedBatches) {
          return this.$router.push({ name: 'Surveys', params: { id: surveyId } })
        }

        return this.$router.push({ name: 'YouCant' })
      }

      return this.$router.push({ name: 'Surveys', params: { id: surveyId } })
    },
    async redirectTo (payload) {
      this.setLoading()

      const { location, surveyId } = payload

      const query = { $and: [{ survey: { $eq: surveyId } }] }

      try {
        const survey = await surveyService.getSurvey(surveyId)

        this.$store.commit('dashboard/updateCurrentSurvey', survey)
        this.$store.commit('dashboard/updateSelectedSurveyId', surveyId)
        this.$store.commit('filters/updateFilterAnd', [{ survey: { $eq: surveyId } }])
        this.$router.push({ name: location, query: { q: JSON.stringify(query) } })
      } catch (error) {
        console.log('Error accessing route', location)
        console.error('Error:', error)

        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('error_go_to'),
          position: 'bottomRight',
          showAlert: true
        })
      } finally {
        this.setLoading(true)
      }
    },
    async getAllSurveys () {
      const result = await surveyService.getAllSurveys()

      if (!result.length && this.from === 'Start') {
        this.showTemplatesModal = true
        return
      }

      this.surveys = result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      this.setLoading(true)
      this.updatePagination()
      this.$root.$emit('exceeded')
    },
    setLoading (close) {
      if (!close) { this.isListLoading = true }

      const opacity = close ? 0 : 1
      const divLoading = document.querySelector('.loading-survey-list')
      gsap.to(divLoading, { opacity, duration: 0.2, onComplete: () => { if (close) { this.isListLoading = false } } })
    },
    sortingByLastSend (surveys, orderBy) {
      surveys.sort((a, b) => {
        const getDate = obj => (obj.lastSendAt ? new Date(obj.lastSendAt) : null)

        const dateA = getDate(a)
        const dateB = getDate(b)

        return (dateA && dateB) ? (orderBy === 'recent_date_sent' ? dateB - dateA : dateA - dateB) : (dateA ? -1 : (dateB ? 1 : 0))
      })
    },
    updatePagination () {
      const listSurveys = this.filteredSurveys
      const surveysLength = (listSurveys.length / 10)
      if (Number.isInteger(surveysLength)) {
        this.dynamicPageCount = surveysLength
      } else {
        this.dynamicPageCount = Math.trunc(surveysLength) + 1
      }
      this.key++
    },
    changePage (page) {
      this.page = page
      window.scrollTo({ top: 200, behavior: 'smooth' })
      this.key++
    },
    filterHealth (list, type) {
      list.sort((a, b) => {
        if (a.stats?.health !== undefined && b.stats?.health === undefined) {
          return -1
        }
        if (a.stats?.health === undefined && b.stats?.health !== undefined) {
          return 1
        }
        if (a.stats?.health === undefined && b.stats?.health === undefined) {
          return 0
        }

        if (type === 'health_ascending') {
          return a.stats.health - b.stats.health
        } else if (type === 'health_descending') {
          return b.stats.health - a.stats.health
        }

        return 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-survey-list {
  background: #EFF3F840;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner-loading-survey-list {
    width: 2.1vw !important;
    height: 2.1vw !important;
    border-width: 3px !important;
    border-radius: 35px;
    border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
  }
}

.container-survey-list {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .content-survey-list {
    flex: 1;
    padding: 2.7vw 3.5vw 0 3.5vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    .header-survey-list {
      width: 100%;
      height: 7vw;
      .titles-survey-list {
        height: 60%;
        display: flex;
        flex-direction: column;
        gap: 0.5vw;
      }
      .options-survey-list {
        width: 100%;
        height: 40%;
        display: flex;
        .options-one-survey-list {
          display: flex;
          align-items: flex-end;
          gap: 0.5vw;
          .btn-survey-list {
            background: var(--primary-color);
            color: var(--default-text-color);
            width: 7vw;
            height: 2.1vw;
            border-radius: 0.4vw;
            font-size: 0.75vw;
            font-weight: 600;
            box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
          }
          .second-btn {
            background: transparent;
            border: 2px solid var(--primary-color);
            color: var(--primary-color);
          }
        }
        .options-two-survey-list {
          flex: 1;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          gap: 1vw;
          .input-search-survey {
            width: 17vw;
            height: 2.1vw;
            padding-left: 0.5vw;
            border-radius: 0.3vw;
            border: 1px solid #cacaca;
            box-shadow: 0 0 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
            font-size: 0.8vw;
            color: #454548;
          }
          .input-search-survey::placeholder {
            color: #808080;
          }
        }
      }
    }
    .section-survey-list {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1vw;
    }
    .section-items-paginate {
      height: 4vw;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
