<template>
  <div class="container-activity-logs">
    <Menu />
    <div class="content-activity-logs">
      <div class="no-data-to-show" v-if="noActivities && !loading">
        <NoData :message="$t('activity.no_logs')" />
      </div>
      <div class="is-loading-activity" ref="isLoading" v-if="loading">
        <Circle2 class="spinner-social"/>
      </div>
      <div class="header-activity-logs">
        <p class="title-activity-logs">{{ $t("activity.title") }}</p>
        <p class="label-activity-logs" v-if="!loadingItems && !buttonTodayActive">{{ $t('activity.displaying') }} {{ allActivities.length }} {{ $t("activity.label") }}</p>
        <p class="label-activity-logs" v-if="!loadingItems && buttonTodayActive && todayActivities.length">{{ $t('activity.displaying') }} {{ todayActivities.length }} {{ $t("activity.label") }} {{ $t('activity.of_today') }}</p>
      </div>
      <div class="search-activity-logs">
        <button class="btn-day-activity-logs" :disabled="loadingItems" :class="{'today-active': buttonTodayActive}" @click="filterPerDay()" v-title="$t('activity.filter_tooltip_today')">
          <i class="material-icons icon-btn-activity-logs">today</i>
          <p class="title-btn-day-activity-logs">{{ $t('activity.today') }}</p>
        </button>
      </div>
      <div class="section-activity-logs">
        <div class="is-loading-activity-items" v-if="loadingItems">
        <Circle2 class="spinner-social"/>
      </div>
        <div class="section-logs" v-if="!buttonTodayActive">
          <CardActivity v-for="(log, idx) in allActivities" :key="`${log._id}${idx}`" :data="log" :class="`log-${idx}`" />
        </div>
        <div class="section-logs" v-if="buttonTodayActive">
          <CardActivity v-for="(log, idx) in todayActivities" :key="`${log._id}${idx}`" :data="log" />
          <div class="no-data-to-show-day" v-if="!todayActivities.length">
            <NoData :message="$t('activity.no_logs_day')" />
          </div>
        </div>
      </div>
      <div class="load-more-activity-logs">
        <button class="btn-see-more-logs" v-if="showButtonSeeMore && !loadingItems" @click="loadMore()">
          <p class="title-btn-see-more-logs">{{ $t('dashboard.see_more') }}</p>
          <i class="material-icons icon-btn-see-more-logs">unfold_more</i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Menu from '../components/Menu.vue'
import CardActivity from '../components/activities/CardActivity.vue'
import NoData from '../components/insights/components/NoData.vue'
import gsap from 'gsap'
import { accountService } from '@/_services'
import('lodash-es').then((lodashEs) => { window._ = lodashEs.default })

export default {
  name: 'Activity',
  props: [],
  components: {
    Menu,
    NoData,
    CardActivity,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      buttonTodayActive: false,
      allActivities: [],
      todayActivities: [],
      endRange: 11,
      startRange: 0,
      loading: true,
      loadingItems: false,
      noActivities: false
    }
  },
  mounted () {
    this.getActivities()
    this.$moment.locale(this.lang)
  },
  computed: {
    lang () { return this.$store.getters['account/getLang'] },
    showButtonSeeMore () { return this.allActivities.length && !this.buttonTodayActive }
  },
  methods: {
    filterPerDay () {
      this.loadingItems = true
      this.buttonTodayActive = !this.buttonTodayActive
      this.todayActivities = this.allActivities.filter(item => {
        const createdAtItem = this.$moment(item.createdAt)
        return createdAtItem.isSame(this.$moment(), 'day')
      })
      setTimeout(() => { this.loadingItems = false }, 1000)
    },
    async getActivities () {
      const result = await accountService.getActivities(this.startRange, this.endRange)
      if (result.length) {
        this.allActivities = result
        gsap.to(this.$refs.isLoading, {
          opacity: 0,
          duration: 0.5,
          onComplete: () => {
            this.loading = false
          }
        })
      } else {
        this.noActivities = true
        this.loading = false
      }
    },
    async loadMore () {
      this.loadingItems = true
      this.startRange = this.startRange + 11
      this.endRange = this.endRange + 11
      const result = await accountService.getActivities(this.startRange, this.endRange)
      if (result.length) {
        result.forEach(log => { this.allActivities.push(log) })
      }
      this.loadingItems = false
      setTimeout(() => { this.scrollToLog() }, 100)
    },
    scrollToLog () {
      const log = document.querySelector(`.log-${this.startRange + 2}`)
      if (log) { log.scrollIntoView({ behavior: 'smooth', block: 'start' }) }
    }
  }
}
</script>
<style lang="scss" scoped>
.container-activity-logs {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
  .content-activity-logs {
    position: relative;
    width: 100vw;
    height: 92%;
    padding: 0 3.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    .is-loading-activity {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ffffff;
      display: flex;
      justify-content: center;
      padding-bottom: 8vw;
      align-items: center;
      .spinner-social {
        width: 40px !important;
        height: 40px !important;
        border-width: 4px !important;
        border-color: var(--accent-color) #cacaca #cacaca !important;
      }
    }
    .no-data-to-show {
      width: 100%;
      height: 100%;
      background: #ffffff;
    }
    .header-activity-logs {
      height: 15%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.5rem;
      border-bottom: 1px solid #cacaca;
      .title-activity-logs {
        font-family: Open Sans,Helvetica,Arial,sans-serif;
        color: #454548;
        font-size: 1.9vw;
        font-weight: bold;
      }
      .label-activity-logs {
        color: #808080;
        font-size: 0.8vw;
      }
    }
    .search-activity-logs {
      height: 5%;
      display: flex;
      align-items: center;
      gap: 1rem;
      .btn-day-activity-logs {
        background-color: transparent;
        border: none;
        color: #bababa;
        padding: 0.2rem 0.7rem;
        border: 1px solid #bababa;
        border-radius: 35px;
        box-shadow: 0px 1px 5px #cacaca;
        transition: background-color 0.4s, box-shadow 0.3s;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .icon-btn-activity-logs {
          font-size: 1.1vw;
        }
        .title-btn-day-activity-logs {
          font-size: 0.75vw;
        }
      }
      .yesterday-active, .today-active {
        background: var(--accent-color);
        opacity: 0.6;
        box-shadow: 0 1px 2px #cacaca;
        color: var(--default-text-color);
        font-weight: 500;
      }
    }
    .section-activity-logs {
      position: relative;
      .is-loading-activity-items {
        background: #ffffff;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .spinner-social {
          width: 40px !important;
          height: 40px !important;
          border-width: 4px !important;
          border-color: var(--accent-color) #cacaca #cacaca !important;
        }
      }
      .section-logs {
        height: 30.1vw;
        background: #ffffff;
        box-shadow: 0px 1px 5px #f1f1f1;
        border: 1px solid #e1e1e1;
        border-radius: 0.2rem;
        overflow-y: auto;
        .no-data-to-show-day {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .load-more-activity-logs {
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-see-more-logs {
        background-color: var(--accent-color);
        opacity: 0.8;
        padding: 0.2rem 0.7rem;
        border: 1px solid #bababa;
        border-radius: 35px;
        box-shadow: 0px 1px 5px #cacaca;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .title-btn-see-more-logs {
          color: var(--default-text-color);
          font-weight: bold;
        }
        .icon-btn-see-more-logs {
          color: var(--default-text-color);
          font-size: 1.2vw;
        }
      }
      .btn-see-more-logs:hover {
        box-shadow: 0px 1px 5px var(--accent-color);
        border-color: var(--accent-color);
      }
    }
  }
}
</style>
