<template>
  <div style="width: 100%">
    <!-- Menu de filtros, data e survey Mobile -->
    <div class="isToShowMobile" v-if="mobile && canShowThePage" style="width: 100%">
      <div class="row" style="display: flex;" :class="{'show-inputs-mobile': !openMenu, 'hide-inputs-mobile': openMenu}">
        <filter-date-field
          v-if="!approaches"
          :key="dateFilterKey"
          :dateFrom="filter.query.respondedAt.$gte"
          :dateTo="filter.query.respondedAt.$lte"
          @onFocus="onFocus"
          class="col-xs-12"
        ></filter-date-field>
        <filter-date-field
          v-if="approaches"
          :key="dateFilterKey"
          :dateFrom="filter.query.createdAt.$gte"
          :dateTo="filter.query.createdAt.$lte"
          @onFocus="onFocus"
          class="col-xs-12"
        ></filter-date-field>
        <div class="submenu_filters submenu_select col-xs-10" v-if="!ranking">
          <v-select
            class="survey-selector"
            label="title"
            :options="surveys"
            :clearable="false"
            v-model="selectedSurvey"
            :filterBy="customFilter"
            @input="saveSelectedSurvey()"
          ></v-select>
        </div>
        <div class="col-xs-2 cursor" style="margin: auto;" v-on:click="filterIsOpen = !filterIsOpen" v-if="!ranking">
          <i class="material-icons mobileFilter">filter_alt</i>
        </div>
        <filter-modal v-if="filterIsOpen && !ranking" @close="filterIsOpen = false" :entity="entity"></filter-modal>
        <div class="row" v-if="ranking">
          <v-select
            class="survey-selector submenu_filters submenu_select col-xs-12"
            label="title"
            :options="surveys"
            :clearable="false"
            v-model="selectedSurvey"
            :filterBy="customFilter"
            @input="saveSelectedSurvey()"
          ></v-select>
          <v-select
            class="col-xs-12 survey-selector"
            label="key"
            v-title="$t('ranking.custom_field')"
            :options="groupableFields"
            :clearable="false"
            v-model="selectedGroupableField"
            @input="updateRanking()"
          ></v-select>
          <v-select
            class="col-xs-9 survey-selector"
            label="labelToShowOnSelect"
            v-title="$t('ranking.question')"
            :options="elegibleQuestions"
            :clearable="false"
            v-model="selectedRankingQuestion"
            @input="updateRanking()"
          ></v-select>
          <app-form-input
            v-title="$t('ranking.min_sample')"
            class="col-xs-3"
            style="margin-top: -14px;"
            v-model="minSample"
            border-bottom
            type="number"
            @change="updateRanking()"
          ></app-form-input>
        </div>
      </div>
    </div>
    <!-- Menu de filtros, data e survey Desktop -->
    <div class="notToShowMobile" v-if="canShowThePage">
      <!-- Para ranking -->
      <div class="" v-if="ranking && !approaches && !mobile" style="display: flex; width: 100%; margin: 1rem 4.2%;">
        <filter-date-field
          :key="dateFilterKey"
          :dateFrom="filter.query.respondedAt.$gte"
          :dateTo="filter.query.respondedAt.$lte"
          @onFocus="onFocus"
          class="col-sm-4 col-md-4 col-lg-4 survey-selector"
          style="position: relative;"
        ></filter-date-field>
        <div class="col-sm-10 col-md-10 col-lg-10">
          <div class="submenu_select row">
            <v-select
              class="survey-selector"
              label="title"
              v-title="$t('questionsNav.survey')"
              :options="surveys"
              :clearable="false"
              v-model="selectedSurvey"
              :filterBy="customFilter"
              style="padding-top: 16px;width: 40%;margin-right: 33px;"
              @input="saveSelectedSurvey()"
            >
              <template #option="{ title, isExpired }">
                <p :class="{'option-expired': isExpired}">
                  {{ title }}
                  <i v-if="isExpired" class="material-icons expired-icon-select" v-title="$t('surveyList.survey_expired')">timer_off</i>
                </p>
              </template>
            </v-select>
            <v-select
              class="survey-selector"
              style="padding-top: 16px;width: 46%;"
              label="labelToShowOnSelect"
              v-title="$t('ranking.question')"
              :options="elegibleQuestions"
              :clearable="false"
              :filterBy="customFilter"
              v-model="selectedRankingQuestion"
              @input="updateRanking()"
            ></v-select>
            <div class="col-md-1 col-lg-1 col-sm-1 cursor mt3neg options-filter" style="display: flex;">
              <div v-on:click="filterIsOpen = !filterIsOpen">
                <span class="count-filter" v-if="countedItem > 0">{{countedItem}}</span>
                <i class="material-icons mobileFilter" v-title="$t('searchMenu.advanced_filters')">filter_alt</i>
              </div>
              <div class="fix-export" v-if="!hideExports">
                <app-button
                  inline
                  icon="download"
                  icon-color="gray"
                  icon-size="sm"
                  :class="{ isExportable: !rankingCanExport, colored: true }"
                  v-title="$t('searchMenu.export_data')"
                  v-on:click="rankingCanExport ? handleExportClick() : showMessageDenyExport()"
                >
                </app-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Para approaches -->
      <div class="" v-if="approaches && !ranking && !mobile" style="display: flex; width: 100%; margin: 0;">
        <filter-date-field
          :key="dateFilterKey"
          :dateFrom="filter.query.createdAt.$gte"
          :dateTo="filter.query.createdAt.$lte"
          @onFocus="onFocus"
          class="col-sm-10 col-md-10 col-lg-10 survey-selector"
          style="position: relative;"
        ></filter-date-field>
        <div class="col-sm-12 col-md-12 col-lg-12 apprSelect">
          <v-select
            class="survey-selector"
            label="title"
            :options="approachesTypes"
            v-model="selectedApproachType"
            :searchable="false"
            :clearable="false"
            @input="selectApproachType()"
          >
        </v-select>
        </div>
        <div class="col-md-2 col-lg-2 col-sm-2 options-filter" style="margin-top: 7px; display: flex" >
          <div v-on:click="filterIsOpen = !filterIsOpen">
            <span class="count-filter" v-if="countedItem > 0">{{countedItem}}</span>
            <i class="material-icons mobileFilter" v-title="$t('searchMenu.advanced_filters')">filter_alt</i>
          </div>
          <div class="fix-export" v-if="!hideExports">
            <app-button
              inline
              icon="download"
              icon-color="gray"
              icon-size="sm"
              class="colored"
              v-title="$t('searchMenu.export_data')"
              v-if="permisionExportApproaches()"
              v-on:click="openSidebarExportSending = !openSidebarExportSending; pageToExport = 'sendings'"
            >
            </app-button>
          </div>
        </div>
      </div>
      <!-- Para o dashboard -->
      <div
        class=""
        v-if="!approaches && !ranking && !mobile"
        style="display: flex; width: 100%;"
        :style="
          [
            (individual || referrals) ? { 'margin': '1rem 2.6%' } :
            !((individual && ranking && referrals) || $route.name === 'Sentimentals') ? { 'margin': '1rem 3.3%' } : { 'margin': '1rem 1.6%' }
          ]
      ">
        <filter-date-field
        :dateFrom="filter.query.respondedAt.$gte"
        :dateTo="filter.query.respondedAt.$lte"
        :key="dateFilterKey"
        @onFocus="handleUserClickToChangeData();onFocus(...arguments);"
        class="col-sm-6 col-md-6 col-lg-6 survey-selector" style="position: relative;"
        :style="[cloudwords ? {'max-width': '27.5%'} : {'min-width': '29%'}]"
        ></filter-date-field>
        <div class="col-sm-8 col-md-8 col-lg-8">
          <div class="submenu_select row">
            <v-select
              class="col-lg-10 col-md-10 col-sm-10 col-xs-10 survey-selector"
              label="title"
              :options="surveys"
              :clearable="false"
              v-model="selectedSurvey"
              :filterBy="customFilter"
              @input="handleUserClickToChangeData();saveSelectedSurvey();"
              :style="[cloudwords ? {'max-width': '50%'} : {'min-width': '91%'}]"
            >
              <template #option="{ title, isExpired }">
                <p :class="{'option-expired': isExpired}">
                  {{ title }}
                  <i v-if="isExpired" class="material-icons expired-icon-select" v-title="$t('surveyList.survey_expired')">timer_off</i>
                </p>
              </template>
            </v-select>
            <!-- Select Questions (Analytics) -->
            <v-select
              class="col-lg-10 col-md-10 col-sm-10 col-xs-10 survey-selector question-selector"
              label="question"
              :options="questions"
              v-model="selectedQuestion"
              :clearable="false"
              @input="saveSelectedQuestion()"
              v-if="cloudwords"
              style="max-width: 50%"
            ></v-select>
            <!-- END Select Questions (Analytics) -->
            <div class="col-md-1 col-lg-1 col-sm-1 cursor mt3neg options-filter" style="display: flex;">
              <div v-if="!cloudwords" v-on:click="filterIsOpen = !filterIsOpen">
                <span class="count-filter" v-if="countedItem > 0">{{countedItem}}</span>
                <i class="material-icons mobileFilter">filter_alt</i>
              </div>
              <div>
                <i v-if="referrals && !hideExports" class="material-icons mobileFilter" v-on:click="openSidebarExportSending = !openSidebarExportSending; pageToExport = 'referrals'">cloud_download</i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- sidebar da exportação de respostas -->
      <export-sending
        v-if="openSidebarExportSending"
        :pageToExport="pageToExport"
        :rankingResults="rankingResults"
        @close-export-sidebar="openSidebarExportSending = false"
      ></export-sending>
      <!-- FilterModal -->
      <filter-modal v-if="filterIsOpen && !mobile" @close="filterIsOpen = false" :entity="entity"></filter-modal>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { surveyService } from '@/_services'
import FilterModal from '../../filters/FilterModalOld.vue'
import FilterDateField from '../../filters/FilterDateField.vue'
import ExportSending from '../../SideBarConfig/ExportSending.vue'
import { constants } from '@/_helpers'
import('lodash-es').then((lodashEs) => { window._ = lodashEs.default })

export default {
  name: 'SearchMenu',
  props: ['approaches', 'ranking', 'mobile', 'referrals', 'individual', 'sentimentals', 'cloudwords', 'insights'],
  surveyId: '',
  components: {
    vSelect: () => import('vue-select'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    FilterModal,
    FilterDateField,
    ExportSending
  },
  data () {
    return {
      surveys: [],
      questions: [],
      canShowThePage: false,
      openMenu: false,
      filterIsOpen: false,
      pageToExport: '',
      openSidebarExportSending: false,
      filterModalKey: 0,
      dateFilterKey: 0,
      selectedQuestion: '',
      selectedSurvey: this.$i18n.t('survey.choose_survey'),
      selectedApproachType: '',
      entity: '',
      groupableFields: [],
      elegibleQuestions: [],
      minSample: 0,
      selectedGroupableField: '',
      selectedRankingQuestion: '',
      rankingCanExport: false,
      rankingResults: []
    }
  },
  beforeDestroy () {
    this.$root.$off('filter-ranking')
    this.$root.$off('ranking-data')
    this.$root.$off('loadFilterAgain')
  },
  created () {
    this.entity = this.approaches ? 'sendings' : this.ranking ? 'ranking' : this.cloudwords ? 'cloudwords' : this.sentimentals ? 'sentimentals' : 'sending-responses'
    const dateMetric = this.entity === 'sendings' ? 'createdAt' : 'respondedAt'
    this.$store.commit('filters/updateEntity', this.entity === 'sendings' ? 'approaches' : this.entity === 'ranking' ? 'ranking' : this.referrals ? 'referrals' : this.individual ? 'individual' : 'dashboard')
    this.$store.commit('filters/updateDateMetric', dateMetric)
    this.$store.commit('providers/UPDATE_PROVIDER', { key: 'sendingIdOnQuery', value: this.$route.query.sending })
  },
  async mounted () {
    this.$root.$on('ranking-can-export', (value) => {
      this.rankingCanExport = value
    })

    this.$root.$on('ranking-export-content', async (data) => {
      this.rankingResults = await data
    })

    this.$root.$on('loadFilterAgain', () => {
      this.selectedSurvey = {}
      const hasSurveyOnQuery = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, 'survey')
      if (hasSurveyOnQuery && this.entity !== 'sendings') {
        const survey = window._.find(this.surveys, { _id: hasSurveyOnQuery.survey.$eq })
        if (survey) {
          this.selectedSurvey = survey
          this.saveSelectedSurvey()
        }
      }
      if (this.entity === 'sendings') {
        this.updateUrl()
        this.dateFilterKey++
        this.$root.$emit('load-favorite-filter-for-approaches')
      }
    })

    this.$root.$on('loadingNewDataSpinner', (isToLoad) => {
      this.isLoading = isToLoad
    })

    this.$root.$on('close-export-sidebar', () => {
      this.openSidebarExportSending = false
    })

    this.$root.$on('ranking-data', (payload) => {
      if (payload.where === 'meta') {
        this.selectedGroupableField = payload.item
      }

      if (payload.where === 'count') {
        this.minSample = payload.item
      }
      this.updateRanking()
    })

    // With this, we can know what page is using the modal filter and load their fields correctly
    this.isLoading = true
    this.$store.commit('filters/resetOptionsToReload')

    if (this.entity === 'sendings') {
      const found = this.$store.getters['filters/getDashFilter'].query.$and.find(item => item.archivedAt)
      if (found && found.archivedAt) {
        const exists = found?.archivedAt?.$exists === true
        this.$store.commit('approaches/updateSelectedType', 'active')
        if (exists) {
          this.$store.commit('approaches/updateSelectedType', 'archived')
        }
      }
      this.selectedApproachType = this.$store.getters['approaches/getSelectedType']
      this.$store.commit('filters/deleteSpecificKey', 'archivedAt')
      if (this.selectedApproachType.toQuery) {
        this.$store.commit('filters/createOrUpdateSpecificKey', this.selectedApproachType.toQuery)
      }
    }

    const dateMetric = this.$store.state.filters.currentDateMetric
    let needToUpdateCardsAgain = false

    this.surveys = await surveyService.getAllSurveys()
    if (this.surveys.length > 0) { // se n tiver pesquisas na conta, forçamos ele para a pagina de criação da primeira pesquisa
      this.surveys.map(element => {
        this.$store.commit('filters/insertSurveyField', element)
      })
    } else {
      return this.$router.push({ name: 'Start' })
    }

    if (this.$route.query.q) {
      let parseQuery = this.$route.query.q
      if (typeof parseQuery !== 'object') {
        parseQuery = JSON.parse(this.$route.query.q)
      }

      this.$store.commit('filters/updateFilterAnd', parseQuery.$and)

      let hasSurveyOnQuery = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, 'survey')
      let gteQuery = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$gte`)
      let lteQuery = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$lte`)

      const currentQuery = this.getCurrentQuery()

      gteQuery = gteQuery ? gteQuery[dateMetric].$gte : currentQuery.$gte
      lteQuery = lteQuery ? lteQuery[dateMetric].$lte : currentQuery.$lte

      if (!hasSurveyOnQuery && this.entity !== 'sendings') {
        this.$store.commit('filters/resetAllFilters')
        const hasCurrentSurvey = this.currentSurvey
        if ((!hasCurrentSurvey || !hasCurrentSurvey._id) && this.surveys.length > 0) {
          this.selectedSurvey = this.surveys[0]
        } else {
          this.selectedSurvey = hasCurrentSurvey
        }
        const surveyIdToLoad = this.currentSurvey ? this.currentSurvey._id : this.selectedSurvey._id
        this.$store.commit('filters/createOrUpdateSpecificKey', { survey: { $eq: surveyIdToLoad } })
        this.$store.commit('dashboard/updateCurrentSurveyStats', this.selectedSurvey)
      }

      this.$store.commit('filters/updateCreatedAtFrom', gteQuery)
      this.$store.commit('filters/updateCreatedAtTo', lteQuery)

      // checkagain
      hasSurveyOnQuery = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, 'survey')
      // força o filtro de data carregar novamente e pegar os dados da conta para inserir valor neles caso n tenha
      this.dateFilterKey++

      if (hasSurveyOnQuery && this.entity !== 'sendings' && this.entity !== 'sending-responses') {
        const querySurveyId = hasSurveyOnQuery.survey.$eq
        const getterSurvey = this.currentSurvey
        if (getterSurvey && getterSurvey._id !== querySurveyId) {
          needToUpdateCardsAgain = true
        }
        const surveyWithQuestions = querySurveyId ? await surveyService.getSurvey(querySurveyId) : undefined
        this.$store.commit('dashboard/updateCurrentSurvey', surveyWithQuestions)
        this.$store.commit('dashboard/updateSelectedSurveyId', querySurveyId)
        if (this.entity === 'cloudwords') {
          this.onFocus() // quando entra na tela por reload do navegador ou copiando link, pode duplicar as primeiras requisições dos gráficos
          // encontrar uma forma melhor de carregar os gráficos nesse cenário, talvez validando a "previous route"
        }
        this.selectedSurvey = surveyWithQuestions
      } else if (this.entity === 'sending-responses') {
        const querySurveyId = hasSurveyOnQuery?.survey?.$eq // se existe survey na query devemos considerar ela como prioridade, em casos de reload da página ou até mesmo alguém compartilhando link com outra pessoa
        const surveyIdToLoad = querySurveyId || (this.currentSurvey ? this.currentSurvey._id : this.selectedSurvey._id)
        this.$store.commit('dashboard/updateCurrentSurvey', await surveyService.getSurvey(surveyIdToLoad))
        this.$store.commit('dashboard/updateSelectedSurveyId', surveyIdToLoad)
        this.$store.commit('filters/createOrUpdateSpecificKey', { survey: { $eq: surveyIdToLoad } })
        this.onFocus()
      }
    }

    const current = this.currentSurvey
    if (current !== null) {
      const statsIndex = window._.findIndex(this.surveys, { _id: current?._id })
      if (statsIndex >= 0) {
        // insere os stats da survey no state
        this.$store.commit('dashboard/updateCurrentSurveyStats', this.surveys[statsIndex])
      }
      this.selectedSurvey = current
    }
    if (this.entity === 'ranking' || this.entity === 'sentimentals' || this.entity === 'sending-responses' || this.entity === 'cloudwords') {
      if (!current) {
        this.selectedSurvey = this.surveys[0]
        await this.saveSelectedSurvey()
      }
      const fields = this.account.fields
      fields.map(item => {
        if (item.groupable) {
          this.groupableFields.push(item)
        }
      })

      this.selectedGroupableField = this.groupableFields[0]

      this.updateCurrentQuestionElegibleForRanking()
      if (this.entity === 'ranking') {
        this.$root.$emit('filter-ranking', { question: this.selectedRankingQuestion, isToUpdateView: false })
      }
    }
    this.updateUrl()
    this.isLoading = false

    // quando o usuário acessa a rota do dash com uma survey filtrada na url
    // devemos ignorar se ela é a mesma da que já existe no state
    // se for diferente atualizamos para fazer as reqs outra vez
    // caso contrário n precisa pq quando entra na tela já faz uma req
    if (needToUpdateCardsAgain && ['dashboard', 'referrals', 'individual'].indexOf(this.entity) > -1) {
      this.$root.$emit('filter-updated')
    }
    if (this.entity !== 'ranking') {
      this.listQuestions(this.selectedSurvey._id)
    }
    this.$nextTick(function () {
      this.canShowThePage = true
      this.$root.$emit('search-menu-is-done') // emito isso para falar aos demais componentes que o filtro está pronto
      // garantindo assim que a página carregue com a pesquisa que está na url caso a pessoa acesse via link
    })
  },
  computed: {
    lang () { return this.$store.getters['account/getLang'] },
    filter () { return this.$store.getters['filters/getQueryFromDash'] },
    approachesTypes: {
      get () { return this.$store.getters['approaches/getTypes'] }
    },
    ...mapGetters({
      currentSurvey: 'dashboard/getCurrentSurvey',
      account: 'account/getAccount',
      user: 'account/getUser'
    }),
    countedItem () { return this.$store.getters['filters/getCountedFilter'] },
    hideExports () { return this.user.features.hideExports }
  },
  methods: {
    customFilter (option, _label, search) {
      const sanitize = (value) => value.toString().toLowerCase().trim()
      const id = option._id
      const title = option.title
      const toSearch = [id, title]

      return (sanitize(toSearch).includes(sanitize(search)))
    },
    getCurrentQuery () {
      let date = new Date()
      const currentLocale = this.$moment.locale()

      switch (this.account.dashboardDefaultPeriod) {
        case 'day': date = this.$moment().locale(currentLocale).format('DD/MM/YYYY')
          break
        case 'week': date = this.$moment().subtract(1, 'weeks').format('DD/MM/YYYY')
          break
        case 'month': date = this.$moment().subtract(1, 'months').format('DD/MM/YYYY')
          break
        case 'semester': date = this.$moment().subtract(6, 'months').format('DD/MM/YYYY')
          break
        case 'year': date = this.$moment().subtract(1, 'years').format('DD/MM/YYYY')
          break
        default: date = this.$moment().locale(currentLocale).format('DD/MM/YYYY')
          break
      }

      date = this.$moment(date, 'DD/MM/YYYY').utc().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      const dateTo = this.$moment(new Date(), 'DD/MM/YYYY').utc().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

      return { $gte: date, $lte: dateTo }
    },
    /**
     * Atualiza a pergunta default atual na tela de ranking
     */
    updateCurrentQuestionElegibleForRanking () {
      this.elegibleQuestions = []

      if (!this.currentSurvey) { return false }

      const updateLabel = (value, idx) => { value.labelToShowOnSelect = `${idx + 1} - ${value.labelToShowOnSelect}` }

      this.currentSurvey.questions.map(question => {
        if (['nps', 'csat', 'nes', 'ces', 'enum', 'kpi', 'nvs'].indexOf(question.type) > -1) {
          question.labelToShowOnSelect = question.label || question.question
          this.elegibleQuestions.push(question)
        }
      })

      this.elegibleQuestions.forEach((item, idx) => updateLabel(item, idx))

      this.elegibleQuestions.unshift({
        labelToShowOnSelect: this.$t('health_score.title_card_score'),
        type: constants.ranking.TYPES.HEALTH,
        ui: constants.ranking.TYPES.HEALTH,
        _id: 'health'
      })

      if (this.elegibleQuestions.length > 0) {
        let questionFinded = this.elegibleQuestions[0]
        if (this.$route.query.rankBy) {
          const finded = this.elegibleQuestions.filter(question => question._id === this.$route.query.rankBy)
          if (finded.length > 0) {
            questionFinded = finded[0]
          }
        }
        this.selectedRankingQuestion = questionFinded
      }
    },
    async listQuestions (surveyId) {
      let survey = this.currentSurvey
      if (!survey && surveyId) {
        survey = await surveyService.getSurvey(surveyId)
      }
      if (survey) {
        this.questions = survey.questions.filter((item) => {
          // return question types that works in cloud words (nps,csat,kpi,nes)
          return item.type === 'nps' || item.type === 'csat' || item.type === 'kpi' || item.type === 'nes'
        })
      }
      this.selectedQuestion = this.questions[0]
    },
    saveSelectedQuestion () {
      this.$root.$emit('question-analytics', this.selectedQuestion._id)
    },
    permisionExportApproaches () {
      if (this.user.type !== 'user') {
        return true
      } else {
        if (this.user.features.sendings) {
          return true
        } else {
          return false
        }
      }
    },
    handleExportClick () {
      this.openSidebarExportSending = !this.openSidebarExportSending
      this.pageToExport = 'ranking'
    },
    showMessageDenyExport () {
      this.$store.commit('alerts/alert', {
        message: this.$i18n.t('ranking.cannotExport'),
        position: 'bottomRight',
        showAlert: true
      })
    },
    /**
     * this functions is useful to do some actions when the user change the survey/date
     */
    handleUserClickToChangeData () {
      this.$store.commit('providers/RESET_PROVIDER', 'sendingIdOnQuery')
    },
    updateRanking () {
      this.updateUrl()
      this.$root.$emit('filter-ranking', { question: this.selectedRankingQuestion, isToUpdateView: true })
    },
    selectApproachType () {
      this.$store.commit('approaches/updateSelectedType', this.selectedApproachType.type)
      this.$store.commit('filters/deleteSpecificKey', 'archivedAt')
      if (this.selectedApproachType.toQuery) {
        this.$store.commit('filters/createOrUpdateSpecificKey', this.selectedApproachType.toQuery)
      }
      this.$root.$emit('filter-approach-type')
      this.updateUrl()
    },
    async saveSelectedSurvey () {
      this.isLoading = true
      const dateMetric = this.$store.state.filters.currentDateMetric
      const surveyId = this.selectedSurvey ? this.selectedSurvey._id : ''

      if (!surveyId) {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('survey.survey_not_found'),
          position: 'bottomRight',
          showAlert: true
        })
        this.$store.commit('dashboard/updateCurrentSurvey', false)
        this.$store.commit('dashboard/updateSelectedSurveyId', null)
        this.$store.commit('filters/updateSpecificKey', { survey: {} })
        this.isLoading = false
        return
      }
      const surveyWithQuestions = await surveyService.getSurvey(surveyId)
      if (surveyWithQuestions === undefined) {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('survey.questions_error'),
          position: 'bottomRight',
          showAlert: true
        })
        this.isLoading = false
        return
      }

      const statsIndex = window._.findIndex(this.surveys, { _id: surveyId })
      this.$store.commit('dashboard/updateCurrentSurveyStats', this.surveys[statsIndex])
      this.$store.commit('dashboard/updateCurrentSurvey', surveyWithQuestions)
      this.$store.commit('dashboard/updateSelectedSurveyId', surveyId)

      const hasGteDateOnQuery = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$gte`)
      const hasLteDateOnQuery = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$lte`)
      this.$store.commit('filters/createOrUpdateSpecificKey', { survey: { $eq: surveyId } })

      const parsedQuery = JSON.parse(this.$route.query.q)
      const idOnQuery = parsedQuery ? parsedQuery?.$and?.find(obj => obj.survey)?.survey?.$eq : ''
      const hasResponsesFilter = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, 'responses')
      if (hasResponsesFilter && (surveyId !== idOnQuery)) {
        this.$store.commit('filters/deleteSpecificKey', 'responses')
        this.$store.commit('filters/resetOptionsToReload')
      }

      if (hasGteDateOnQuery) {
        this.$store.commit('filters/createOrUpdateSpecificKey', hasGteDateOnQuery)
      }

      if (hasLteDateOnQuery) {
        this.$store.commit('filters/createOrUpdateSpecificKey', hasLteDateOnQuery)
      }

      if (this.ranking) {
        this.updateCurrentQuestionElegibleForRanking()
      }

      // está assim pq o SaveSelectedSurvey já chama um outro upadate na tela do ranking
      this.$root.$emit('filter-ranking', { question: this.selectedRankingQuestion, isToUpdateView: false })

      this.onFocus()
      this.isLoading = false
      if (this.entity === 'sending-responses') {
        this.$root.$emit('check-and-update-custom-dashboard')
      }

      // get questions
      this.listQuestions(surveyId)
    },
    openMobileMenu () {
      this.openMenu = !this.openMenu
    },
    /**
     * Updates current filter and emit an event to notice the page to re-render the components
     * Atualiza a data utilizada no filtro, cria ou remove dependendo do valor se for empty
     * fromNativeEventOfInputDate garante que estamos atualizando o campo por uma ação manual e não por um reload da página
     * sendo assim podemos enviar o valor escolhido e não o que está no state do storage
     */
    onFocus (from, to, fromNativeEventOfInputDate, isDay) {
      const dateMetric = this.$store.state.filters.currentDateMetric
      const objectFilter = {
        query: {
          [dateMetric]: {
            $gte: fromNativeEventOfInputDate ? from : this.filter.query[dateMetric].$gte,
            $lte: fromNativeEventOfInputDate ? to : this.filter.query[dateMetric].$lte
          }
        }
      }
      const currentLocale = this.$moment.locale()
      // 2020-08-26T03:00:00.000Z
      if (objectFilter.query[dateMetric].$gte) {
        objectFilter.query[dateMetric].$gte = this.$moment(objectFilter.query[dateMetric].$gte, 'DD/MM/YYYY').locale(currentLocale).utc().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      }

      if (objectFilter.query[dateMetric].$lte) {
        objectFilter.query[dateMetric].$lte = this.$moment(objectFilter.query[dateMetric].$lte, 'DD/MM/YYYY').locale(currentLocale).utc().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      }

      if (isDay) {
        objectFilter.query[dateMetric].$lte = this.$moment().locale(currentLocale).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        objectFilter.query[dateMetric].$gte = this.$moment(objectFilter.query[dateMetric].$lte).subtract(21, 'hours').locale(currentLocale).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      }

      this.$store.commit('filters/createOrUpdateSpecificKey', { [dateMetric]: { $lte: objectFilter.query[dateMetric].$lte } })
      this.$store.commit('filters/createOrUpdateSpecificKey', { [dateMetric]: { $gte: objectFilter.query[dateMetric].$gte } })
      this.$store.commit('filters/updateDashFilter', objectFilter)

      if (this.ranking) {
        this.updateCurrentQuestionElegibleForRanking()
      }

      const countFrom = this.filter.query[dateMetric].$gte.length
      const countTo = this.filter.query[dateMetric].$lte.length

      this.updateUrl()

      // Ensures that it won't filter with wrong date or when the user are cleanning up the input
      if ((countFrom > 0 && countFrom < 10) || (countTo > 0 && countTo < 10)) {
        return false
      }

      // isso garante que todas as telas atualizem quando a data mudar no filtro
      this.$root.$emit('filter-updated')
    },
    updateUrl () {
      this.$route.query.q = { $and: this.$store.getters['filters/getDashFilter'].query.$and }
      if (!this.$route.query.cleanEmptyQuestions) {
      // defaukt value to get only question with value
        this.$route.query.cleanEmptyQuestions = true
      }
      const objectToLoad = { q: JSON.stringify(this.$route.query.q), cleanEmptyQuestions: this.$route.query.cleanEmptyQuestions }

      if (this.ranking) {
        objectToLoad.groupBy = (this.selectedGroupableField) ? 'metadata.' + this.selectedGroupableField.key : ''
        objectToLoad.minSample = this.minSample
        if (this.selectedRankingQuestion.type === constants.ranking.TYPES.HEALTH) {
          objectToLoad.rankBy = constants.ranking.TYPES.HEALTH
        } else {
          objectToLoad.question = this.selectedRankingQuestion._id
          objectToLoad.rankBy = constants.ranking.TYPES.QUESTION
        }
      }

      this.$router.push({ path: this.$route.path, query: objectToLoad })
    }
  }
}
</script>

<style lang="scss">
.option-expired {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #efefef;
}

.option-expired:hover {
  background: #5897fb;
  color: #ffffff;
}

.isExportable {
  cursor: not-allowed !important;
}
.fix-export {
  div.button-icon {
    display: flex !important;
  }
  div:empty {
    display: none !important;
  }
  i.b-icon.download {
    font-size: 20px !important;
    padding: 0 !important;
  }
}
.question-selector {
  .vs__selected-options {
    width: 90%;
  }
  span {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    margin-top: 12px !important;
    width: 100%;
  }
}
.vs__dropdown-menu {
  min-width: fit-content !important;
  max-width: 100% !important;
  top: calc(100% - 15px) !important;
}
.data-loading {
  z-index: 99;
}
.mobileFilter {
  padding: 5px;
  cursor: pointer;
}
.submenu_filters >
.datepicker >
.datepicker__input >
.text-field >
.text-field__input >
input,
.mobileFilter,
.vs__selected {
  color: #A2A2A2 !important;
}
.firstBadgeDate > div > i , .secondBadgeDate > div > i {
  color:  #A2A2A2 !important;
}
.survey-selector {
  margin-top: -15px !important;
  .vs__open-indicator {
    .vs__selected-options {
      padding-top: 8px !important;
    }
  }
  .vs__dropdown-toggle {
    background: #fcfcfc !important;
    border: 1px solid transparent !important;
    border-radius: 4px !important;
    white-space: normal !important;
    overflow: hidden;
    height: 50px !important;
    box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.03),0 5px 4px 0 rgba(0,0,0,.01);
  }
  .vs__dropdown-option {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: normal !important;
    width: 100% !important;
    padding: 0;
    min-height: 1.6vw;
    display: flex;
    align-items: center;
    p {
      padding: 0 0.5rem;
    }
  }
}
.isToShowMobile {
  display: none !important;
}
.graph-icon {
  background-image: url("../../../assets/img/graph-icons.png") !important;
}
.notToShowMobile {
  display: contents;
}

@media (min-width: 1045px)
{
  .notToShowMobile {
    display: flex;
    width: 100%;
  }
  .dashboardSubmenu > div:nth-child(3) {
    width: auto !important;
  }
}
.firstBadgeDate {
  background-color: #fcfcfc;
  margin-top: -49px;
  margin-left: 97%;
  border-radius: 0px 10px 10px 0px;
  width: 30px;
  position: relative;
  height: 48px;
  // box-shadow: 5px 2px 3px 0 rgba(48, 48, 48, 0.03),0 5px 0px 0 rgba(0,0,0,.01);
}
.firstBadgeDate > div {
  margin-top: 12px;
  margin-left: 4px;
  position: absolute;
}
.secondBadgeDate {
  background-color: #fcfcfc;
  margin-top: -49px;
  margin-left: 96%;
  border-radius: 0px 10px 10px 0px;
  width: 30px;
  position: relative;
  height: 48px;
  // box-shadow: 5px 2px 3px 0 rgba(48, 48, 48, 0.03),0 5px 0px 0 rgba(0,0,0,.01);
}
.secondBadgeDate > div {
  margin-top: 12px;
  margin-left: 4px;
  position: absolute;
}
.firstTitle {
  margin-top: -15px;
}
.submenu_select > .input-wrapper > .dropdown {
  height: 49px !important;
  padding: 5px 16px !important;
  font-size: 13px !important;
}
.submenu_filters_data {
  width: 100% !important;
}
.submenu_filters > .dropdown-menu > .dropdown-menu__label span {
  color: $color-gray-light;
}
.submenu_filters > .dropdown-menu > .dropdown-menu__label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 80%;
  color: $color-gray-light;
}

.submenu_filters > h5 > span {
  font-size: 30px;
}
.submenu_filters
  > .datepicker
  > .datepicker__input
  > .text-field
  > .text-field__input
  input {
  text-align: center;
  padding: 15px 0;
  font-size: 12px !important;
  box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.03),0 5px 4px 0 rgba(0,0,0,.01);
}
.submenu_filters {
  text-align: -webkit-center;
}

.submenu_filters .datepicker {
  width: 100%;
  margin-right: 15px;
}
.submenu_filters .datepicker__input .text-field__input {
  border-radius: 5px;
  border: 1px solid transparent;
}
.submenu_filters .input-wrapper:after {
  border-color:  #b986fd !important;
}
@media only screen and (min-width: 91rem) {
  .col-lg-offset-1 {
    margin-left: 5% !important;
  }
}
@media (max-width: 648px) {
  .isToShowMobile {
    display: flex !important;
  }
  .notToShowMobile {
    display: none;
  }
  .datepicker__picker {
    right: 20px !important;
  }
  .submenu_filters .input-wrapper > .dropdown {
    border: 1px solid
    #fff !important;
    background-color:rgba(255, 255, 255, 0) !important;
    font-weight: 510 !important;
    outline: none !important;
    color: #aca9b3 !important
  }
}
.expand-submenu-mobile {
  @extend %animated-faster;
  @extend %fadeInRight;
  position: absolute;
  z-index: 999999999;
  min-height: 100%;
  width: 100%;
  opacity: 1;
}

.show-inputs-mobile {
  z-index: 99999999;
}
.hide-inputs-mobile {
  z-index: 9;
}

.hide-submenu-mobile {
  @extend %animated-faster;
  @extend %fadeOutRight;
  position: absolute;
  min-height: 100%;
  width: 100%;
  opacity: 0;
}
.apprSelect {
  margin-top: 15px !important;
}
.surSelect {
  margin-left: -45px !important;
}
.mt3neg {
  margin-top: -3px !important;
}

.options-filter {
  div {
    display: inline-block;
    position: relative;
    margin-right: 15px;
    .count-filter {
      width: 25px;
      height: 25px;
      position: absolute;
      left: 18px;
      top: -7px;
      background: rgb(212, 0, 0);
      border-radius: 50%;
      padding-right: 2px;
      color: #ffffff;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
