<template>
  <div class="container-middle-custom-card" v-if="!loading">
    <div class="section-middle-custom-card" v-if="averageWithoutMatrix && returnAverageData(cardData.question)">
      <ChartController :view="cardData.viewType" :data="returnAverageData(cardData.question)" :cardData="cardData" />
    </div>
    <div class="section-middle-custom-card" v-if="overtime && returnOvertimeData(cardData.question)">
      <ChartController :view="cardData.viewType" :data="returnOvertimeData(cardData.question)" :cardData="cardData" />
    </div>
    <div class="section-middle-custom-card" v-if="overallWithoutMatrix && dataOverall">
      <ChartController :view="cardData.viewType" :data="dataOverall" :cardData="cardData" />
    </div>
    <div class="section-middle-custom-card" v-if="textCard && dataText">
      <TextList :data="dataText" :cardData="cardData" :question="cardData.question" />
    </div>
    <div class="section-middle-custom-card" v-if="overallMatrixWithoutLike && matrixData">
      <StackedBar :data="matrixData" :cardData="cardData.overall" :question="cardData.question" />
    </div>
    <div class="section-middle-custom-card" v-if="averageMatrixLike && matrixData">
      <likeOverall :data="matrixData" :cardData="cardData.overall" :question="cardData.question" />
    </div>
    <div class="section-middle-custom-card" v-if="overtimeMatrix && matrixData">
      <likeOvertime :cardData="matrixData" :graphic="cardData.overtime" :data="returnOvertimeData(cardData.question)" :question="cardData.question" />
    </div>
  </div>
</template>

<script>
import { dashboardsService } from '@/_services'
import ChartController from '../charts/ChartController.vue'
import TextList from '../charts/TextList.vue'
import StackedBar from '../charts/StackedBar.vue'
import likeOverall from '../charts/likeOverall.vue'
import likeOvertime from '../charts/likeOvertime.vue'
import { gridMaker, formatQuestionResult, formatters } from '@/_helpers'
import('lodash-es').then((lodashEs) => { window._ = lodashEs.default })

export default {
  name: 'MiddleChartDefault',
  props: ['keyCard', 'cardData', 'allQuestionsSynthetic', 'allQuestionsOvertime', 'allQuestionsOverall', 'loading'],
  components: {
    TextList,
    StackedBar,
    likeOverall,
    likeOvertime,
    ChartController
  },
  data () {
    return {
      graphic: {},
      dataText: '',
      matrixData: '',
      dataAverage: [],
      dataOvertime: this.allQuestionsOvertime,
      dataOverall: {}
    }
  },
  mounted () {
    this.returnCardData(this.cardData.question, this.cardData.viewType)
  },
  watch: {
    keyCard: {
      handler () {
        this.returnCardData(this.cardData.question, this.cardData.viewType)
      }
    }
  },
  computed: {
    textCard () { return this.cardData.question.type === 'text' && this.dataText.length },
    averageMatrixLike () { return this.cardData.viewType !== 'overtime' && this.cardData.ui === 'like' },
    overtimeMatrix () { return this.cardData.viewType === 'overtime' && this.cardData.type === 'matrix' },
    overtime () { return this.cardData.viewType === 'overtime' && this.cardData.type !== 'matrix' && this.dataOvertime.length > 0 },
    averageWithoutMatrix () { return this.cardData.viewType === 'average' && this.cardData.type !== 'matrix' && this.cardData.type !== 'text' },
    overallWithoutMatrix () { return this.cardData.viewType === 'overall' && this.cardData.type !== 'matrix' },
    overallMatrixWithoutLike () { return this.cardData.viewType === 'average' && this.cardData.type === 'matrix' && this.cardData.ui !== 'like' }
  },
  methods: {
    objectData (question) {
      const query = this.$route.query.q
      const emptyQuestions = this.$route.query.cleanEmptyQuestions
      const queryWithTimezone = formatters.formatDateByAddTimezone(query, 3, 'hours')
      const gridRange = gridMaker.getGrid(gridMaker.getDataFromQuery(query).$gte, gridMaker.getDataFromQuery(query).$lte)
      const isQuestionTypeToProcess = ['csat', 'nps', 'kpi', 'enum', 'nes', 'matrix', 'ces', 'nvs'].includes(question.type)

      return { query, emptyQuestions, queryWithTimezone, gridRange, isQuestionTypeToProcess }
    },
    async returnCardData (question, view) {
      const hasData = this.objectData(question)

      if (this.cardData.question.type === 'matrix') {
        return this.processMatrixCardData(hasData?.queryWithTimezone, view)
      }

      if (view === 'average' && this.allQuestionsSynthetic[0]?.questions) {
        this.processAverageQuestionType(question, hasData?.isQuestionTypeToProcess, view)
      }

      if (view === 'overall' && this.allQuestionsOverall.length) {
        this.dataOverall = this.allQuestionsOverall
        this.cardData.viewType = view

        setTimeout(() => { this.$emit('card-data-done') }, 500)
      }

      if (view === 'overtime' && Object.keys(this.allQuestionsOvertime).length > 0) {
        this.dataOvertime = this.allQuestionsOvertime
        setTimeout(() => { this.$emit('card-data-done') }, 500)
      }

      if (this.cardData.question.type === 'text' && !this.cardData.query) {
        return this.processTextCardData(hasData?.queryWithTimezone, question)
      }
    },
    processAverageQuestionType (question, isQuestionTypeToProcess, view) {
      if (isQuestionTypeToProcess && !this.allQuestionsSynthetic?.average) {
        const result = window._.find(this.allQuestionsSynthetic[0].questions, { question: { _id: question._id } })

        if (result) { this.dataAverage = result }

        this.cardData.viewType = view

        this.$emit('card-data-done')
      }
    },
    async processTextCardData (queryWithTimezone, question) {
      try {
        const textResult = await dashboardsService.getTextDash({ q: queryWithTimezone }, question._id, true)
        if (textResult) {
          this.dataText = textResult
          this.cardData.viewType = 'text'
          return this.$emit('card-data-done')
        }
      } catch (error) {
        console.error('Error fetching text data:', error)
      }
    },
    async processMatrixCardData (queryWithTimezone, view) {
      try {
        const result = await dashboardsService.getSyntheticData(queryWithTimezone)

        if (result) {
          this.matrixData = result

          this.cardData.viewType = view === 'overall' ? 'average' : view

          this.$emit('card-data-done')
        }
      } catch (error) {
        console.error('Error fetching synthetic data matrix:', error)
      }
    },
    returnAverageData (question) {
      if (question.type === 'matrix') {
        return formatQuestionResult.matrixAverage(this.dataAverage)
      }

      if (this.cardData.average.graphicType === 'percentageBar') {
        return formatQuestionResult.averageBarWithPercentage(this.dataAverage)
      }

      return this.dataAverage
    },
    returnOvertimeData (question) {
      return window._.find(this.allQuestionsOvertime, { question: question._id })
    }
  }
}
</script>

<style lang="scss">
.container-middle-custom-card {
  width: 100%;
  height: 100%;
  .section-middle-custom-card {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.2rem;
    .dash-responses-text {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
