<template>
  <div class="sentimental-aside">
    <app-card>
      <div class="row">
        <div id="list-responses" class="list-text-analytics">
          <div
            v-for="(item, index) in responses" v-bind:key="index"
            v-on:click="getIndividualResponses(item)"
            class="box-cite-analytics hvr-float-shadow"
            :class="[
              { 'border-promotor' : item.responses.score >= 0.5 && item.responses.score <= 1 },
              { 'border-neutral' : item.responses.score >= 0 && item.responses.score < 0.5  },
              { 'border-detractor' : item.responses.score < 0 }
            ]"
          >
            <div class="contact-answered" v-if="item?.seedData && (item?.seedData?.from?.email || item?.seedData?.from?.name)">
              <div style="margin-bottom:5px">
              <app-heading level="h5" class="contact-name"  v-if="item?.seedData?.from?.name">{{item?.seedData?.from?.name}}</app-heading>
              </div>
              <div>
              <app-heading level="h6" class="contact-email"  v-if="item?.seedData?.from?.email">{{item?.seedData?.from?.email}}</app-heading>
              </div>
            </div>
            <div class="contact-answered" v-else>
              <div style="margin-bottom:5px">
                <app-heading level="h5" class="contact-name">{{ $t('analytics.anonymous_reply') }}</app-heading>
              </div>
            </div>
            <div class="response-analytics">
              <p :class="{
                'sentiment-light-text': item.responses.magnitude < 1.2,
                'sentiment-medium-text': item.responses.magnitude >= 1.2 && item.responses.magnitude <= 3,
                'sentiment-hevy-text': item.responses.magnitude > 3
                }"
              >" {{item.responses.text}} "</p>
            </div>
            <div class="to-text-footer">
              <span class="text-date">{{item.responses.createdAt | moment("from", "now")}}</span>
              <div>
                <app-iconify name="heart" color="#00aaa7" size="40" v-if="item.responses.score >= 0.5 && item.responses.score <= 1" />
                <app-iconify name="heartHalf" color="#ffb500" size="40" v-if="item.responses.score >= 0 && item.responses.score < 0.5" />
                <app-iconify name="heartDislike" color="#ff607d" size="40" v-if="item.responses.score < 0" />
              </div>
            </div>
          </div>
        </div>
        <div class="row center-xl center-lg center-md center-sm load-more-btn">
          <i class="material-icons more-button" v-if="!loadingMore && !noMorePages" v-title="$t('tickets.load_more')" @click="loadMore()">more_horiz</i>
          <Circle2 class="circle-responses" v-if="loadingMore && !noMorePages"></Circle2>
        </div>
      </div>
    </app-card>
    <Details
      v-if="showDetails"
      :id="sendingId"
      @closeSendings="closeSidebar"
      :class="{'expand-details': showDetails}"
    ></Details>
  </div>
</template>

<script>
import('chart.js').then((chartjs) => {
  window.Chart = chartjs.default
})
export default {
  name: 'Analytics',
  props: ['data'],
  components: {
    Details: () => import('../SideBarConfig/SidebarSendings.vue'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-iconify': () => import('@binds-tech/binds-design-system/src/components/Icon/Iconify.vue')
  },
  data () {
    return {
      responses: [],
      showDetails: false,
      sendingId: undefined,
      loadingMore: false,
      noMorePages: false
    }
  },
  beforeDestroy () {
    this.$root.$off('load-responses-done')
  },
  mounted () {
    this.responses = this.data
    this.$root.$on('load-responses-done', (result) => {
      this.responses = result.responses
      this.noMorePages = result.noMoreItems
      this.loadingMore = false
    })
  },
  methods: {
    async getIndividualResponses (item) {
      this.showDetails = false
      this.sendingId = item._id
      setTimeout(() => { this.showDetails = true }, 100)
    },
    closeSidebar () {
      this.showDetails = false
    },
    loadMore () {
      this.loadingMore = true
      this.$root.$emit('load-more-responses')
    }
  }
}
</script>

<style lang="scss">
.circle-responses {
  margin: 15px 20px 0px 20px;
  width: 20px !important;
  height: 20px !important;
  border-color: rgb(77, 1, 90) rgb(161, 106, 206) rgb(161, 106, 206) !important;
}
.load-more-btn {
  padding-top: 5px;
  .no-items {
    font-size: 15px;
    font-style: italic;
  }
  .more-button {
    color: #424242 !important;
  }
}
.total-responses-sentimental {
  span {
    display: inline-block;
    margin-left: 10px;
    color: $color-gray-dark;
  }
  .heading {
    display: inline-block;
  }
}
.sentiment-light-text {
  font-size: 14px !important;
}
.sentiment-medium-text {
 font-size: 16px !important;
}
.sentiment-hevy-text {
  font-size: 18px !important;
}
</style>
