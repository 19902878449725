<template>
  <div class="card-overtime" v-if="itemSelected"
    :style="{
      '--legend': graphic?.colors.length ? graphic.colors[0] : '#CACACA'
    }">
    <div class="card-overtime-title">
      <div class="title">
        <app-heading level="h7" :key="render"
        :style="{'white-space': itemSelected.label.length > 10 ? 'nowrap' : 'pre-wrap'}"
        v-title="itemSelected.label" title-max-width="320">
        {{ itemSelected.label }}</app-heading>
      </div>
      <div class="select-option">
        <v-select
          :options="graphicIndividual"
          v-model="itemSelected"
          label="label"
          @input="optionSelected(itemSelected)"
        ></v-select>
      </div>
    </div>
    <div class="display-graphic">
      <div class="content-graphic">
        <div class="legends">
          <div class="info">
            <span v-if="question.ui === 'like'" class="legend legend--like"></span>
            <span v-else class="legend legend--matrix"></span>
            <p v-if="question.ui === 'like'">% {{ $t('dashboard.graphics.likes_in_time') }}</p>
            <p v-else>Média no tempo</p>
          </div>
        </div>
        <div class="chart-overtime-like">
          <canvas :id="makeSpecialId()"></canvas>
        </div>
      </div>
      <div class="details-graphic"  v-if="this.question.ui === 'like'">
        <div class="card-comparative">
          <div
            class="percent"
            :style="{
              '--clr': graphic?.colors.length ? graphic.colors[0] : '#73E831c7',
              '--num': itemSelected.qtyLike,
              '--off': `${310 - (310 * this.itemSelected.qtyLike) / 100}px`
            }">
            <div class="number">
              <p>% {{ $t('dashboard.graphics.total') }}</p>
              <p>{{ itemSelected.qtyLike }}</p>
              <p>LIKE</p>
            </div>
            <div class="dot"></div>
            <svg>
              <circle cx="50" cy="50" r="50"></circle>
              <circle cx="50" cy="50" r="50"></circle>
            </svg>
          </div>
        </div>
        <div class="card-comparative">
          <div
            class="percent"
            :style="{
              '--clr': graphic?.colors.length ? graphic.colors[1] : '#e0614e',
              '--num': itemSelected.qtyDislike,
              '--off': `${310 - (310 * this.itemSelected.qtyDislike) / 100}px`
            }"
          >
            <div class="number">
              <p>% {{ $t('dashboard.graphics.total') }}</p>
              <p>{{ itemSelected.qtyDislike }}</p>
              <p>DISLIKE</p>
            </div>
            <div class="dot"></div>
            <svg>
              <circle cx="50" cy="50" r="50"></circle>
              <circle cx="50" cy="50" r="50"></circle>
            </svg>
          </div>
        </div>
      </div>
      <div v-else
        class="details-graphic">
        <div class="card-comparative">
          <div class="percent"
            :style="{
              '--clr': graphic?.colors.length ? graphic.colors[0] :'#00005f',
              '--num': itemSelected.qtyAverage,
              '--off': `${310 - (310 * this.itemSelected.qtyAverage) / 100}px`
            }"
          >
            <div class="number">
              <p v-show="question.ui === 'like'">% {{ $t('dashboard.graphics.total') }}</p>
              <p :style="[question.ui !== 'like' ? {'padding-top': '5px'} : null]">{{ itemSelected.qtyAverage }}</p>
              <p>MÉDIA</p>
            </div>
            <div class="dot"></div>
            <svg>
              <circle cx="50" cy="50" r="50"></circle>
              <circle cx="50" cy="50" r="50"></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatters, gridMaker, canvasMaker } from '@/_helpers'
import('chart.js').then((chartjs) => { window.Chart = chartjs.default })

export default {
  name: 'likeOvertime',
  props: ['data', 'cardData', 'question', 'graphic'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    vSelect: () => import('vue-select')
  },
  data () {
    return {
      render: 0,
      graphicIndividual: [],
      labels: [],
      itemSelected: null,
      canvasId: null,
      currentChart: null
    }
  },
  created () {
    const gridRange = gridMaker.checkIfPeriodIsByDay(this.$route.name, this.$route.query.q)
    let formatDate = 'MMM Do' // esse if é para saber se o filtro é por hora ou dias, com isso formatamos melhor os labels de data do gráfico
    if (gridRange) {
      formatDate = 'HH:mm'
    }

    // MATRIX LIKE
    if (this.data && this.data.overtime && this.question.ui === 'like') {
      this.question.subjects.forEach(sub => {
        this.graphicIndividual.push({
          label: sub.label,
          percentage: null,
          qtyLike: null,
          qtyDislike: null
        })
      })

      this.data.overtime.forEach((item, i) => {
        if (i > 0 && this.data.overtime[i] && this.data.overtime[i][2] !== null) {
          const time = formatters.formatDateByLangAndFixTimeZone(this.data.overtime[i][0], this.lang, formatDate)
          this.labels.push(time)

          this.question.subjects.forEach(sub => {
            const idxSub = this.data.overtime[0].findIndex(i => i === sub._id)
            const percentage = []
            this.data.overtime.forEach((item, i) => {
              if (i > 0 && this.data.overtime[i][2] !== null) {
                const convertPercent = item[idxSub] ? item[idxSub] : 0
                percentage.push((convertPercent).toFixed(1))
                this.graphicIndividual.forEach(op => {
                  if (sub.label === op.label) {
                    op.percentage = percentage
                  }
                })
              }
            })
          })

          this.question.subjects.forEach(sub => {
            const percentLike = parseFloat(this.data.overall.avg[sub._id].toFixed(1))
            this.graphicIndividual.forEach(graphic => {
              if (graphic.label === sub.label) {
                graphic.qtyLike = percentLike
                graphic.qtyDislike = parseFloat((100 - graphic.qtyLike).toFixed(1))
              }
            })
          })

          this.itemSelected = this.graphicIndividual[0]
        }
      })
    }

    // MATRIX 'NORMAL'
    if (this.data && this.data.overtime && this.question.ui !== 'like') {
      this.question.subjects.forEach(sub => {
        this.graphicIndividual.push({
          label: sub.label,
          percentage: null,
          qtyAverage: null
        })
      })

      this.data.overtime.forEach((item, i) => {
        if (i > 0 && this.data.overtime[i] && this.data.overtime[i][2] !== null) {
          const time = formatters.formatDateByLangAndFixTimeZone(this.data.overtime[i][0], this.lang, formatDate)
          this.labels.push(time)

          this.question.subjects.forEach(sub => {
            const idxSub = this.data.overtime[0].findIndex(i => i === sub._id)
            const percentage = []
            this.data.overtime.forEach((item, i) => {
              if (i > 0 && this.data.overtime[i][2] !== null) {
                const convertPercent = item[idxSub] ? item[idxSub] : 0
                percentage.push((convertPercent / 10).toFixed(1))
                this.graphicIndividual.forEach(op => {
                  if (sub.label === op.label) {
                    op.percentage = percentage
                  }
                })
              }
            })
          })

          this.question.subjects.forEach(sub => {
            const average = parseFloat(this.data.overall.avg[sub._id].toFixed(1))
            this.graphicIndividual.forEach(graphic => {
              if (graphic.label === sub.label) {
                graphic.qtyAverage = average
              }
            })
          })

          this.itemSelected = this.graphicIndividual[0]
        }
      })
    }
  },
  mounted () {
    this.optionSelected(this.graphicIndividual[0])
  },
  methods: {
    makeSpecialId () {
      this.canvasId = canvasMaker.makeCanvasId(this.question)
      return this.canvasId
    },
    async renderChart (data) {
      const fontSize = 8
      const fontFamily = "'Avenir', Helvetica, Arial, sans-serif;"

      const ctx = document.getElementById(this.canvasId).getContext('2d')
      const myDataSets = {
        datasets: [
          {
            label: this.question.ui === 'like' ? this.$i18n.t('dashboard.graphics.percentage') : this.$i18n.t('dashboard.graphics.average'),
            data: data,
            backgroundColor: this.graphic?.colors.length ? `${this.graphic.colors[0]}40` : '#73E83150',
            borderColor: this.graphic?.colors.length ? this.graphic.colors[0] : '#73E831',
            pointBorderColor: this.graphic?.colors.length ? this.graphic.colors[0] : '#73E831',
            pointBackgroundColor: this.graphic?.colors.length ? this.graphic.colors[0] : '#73E831',
            borderWidth: 2
          }
        ]
      }

      const options = {
        maintainAspectRatio: false,
        tooltips: {
          titleFontFamily: fontFamily,
          titleFontSize: fontSize,
          position: 'average',
          titleMarginTop: 0,
          footerFontStyle: 'normal'
        },
        animation: {
          duration: 1900
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontFamily: fontFamily,
                fontSize: fontSize
              },
              scaleLabel: {
                display: false
              },
              stacked: false
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
                color: '#fff',
                zeroLineColor: '#fff',
                zeroLineWidth: 0
              },
              ticks: {
                fontFamily: fontFamily,
                fontSize: fontSize,
                max: this.question.ui === 'like' ? 100 : 10,
                min: 0
              },
              stacked: false
            }
          ]
        },
        legend: {
          display: false
        }
      }

      const datas = {
        labels: this.labels,
        datasets: myDataSets.datasets
      }

      if (this.render > 0) this.currentChart.destroy()

      const chart = await new window.Chart(ctx, {
        type: 'line',
        data: datas,
        options: options
      })

      this.currentChart = chart

      return chart
    },
    optionSelected (e) {
      if (e) {
        this.itemSelected = e
        this.renderChart(e.percentage)
        this.render++
      }
    }
  },
  computed: {
    lang () {
      return this.$store.getters['account/getLang']
    }
  }
}
</script>

<style lang="scss">
.card-overtime {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .card-overtime-title {
    width: 100%;
    height: 10%;
    padding: 3px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 65%;
      padding-left: 10px;
      overflow: hidden;
      .h7 {
        width: 80%;
        line-height: 18px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .select-option {
      padding-right: 30px;
      width: 35%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .v-select {
        .vs__clear {
          display: none;
        }
        .vs__selected {
          max-width: 220px !important;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .vs__dropdown-toggle {
          min-width: 270px !important;
          max-width: 270px !important;
        }
        .vs__dropdown-menu {
          width: 270px !important;
          .vs__dropdown-option {
            max-width: 270px !important;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
  .display-graphic {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .content-graphic {
      width: 80%;
      height: 100%;
      .legends {
        width: 100%;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 5px;
        margin-bottom: 5px;
        .info {
          height: 100%;
          margin: 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .legend {
            display: block;
            width: 8px;
            height: 8px;
            margin: 0 5px;
            border-radius: 50%;
          }
          p {
            font-size: 14px;
            margin-bottom: 3px;
            color: #424242;
          }
        }
        .legend--like, .legend--matrix {
          background-color: var(--legend);
        }
      }
      .chart-overtime-like {
        width: 100%;
        height: 290px;
        #canvas {
          width: 100% !important;
          height: 290px;
        }
      }
    }
    .details-graphic {
      width: 20%;
      height: 300px;
      margin: 20px 0;
      padding-right: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .card-comparative {
        width: 120px;
        height: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        .percent {
          position: relative;
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          .number {
            width: 80px;
            text-align: center;
            position: absolute;
            top: 20%;
            left: 19%;
            opacity: 0;
            animation: fadeCircle 0.5s linear forwards;
            animation-delay: 1s;
            p {
              font-size: 8px;
              margin-top: 5px;
              text-align: center;
              color: #424242;
              letter-spacing: 1px;
            }
            p:nth-child(1) {
              font-size: 10px;
            }
            p:nth-child(2) {
              top: 40px;
              font-size: 30px;
              font-weight: 700;
            }
          }
          .dot {
            position: absolute;
            inset: 5px;
            left: 15px;
            z-index: 10;
            animation: animateDot 1s linear forwards;
          }
          .dot::before {
            content: '';
            position: absolute;
            top: -2px;
            border-radius: 50%;
            left: 50%;
            z-index: 11;
            width: 15px;
            height: 15px;
            background: var(--clr);
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%) !important;
            box-shadow: 0 0 10px var(--clr), 0 0 30px var(--clr);
            -webkit-box-shadow: 0 0 10px var(--clr), 0 0 30px var(--clr);
          }
          svg {
            width: 110px;
            transform: rotate(270deg);
            -webkit-transform: rotate(270deg);
            height: 100%;
            circle {
              width: 100%;
              height: 100%;
              fill: transparent;
              stroke-width: 10;
              stroke: rgb(241, 241, 241);
              transform: translate(5px, 15px);
              -webkit-transform: translateX(5px, 15px);
            }
            circle:nth-child(2) {
              stroke: var(--clr);
              stroke-dasharray: 310px;
              stroke-dashoffset: var(--off);
              opacity: 0;
              animation: fadeCircle 0.5s linear forwards;
              animation-delay: 1s;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .title {
    .h8 {
      display: none !important;
    }
  }
}

@-webkit-keyframes animateDot {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(calc(3.6deg * var(--num)));
    -webkit-transform: rotate(calc(3.6deg * var(--num)));
  }
}
@keyframes animateDot {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(calc(3.6deg * var(--num)));
    -webkit-transform: rotate(calc(3.6deg * var(--num)));
  }
}

@-webkit-keyframes fadeCircle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeCircle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
