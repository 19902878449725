<template>
  <div>
    <div class="card-main-insight">
      <div class="card-info-insight">
        <div class="card-header-insight">
          <div class="card-header-info">
            <p class="card-title-name">{{ item.seedData.from.name || $t('dashboard.no_name') }}</p>
            <p class="card-title-email" v-if="item.seedData.from.email || item.seedData.from.phone">{{ item.seedData.from.email || convertPhone(item.seedData.from.phone) }}</p>
            <p class="card-title-email" v-else>{{ $t('dashboard.no_email') }}</p>
            <p class="card-open-details" @click="openDetails(item._id.slice(0, 24))">
              <span>Mais informações</span>
              <i class="material-icons icon-open-details">open_in_new</i>
            </p>
          </div>
          <div class="card-tags-insight">
            <TagInsight :icon="'inventory_2'" :title="'Produto'" :value="item.insight.artificialAnalysis.product" />
            <TagInsight :icon="'favorite'" :title="'Sentimento'" :value="item.insight.artificialAnalysis.sentiment" />
            <TagInsight :icon="'sentiment_very_satisfied'" :title="'Emoção'" :value="item.insight.artificialAnalysis.emotion" />
            <TagInsight :icon="'star'" :title="'Categoria'" :value="item.insight.artificialAnalysis.classification" />
          </div>
        </div>
        <div class="card-text-insight">
          <p class="text-insight" v-html="text"></p>
          <p class="date-responded">{{ this.$moment(item.respondedAt).locale(this.lang).format('LL') }}</p>
        </div>
        <div class="card-insight-ia">
          <div class="insight-ia">
            <p class="title-insight">Insight</p>
            <p class="label-insight">{{ item.insight.artificialAnalysis.insights }}</p>
          </div>
          <div class="insight-ia" style="margin-top: 1rem;">
            <p class="title-insight">Plano de ação</p>
            <p class="label-insight">{{ item.insight.artificialAnalysis.actions || 'N/A' }}</p>
          </div>
          <img src="../../../../public/img/openIA.png" class="icon-ia">
        </div>
      </div>
      <div class="section-swot-insight">
        <SwotCard :swot="swot" />
      </div>
    </div>
    <SidebarSendings
      v-if="openSidebar"
      :id="sendingId"
      @closeSendings="openSidebar = false"
      :class="{'expand-details': openSidebar}"
    ></SidebarSendings>
  </div>
</template>

<script>
import TagInsight from './TagInsight.vue'
import SwotCard from './SwotCard.vue'
import SidebarSendings from '../../SideBarConfig/SidebarSendings.vue'

export default {
  name: 'CardInsight',
  props: ['item', 'highlightedText'],
  components: {
    TagInsight,
    SwotCard,
    SidebarSendings
  },
  data () {
    return {
      text: '',
      sendingId: '',
      swot: null,
      openSidebar: false
    }
  },
  mounted () {
    this.text = this.item.insight.text
    if (this.highlightedText) { this.applyHighlight(this.text, this.highlightedText) }

    this.swot = {
      s: this.item.insight.artificialAnalysis.s,
      w: this.item.insight.artificialAnalysis.w,
      o: this.item.insight.artificialAnalysis.o,
      t: this.item.insight.artificialAnalysis.t
    }
  },
  computed: {
    lang () { return this.$store.getters['account/getLang'] }
  },
  methods: {
    convertPhone (e) {
      let number = e.toString()

      if (number.startsWith('+55')) {
        number = number.slice(3)
        const ddd = number.slice(0, 2)
        const parte1 = number.slice(2, 7)
        const parte2 = number.slice(7)
        const numberFormated = `(${ddd}) ${parte1}-${parte2}`
        return numberFormated
      } else {
        return number
      }
    },
    applyHighlight (inputString, term) {
      const regex = new RegExp(term, 'gi')
      this.text = inputString.replace(regex, match => `<span style="background: #1e90ff; color: #ffffff; border-radius: 0.2rem">${match}</span>`)
    },
    openDetails (sendingId) {
      this.sendingId = sendingId
      this.openSidebar = true
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
}
.card-main-insight {
  width: 100%;
  background: #ffffff;
  border-radius: 0.5rem;
  border: 0.1rem solid #e1e1e1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  .card-info-insight {
    width: 65%;
    padding: 0.7rem;
    border-right: 0.1rem solid #e1e1e1;
    .card-header-insight {
      width: 100%;
      min-height: 2.5rem;
      margin-bottom: 0.7rem;
      display: flex;
      justify-content: space-between;
      .card-header-info {
        width: fit-content;
        max-width: 45%;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        .card-title-name {
          font-size: 1.1vw;
          color: #454548;
          font-weight: bold;
        }
        .card-title-email {
          font-size: 0.7vw;
          color: #808080;
        }
        .card-open-details {
          font-size: 1.2vw;
          margin-top: 0.5rem;
          display: flex;
          align-items: center;
          gap: 0.2rem;
          line-height: 1.1vw;
          color: var(--accent-color);
          cursor: pointer;
          .icon-open-details {
            font-size: 0.8vw;
          }
        }
      }
      .card-tags-insight {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
      }
    }
    .card-text-insight {
      min-height: 3rem;
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .text-insight {
        font-size: 0.8vw;
        line-height: 1.3vw;
        color: #454548;
      }
      .date-responded {
        font-size: 0.6vw;
        margin-top: 0.2rem;
        color: #808080;
      }
    }
    .card-insight-ia {
      position: relative;
      width: 100%;
      min-height: 4.5rem;
      padding: 0.5rem;
      border-radius: 0.5rem;
      border: 0.1rem solid #e1e1e1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .insight-ia {
        .title-insight {
          font-size: 0.9vw;
          color: #454548;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }
        .label-insight {
          width: 90%;
          color: #808080;
          font-size: 0.8vw;
        }
        .date-insight {
          margin-top: 0.5rem;
          font-size: 0.7rem;
          color: #808080;
        }
      }
      .icon-ia {
        position: absolute;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        right: 1rem;
        width: 2rem;
        height: 2rem;
      }
    }
  }
  .section-swot-insight {
    width: 35%;
  }
}

@media (max-width: 1068px) {
  .card-main-insight {
    flex-direction: column;
    .card-info-insight {
      width: 100%;
      border: none;
      border-bottom: 0.1rem solid #e1e1e1;
      .card-header-insight {
        flex-direction: column;
        height: fit-content;
        margin-bottom: 0.5rem;
        .card-title-insight {
          width: 100%;
          font-size: 1.2rem;
          margin-bottom: 0.5rem;
        }
        .card-tags-insight {
          width: 100%;
          height: fit-content;
          flex-wrap: wrap;
        }
      }
      .card-text-insight {
        .text-insight {
          font-size: 0.8rem;
        }
        .date-responded {
          margin-top: 0.5rem;
        }
      }
      .card-insight-ia {
        .insight-ia {
          .title-insight {
            font-size: 0.9rem;
          }
          .label-insight {
            font-size: 0.8rem;
          }
        }
        .icon-ia {
          top: 0.3rem;
          right: 0.3rem;
        }
      }
    }
    .section-swot-insight {
      width: 100%;
      margin: 0;
    }
  }
}
</style>
