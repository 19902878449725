<template>
  <div>
    <app-card class="cardSendhub">
      <app-card-header class="row headerSendhub headerSms">
        <app-heading level="h4" light>
          <app-icon name="sms"></app-icon>
          Edite a mensagem no SMS
        </app-heading>
      </app-card-header>
      <div class="row center-lg">
        <div class="sms-title">
          <div class="content-edit-title">
            <span class="edit-label">{{ $t('sendhub.smsHub.title') }}</span>
            <div class="input-edit-title">
              <app-form-textarea v-model="message"
                secondary
                :disabled="checkUserIsAllowedToSend"
                v-on:change.native="saveSms()"
                :maxlength="(130 - 9).toString()"
                >
              </app-form-textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="sms-link">
          <span> {{linkLabel}} </span>
      </div>
      <div class="sms-info">
        <app-heading level="h6">{{ $t('sendhub.smsHub.charactersRemaining') }} : {{ charactersLeft.remaining }}</app-heading>
        <app-heading level="h6">{{ $t('sendhub.smsHub.charactersLimit') }} : {{ charactersLeft.limit }}</app-heading>
      </div>
    </app-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SmsHub',
  components: {
    'app-form-textarea': () => import('@binds-tech/binds-design-system/src/components/Form/Textarea'),
    'app-icon': () => import('@binds-tech/binds-design-system/src/components/Icon/Icon'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-card-header': () => import('@binds-tech/binds-design-system/src/components/Card/Header')
  },
  data () {
    return {
      editing: false,
      message: `${this.$store.getters['survey/getSurvey'].sms}`,
      linkLabel: this.$t('sendhub.smsHub.label')
    }
  },
  mounted () {
    let msg = this.message
    /*
      a mensagem não pode ter acentos, nem caractéres especiais
      mas pode ter pontuação, como: ponto final, vírgula, interrogação e exclamação.
    */
    msg = msg.normalize('NFD').replace(/([\u0300-\u036f]|[^a-zA-Z\s0-9.-:,|*!?-])/g, '').replace('*|SRVYES|*', '')
    this.message = msg
  },
  computed: {
    checkUserIsAllowedToSend () {
      if (this.user.type === 'user' && this.user.features.seedBatches) return true // in this case the user is not allowed to modify the field
      else return false
    },
    charactersLeft () {
      // isnide backEnd the lib shortid use 9 carcters to generate the string
      const Linklength = 9
      const char = this.message.length - Linklength
      const limit = 130 - Linklength
      return { remaining: (limit - char) - Linklength, limit: limit }
    },
    account () {
      const user = this.$store.getters['account/getUser']
      return user
    },
    ...mapGetters({
      user: 'account/getUser'
    })
  },
  methods: {
    saveSms () {
      const message = this.message.normalize('NFD').replace(/([\u0300-\u036f]|[^a-zA-Z\s0-9.-:,|*!?-])/g, '').replace('*|SRVYES|*', '')
      this.$store.commit('survey/survey', { sms: message })
    }
  }

}
</script>
<style lang="scss">
.headerSms {
  justify-content: center;
  align-items: center;
}
.sms-title {
  width: 80%;
  margin: 0 auto;
  margin-top: 60px;
  align-items: center;
  textarea {
    height: 140px;
    color: #1d1b22 !important;
  }
  &:hover {
    textarea {
      border: 2px dashed $color-purple-darker !important;
    }
  }
}
.sms-link {
  margin: 0 auto;
  text-align: center;
  align-content: center;
  margin-top: -45px;
  position: inherit;
  display: grid;
  width: 144px;
  height: 22px;
  border-radius: 3px;
}
.sms-info {
  width: 80%;
  margin: 0 auto;
  margin-top: 120px;
  span{
    display: block;
    margin-top: 10px;
  }
}
</style>
