<template>
  <div class="container-dashboard-custom">
    <div class="loading-dashboard-custom" v-show="loadingCustom">
      <Circle2 class="spinner-dashboard-custom"/>
    </div>
    <div class="container-auto-scroll" v-if="tvDash">
      <div class="content-toggle-auto-scroll">
        <app-toggle-input v-model="isScrollAllowed" class="toggle-auto-scroll"></app-toggle-input>
        <app-heading class="title-auto-scroll" level="h6">{{ $t('real_time.auto_scroll') }}</app-heading>
      </div>
    </div>
    <div class="container-cards-custom">
      <CardDashboard v-for="(card, idx) in cardsCustom"
        :key="idx"
        :card="card"
        :isCustom="true"
        :allQuestionsSynthetic="allQuestionsSynthetic"
        :allQuestionsOvertime="allQuestionsOvertime"
        :allQuestionsOverall="allQuestionsOverall"
        @edit-card="editCard(card, idx)"
      />
      <div class="container-new-card-customized" v-if="!tvDash" @click="newCard()">
        <i class="material-icons add-card-icon" v-title="$t('dashboard.add_new_card')">note_add</i>
      </div>
      <NewCardCustom
        v-if="openNewCard"
        @close-sidebar-edit="openNewCard = false"
        @update-dashboard-custom="getDashboardCards()"
      />
    </div>

    <ConfigCardCustom
      v-if="openEditCard"
      @close-sidebar-edit="openEditCard = false"
      @update-dashboard-custom="getDashboardCards()"
      :card="cardSelected"
    />
  </div>
</template>

<script>
import gsap from 'gsap'
import { dashboardsService } from '@/_services'
import CardDashboard from '../../inputs/CardDashboard.vue'
import NewCardCustom from './NewCardCustom.vue'
import ConfigCardCustom from './ConfigCardCustom.vue'

export default {
  name: 'DashboardCustom',
  props: ['survey', 'allQuestionsSynthetic', 'allQuestionsOvertime', 'allQuestionsOverall', 'tvDash'],
  components: {
    CardDashboard,
    NewCardCustom,
    ConfigCardCustom,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2'),
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      key: 0,
      loadingCustom: true,
      openEditCard: false,
      openNewCard: false,
      cardSelected: null,
      cardsCustom: [],
      scrollInterval: null,
      isScrollAllowed: false
    }
  },
  mounted () {
    this.getDashboardCards()
  },
  computed: {},
  watch: {
    tvDash: {
      immediate: true,
      handler (newVal) {
        this.pageScroll(newVal, this.isScrollAllowed)
      }
    },
    isScrollAllowed: {
      immediate: true,
      handler (newVal) {
        this.pageScroll(this.tvDash, newVal)
      }
    }
  },
  methods: {
    async getDashboardCards () {
      this.cardsCustom = []
      this.setLoading()
      const result = await dashboardsService.getCustomizedCardsList(this.survey._id)
      const cardsCustom = []
      if (result.length) {
        result.forEach(item => {
          const matchingQuestion = this.survey.questions.find(question => item.question === question._id)

          if (matchingQuestion) {
            const card = {
              [item.viewType]: item,
              question: matchingQuestion,
              viewType: item.viewType,
              _id: item._id,
              type: matchingQuestion.type,
              ui: matchingQuestion.ui,
              query: item.query,
              title: item.title,
              colors: item.colors
            }

            cardsCustom.push(card)
          }
        })
      }

      cardsCustom.map(card => {
        const idx = this.allQuestionsSynthetic[0].questions.findIndex(q => q.question._id === card.question._id)
        if (idx > -1) { card.total = this.allQuestionsSynthetic[0].questions[idx].total }
      })

      this.cardsCustom = cardsCustom
      this.setLoading(true)
    },
    editCard (card) {
      this.openEditCard = true
      this.cardSelected = card
    },
    newCard () {
      this.openNewCard = true
    },
    setLoading (close) {
      if (!close) { this.loadingCustom = true }

      const action = close ? 0 : 1
      const divLoading = document.querySelector('.loading-dashboard-custom')
      gsap.to(divLoading, { opacity: action, duration: 0.5, onComplete: () => { if (close) { this.loadingCustom = false } } })
    },
    pageScroll (fullPage, isScrollAllowed) {
      const dashCustomContainer = document.querySelector('.container-cards-custom')

      if (fullPage && isScrollAllowed) {
        this.scrollInterval = setInterval(() => {
          const isAtBottom = dashCustomContainer.scrollTop + dashCustomContainer.clientHeight >= dashCustomContainer.scrollHeight

          if (isAtBottom) {
            dashCustomContainer.scrollTo(0, 0)
          } else {
            dashCustomContainer.scrollBy(0, 1)
          }
        }, 25)
      } else {
        clearInterval(this.scrollInterval)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-dashboard-custom {
  margin: 1rem 4vw;
  .container-cards-custom {
    max-height: 76vh;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.5vw;
  }

  .container-auto-scroll {
    background: #ffffff;
    margin: 1rem 0;
    height: 2.5vw;
    padding: 0 1vw;
    border-radius: 8px;
    box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .content-toggle-auto-scroll {
      width: fit-content;
      display: flex;
      gap: 3rem;
      .toggle-auto-scroll {
        position: relative;
      }
      .title-auto-scroll {
        margin: 0;
        color: #808080;
        width: fit-content;
      }
    }
  }
  .loading-dashboard-custom {
    background: #EFF3F890;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 36;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner-dashboard-custom {
      width: 2.1vw !important;
      height: 2.1vw !important;
      border-width: 3px !important;
      border-radius: 35px;
      border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
    }
  }
  .container-new-card-customized {
    background: transparent;
    width: calc(25% - 0.57rem);
    height: 25vw;
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border: 2px dashed var(--accent-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .add-card-icon {
      color: var(--accent-color);
      font-size: 6vw;
      opacity: 0.8;
    }
  }
}
</style>
