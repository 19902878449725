<template>
  <div class="container-card-dashboard" :class="{ 'double-width': doubleWidth }">
    <div class="content-loading-card-dashboard" :ref="`ref-card-${card._id}`" v-if="loading">
      <Circle2 class="spinner-social-card-dashboard"/>
      <p class="title-loading-card-dashboard">{{ $t('dashboard.loading_graphic') }}</p>
    </div>
    <div class="content-not-available" v-if="noDataToShow">
      <i class="material-icons icon-config-question" @click="$emit('edit-card')" v-title="$t('dashboard.config_graphic')">settings</i>
      <NotFound />
    </div>
    <div class="header-card-dashboard" v-show="!loading && !noDataToShow">
      <div class="title-card-dashboard">
        <p class="label-card-dashboard">{{ card.title }}</p>
        <!-- <i class="material-icons icon-config-question" @click="$emit('edit-card')" v-title="$t('dashboard.config_graphic')">settings</i> -->
      </div>
      <div class="stats-card-dashboard">
        <div class="icons-card-dashboard">
          <i class="material-icons icon-card-dashboard" v-title="isChurn ? $t('health_score.tooltip_churn') : $t('health_score.tooltip_score')">{{ isChurn ? 'autorenew' : 'favorite' }}</i>
        </div>
      </div>
    </div>
    <div class="content-card-dashboard" v-show="!loading" :style="{'height': handlerHeight }">
      <GaugeHealth :card="card" v-if="!loading && card.viewType === 'average' && !isChurn"/>
      <ChurnHealth :card="card" v-if="!loading && card.viewType === 'average' && isChurn" />
      <ChartController v-if="!loading && card.overtime.length && card.viewType === 'overtime'" :isHealth="true" :view="card.viewType" :data="card" :cardData="cardData" />
      <BarHorizHealth v-if="!loading && hasOverall && card.viewType === 'overall'" :dataHealth="card" :cardData="cardData" />
    </div>
    <div class="section-buttons-view-dashboard" v-show="!isCustom && !loading">
      <ChangeTab :renderTabs="tabs" @tab-active="selectView($event)"></ChangeTab>
    </div>
  </div>
</template>
<script>
import gsap from 'gsap'
import ChartController from '../dashboard/charts/ChartController.vue'
import GaugeHealth from '../dashboard/charts/GaugeHealth.vue'
import ChurnHealth from '../dashboard/charts/ChurnHealth.vue'
import BarHorizHealth from '../dashboard/charts/BarHorizHealth.vue'
import NotFound from '../dashboard/charts/NotFound.vue'
import ChangeTab from './ChangeTab.vue'
import('lodash-es').then((lodashEs) => { window._ = lodashEs.default })

export default {
  name: 'CardDashboardHealth',
  props: [
    'card',
    'isChurn',
    'isCustom',
    'doubleWidth'
  ],
  components: {
    NotFound,
    ChangeTab,
    GaugeHealth,
    ChurnHealth,
    BarHorizHealth,
    ChartController,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      loading: true,
      noDataToShow: false,
      colorHealth: ['#d63237', '#d63237'],
      colorChurn: ['#7339B2', '#7339B2'],
      tabs: [
        { render: true, active: false, title: this.$t('dashboard.views.average'), value: 'average', icon: 'speed' },
        { render: true, active: false, title: this.$t('dashboard.views.overtime'), value: 'overtime', icon: 'monitor_heart' },
        { render: true, active: false, title: this.$t('dashboard.views.overall'), value: 'overall', icon: 'analytics' }
      ],
      cardData: {
        overtime: {
          colors: [],
          graphicType: 'overtime'
        },
        overall: {
          graphicType: 'horizontalBar'
        }
      }
    }
  },
  created () {
    this.cardData.overtime.colors = this.card.type === 'churn' ? this.colorChurn : this.colorHealth

    this.tabs.forEach(tab => {
      tab.active = false
      if (this.card.viewType === tab.value) { tab.active = true }
    })

    if (this.currentPage !== 'average') {
      if (!this.card.overtime.length || !Object.keys(this.card.overall).length) {
        return this.$emit('get-data-health', { view: this.currentPage, isChurn: this.isChurn })
      }
    }
  },
  mounted () {
    if (Object.keys(this.card.average).length > 0) { this.loadingDone(this.card._id) }
  },
  computed: {
    currentPage () { return this.$store.getters['dashboard/getCurrentPage'] },
    formattedNumber () { return parseFloat(this.card.total).toLocaleString('pt-BR') },
    hasOverall () { return Object.keys(this.card.overall).length },
    handlerHeight () {
      if (this.card.ui === 'text' || this.isCustom) { return '85%' }
      return '75%'
    }
  },
  watch: {
    'card.overtime': {
      deep: true,
      handler () {
        this.loadingDone(this.card._id)
      }
    },
    'card.overall': {
      deep: true,
      handler () {
        this.loadingDone(this.card._id)
      }
    }
  },
  methods: {
    selectView (view) {
      this.loading = true
      this.card.viewType = view
      this.query = this.$route.query.q

      if (view === 'overtime' && !this.card.overtime.length) {
        return this.$emit('get-data-health', { view: 'overtime', isChurn: this.isChurn })
      }

      if (view === 'overall' && !Object.keys(this.card.overall).length) {
        return this.$emit('get-data-health', { view: 'overall', isChurn: this.isChurn })
      }

      setTimeout(() => {
        this.loadingDone(this.card._id)
      })
    },
    notAvailable (cardId) {
      this.noDataToShow = true
      this.loadingDone(cardId)
    },
    loadingDone (refLoad) {
      const refLoading = this.$refs[`ref-card-${refLoad}`]

      if (!refLoading) return

      gsap.to(refLoading, {
        opacity: 0,
        duration: 0.5,
        onComplete: () => { this.loading = false }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-card-dashboard {
  user-select: none;
  position: relative;
  background: #f6f4f7;
  width: 22.1vw;
  height: 29vw;
  border-radius: 0.8rem;
  padding: 1rem;
  overflow: hidden;
  border: 1px solid #e1e1e1;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.06);
  .content-loading-card-dashboard {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    background: #ffffff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    .spinner-social-card-dashboard {
      width: 2vw !important;
      height: 2vw !important;
      border-width: 3px !important;
      border-color: var(--accent-color) #cacaca #cacaca !important;
    }
    .title-loading-card-dashboard {
      font-size: 0.65vw;
      color: #cacaca;
      font-weight: 500;
    }
  }
  .content-not-available {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-config-question {
      position: absolute;
      right: 0;
      top: 0;
      background: #9696A0;
      width: 1.4vw;
      height: 1.4vw;
      border-radius: 50%;
      font-size: 1vw;
      color: var(--default-text-color);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .header-card-dashboard {
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .title-card-dashboard {
      background: #ffffff;
      height: 1.5vw;
      border-radius: 35px;
      border: 1px solid #f1f1f1;
      padding-left: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.05);
      .icon-card-dashboard {
        font-size: 0.9vw;
        color: #e7e7e7;
        cursor: default;
      }
      .has-filter {
        color: var(--accent-color);
      }
      .icon-config-question {
        background: #9696A0;
        width: 1.4vw;
        height: 1.4vw;
        border-radius: 50%;
        font-size: 1vw;
        color: var(--default-text-color);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .label-card-dashboard {
        flex: 1;
        font-size: 0.85vw;
        line-height: 1vw;
        font-weight: 500;
        color: #454548;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .stats-card-dashboard {
      background: #ffffff;
      height: 1.5vw;
      border-radius: 35px;
      border: 1px solid #f1f1f1;
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.3rem;
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.05);
      .icon-card-dashboard {
        font-size: 0.8vw;
        color: #808080;
        cursor: default;
      }
      .count-responses-dashboard {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        .title-responses-card-dashboard {
          font-size: 0.75vw;
          color: #454548;
        }
        .icon-responses {
          color: #db6363;
          font-size: 0.8vw;
        }
      }
      .icons-card-dashboard {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
      }
    }
  }
  .content-card-dashboard {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section-buttons-view-dashboard {
    height: 10%;
  }
}

.double-width {
  width: calc(50% - 0.39rem);
}
</style>
