<template>
  <div class="container-ticket-sidebar">
    <div class="content-loading-ticket-sidebar" v-show="loadingTicket"></div>
    <div class="header-ticket-sidebar">
      <p class="title-ticket-sidebar">{{ $t('tickets.title') }}</p>
      <p class="description-ticket-sidebar">{{ $t('tickets.description') }}</p>
    </div>
    <div class="content-ticket-sidebar" v-if="showTicket">
      <div class="content-new-ticket-sidebar" v-if="!hasTicket">
        <p class="title-new-ticket-sidebar">{{ $t('tickets.choose_attendant') }}</p>
        <div class="select-attendance-ticket">
          <app-select-binds
            class="input-select-attendance-sidebar"
            :options="detailsAttendant.attendants"
            titleItem="name"
            id="select-attendance-sidebar"
            :value="detailsAttendant.attendantSelected"
            @model="detailsAttendant.attendantSelected = $event"
            />
            <button class="button-select-attendance-sidebar" @click="handleNewAttendance()">
              <i class="material-icons done">done</i>
            </button>
        </div>
      </div>
      <div v-if="hasTicket" class="content-current-ticket-sidebar">
        <div class="current-ticket-sidebar">
          <p class="title-current-ticket-sidebar">{{ $t('tickets.last') }}</p>
          <div class="section-info-ticket-sidebar" :style="{'border-color': dataTicket.ticket.status === 'closed' ? '#47ba5e90' : '#e1e1e1'}">
            <div class="icon-info-ticket-sidebar">
              <i v-if="dataTicket.ticket.status !== 'closed'" class="material-icons icon-ticket-sidebar">chat</i>
              <i v-else class="material-icons icon-ticket-sidebar" style="color: #47ba5e;">mark_chat_read</i>
            </div>
            <div class="detail-info-ticket-sidebar">
              <p class="label-info-ticket-sidebar label-closed" v-if="dataTicket.ticket.status === 'closed'">{{ $t('tickets.closed') }}</p>
              <p class="label-info-ticket-sidebar">{{ dataTicket.ticket?.by }}</p>
              <p class="label-info-ticket-sidebar">{{ dataTicket.ticket?.email }}</p>
              <p class="label-info-ticket-sidebar">{{ moment(dataTicket.ticket?.date) }}</p>
            </div>
          </div>
        </div>
        <div class="current-ticket-sidebar">
          <p class="title-current-ticket-sidebar">{{ $t('tickets.category.head_title') }}</p>
          <div class="section-info-ticket-sidebar">
            <div class="icon-info-ticket-sidebar" @click="openCategories = true">
              <i class="material-icons icon-ticket-sidebar">local_offer</i>
            </div>
            <div class="detail-info-ticket-sidebar">
              <p class="label-info-ticket-sidebar" v-if="dataTicket.ticket?.category">{{ dataTicket.ticket?.category }}</p>
              <p class="label-info-ticket-sidebar" v-else>{{ $t('tickets.category.no_category') }}</p>
            </div>
          </div>
        </div>
        <div class="current-ticket-sidebar" v-if="eventList.length">
          <p class="title-current-ticket-sidebar">{{ $t('tickets.attendants_history') }}</p>
          <div class="section-event-ticket-sidebar">
            <div class="event-ticket-sidebar" v-for="(event, idx) in eventList" :key="idx">
              <i class="material-icons icon-ticket-sidebar">account_circle</i>
              <div class="detail-info-ticket-sidebar">
                <p class="label-info-ticket-sidebar">{{ event.attendant && event.attendant.name ? event.attendant.name : $t('dashboard.no_attendant') }}</p>
                <p class="label-info-ticket-sidebar">{{ event.attendant && event.attendant.email ? event.attendant.email : $t('dashboard.no_email') }}</p>
                <p class="label-info-ticket-sidebar">{{ moment(event.createdAt) }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="current-ticket-sidebar" v-if="firstMessage">
          <p class="title-current-ticket-sidebar">{{ $t('tickets.first_contact') }}</p>
          <div class="section-event-ticket-sidebar">
            <div class="event-ticket-sidebar">
              <i class="material-icons icon-ticket-sidebar">account_circle</i>
              <div class="detail-info-ticket-sidebar">
                <p class="label-info-ticket-sidebar">{{ firstMessage.author.name }}</p>
                <p class="label-info-ticket-sidebar">{{ firstMessage.author.email }}</p>
                <p class="label-info-ticket-sidebar">{{ moment(firstMessage.createdAt) }}</p>
                <p class="label-text-ticket-sidebar">"{{ firstMessage.text }}"</p>
              </div>
            </div>
          </div>
        </div>
        <div class="redirect-chat-sidebar" v-if="showBtnRedirectChat">
          <button class="btn-redirect-chat-sidebar" @click="redirectToChat()">{{ $t('tickets.see_ticket') }}</button>
        </div>
      </div>
    </div>
    <app-modal v-model="openCategories" class="modal-default-primary modal-categories" lightbox close>
      <Categories :ticketId="dataTicket.sendingId" :currentTicket="dataTicket.ticket?.category" @updateCategory="setNewCategory($event)" />
    </app-modal>
  </div>
</template>

<script>
import gsap from 'gsap'
import { accountService, ticketsService } from '@/_services'
import Categories from '../../tickets/Categories.vue'

export default {
  name: 'TicketSidebar',
  props: ['dataTicket'],
  components: {
    Categories,
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    'app-select-binds': () => import('@binds-tech/binds-design-system/src/components/Select/SelectBinds')
  },
  data () {
    return {
      openCategories: false,
      noAttendant: { name: this.$i18n.t('tickets.no_attendant'), value: null },
      detailsAttendant: {},
      eventList: [],
      firstMessage: null,
      loadingTicket: true
    }
  },
  created () {
    if (!this.hasTicket) { this.getDetailTicket() }

    this.getEventTicket()
  },
  computed: {
    hasTicket () { return Object.keys(this.dataTicket.ticket).length > 0 },
    showBtnRedirectChat () { return this.detailsAttendant.type !== 'user' || (this.detailsAttendant.type === 'user' && this.dataTicket.ticket.by === this.detailsAttendant.name) },
    showTicket () { return this.dataTicket.userInfo.email !== this.$i18n.t('dashboard.no_email') && this.user.features.tickets },
    user () { return this.$store.getters['account/getUser'] },
    lang () { return this.$store.getters['account/getLang'] }
  },
  methods: {
    async getEventTicket () {
      if (this.dataTicket.userInfo.email === this.$i18n.t('dashboard.no_email')) { return }

      try {
        const stats = await ticketsService.getEvents(this.dataTicket.sendingId)
        this.eventList = stats.filter(o => o.type === 'TicketAttendantChangeEvent')

        const messages = stats.filter(o => o.type === 'TicketMessageSentEvent')

        if (messages) {
          messages.reverse()
          this.firstMessage = messages[0]
        }
      } catch (error) {
        console.log('error:', error)
      } finally {
        this.setLoading(false)
      }
    },
    async handleNewAttendance () {
      this.setLoading(true)

      const ticketToSend = { attendant: this.detailsAttendant.attendantSelected._id, sending: this.dataTicket.sendingId }

      if (!this.detailsAttendant.attendantSelected._id) { ticketToSend.attendant = null }

      try {
        const result = await ticketsService.createTicket(ticketToSend)

        const { ticket } = result
        const { date } = ticket.lastStatusUpdate

        const formattedTicket = {
          by: `${this.$t('tickets.by')} ${this.detailsAttendant.attendantSelected.name}`,
          category: null,
          date,
          email: this.detailsAttendant.attendantSelected.email,
          label: this.$t('tickets.opened'),
          status: 'assigned'
        }

        this.$root.$emit('update-ticket', formattedTicket, result._id)

        this.dataTicket.ticket = formattedTicket

        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('tickets.success_msg'),
          position: 'bottomRight',
          showAlert: true
        })
      } catch (error) {
        console.error('error when creating ticket', error)
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('tickets.error_msg'),
          position: 'bottomRight',
          showAlert: true
        })
      } finally {
        this.setLoading(false)
      }
    },
    async getDetailTicket () {
      const users = await accountService.getUsers()

      if (users) {
        const currentUserIndex = users.findIndex(attendant => attendant._id === this.user._id)

        if (currentUserIndex !== -1) {
          users.splice(currentUserIndex, 1)
        }

        users.unshift(this.noAttendant, this.user)
      }

      this.detailsAttendant = {
        attendants: users,
        attendantSelected: users[1]
      }

      this.setLoading(false)
    },
    async setNewCategory (e) {
      this.setLoading(true)

      const category = e.title !== null ? e.id : null

      try {
        await ticketsService.categorizeTicket({ category }, this.dataTicket.sendingId)

        this.dataTicket.ticket.category = e.title !== null ? e.title : this.$i18n.t('tickets.category.select_category')

        this.openCategories = false

        this.$root.$emit('update-ticket', this.dataTicket.ticket, this.dataTicket.sendingId)
      } catch (error) {
        console.error('error adding category', error)
      } finally {
        this.setLoading(false)
      }
    },
    redirectToChat () {
      const ticket = {
        _id: this.dataTicket.sendingId,
        contactEmail: this.dataTicket.userInfo.email,
        status: this.dataTicket.ticket.status
      }
      this.$router.push({ name: 'Tickets', query: ticket })
    },
    setLoading (e) {
      if (e) { this.loadingTicket = e }

      const opacity = e ? 1 : 0
      const divLoading = document.querySelector('.content-loading-ticket-sidebar')
      gsap.to(divLoading, {
        opacity,
        duration: 0.5,
        onComplete: () => {
          if (!e) { this.loadingTicket = e }
        }
      })
    },
    moment (date) {
      this.$moment.locale(this.lang)
      return this.$moment(date).format('lll')
    }
  }
}
</script>
<style lang="scss">
.container-ticket-sidebar {
  .select-select-attendance-sidebar {
    font-size: 1vw !important;
  }
}
</style>
<style lang="scss" scoped>
.container-ticket-sidebar {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1vw;
  overflow-y: auto;
  .header-ticket-sidebar {
    width: 100%;
    height: 10%;
    margin-bottom: 0.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.7vw;
    color: #454548;
    .title-ticket-sidebar {
      font-size: 1vw;
      font-weight: 500;
    }
    .description-ticket-sidebar {
      font-size: 0.9vw;
    }
  }
  .content-ticket-sidebar {
    width: 100%;
    .content-new-ticket-sidebar {
      display: flex;
      flex-direction: column;
      gap: 0.5vw;
      .title-new-ticket-sidebar {
        font-size: 0.9vw;
        font-weight: 500;
        color: #454548;
      }
      .select-attendance-ticket {
        display: flex;
        align-items: center;
        gap: 0.5vw;
        .input-select-attendance-sidebar {
          width: 210px !important;
          min-height: 1.5vw !important;
          height: 1.5vw !important;
          border-radius: 4px !important;
          padding-top: 0 !important;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        }
        .button-select-attendance-sidebar {
          width: 1.5vw;
          height: 1.5vw;
          background: #ffffff;
          color: #5e5e5e;
          border: 1px solid #cacaca;
          border-radius: 6px;
          font-size: 1vw;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
          cursor: pointer;
        }
      }
    }
    .content-current-ticket-sidebar {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 1vw;
      .current-ticket-sidebar {
        display: flex;
        flex-direction: column;
        gap: 0.3vw;
        .title-current-ticket-sidebar {
          font-size: 0.9vw;
          font-weight: 500;
          color: #454548;
        }
        .section-info-ticket-sidebar {
          background: #ffffff;
          height: 5vw;
          border-radius: 5px;
          border: 1px solid #e1e1e1;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
          display: flex;
          .icon-info-ticket-sidebar {
            width: 15%;
            height: 100%;
            border-right: 1px dashed #e1e1e1;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon-ticket-sidebar {
              font-size: 1.3vw;
              color: #5e5e5e;
            }
          }
          .detail-info-ticket-sidebar {
            width: 85%;
            height: 100%;
            padding: 0.2vw;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .label-info-ticket-sidebar {
              font-size: 0.8vw;
              color: #454548;
            }
            .label-closed {
              color: #2e8b40;
            }
          }
        }
        .section-event-ticket-sidebar {
          background: #ffffff;
          padding: 0.2vw;
          border-radius: 5px;
          border: 1px solid #e1e1e1;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
          display: flex;
          flex-direction: column;
          gap: 0.2vw;
          .event-ticket-sidebar {
            width: 100%;
            min-height: 4vw;
            background: #f7f7f790;
            border-radius: 3px;
            padding: 0.2vw;
            border: 1px solid #e1e1e1;
            box-shadow: 0 2px 4px #0000000d;
            display: flex;
            align-items: center;
            gap: 0.5vw;
            .icon-ticket-sidebar {
              font-size: 1.3vw;
              color: #5e5e5e;
            }
            .detail-info-ticket-sidebar {
              flex: 1;
              height: 100%;
              padding: 0.2vw;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              gap: 0.3vw;
              .label-info-ticket-sidebar:first-child {
                font-size: 0.9vw;
                color: #454548;
                font-weight: 500;
              }
              .label-info-ticket-sidebar {
                font-size: 0.9vw;
                color: #454548;
              }
              .label-text-ticket-sidebar {
                width: 100%;
                background: #eff3f8;
                margin-top: 1vw;
                padding: 0.2vw;
                box-shadow: 0 2px 4px #0000000d;
                border-radius: 3px;
                font-size: 0.9vw;
                color: #454548;
                font-style: italic;
              }
            }
          }
        }
      }
      .redirect-chat-sidebar {
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-redirect-chat-sidebar {
          padding: 0 0.6vw;
          height: 1.7vw;
          font-size: 0.8vw;
          border-radius: 5px;
          border: 1px solid #e1e1e1;
          background: #ffffff;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
          color: #5e5e5e;
          transition: background 0.2s;
        }
        .btn-redirect-chat-sidebar:hover {
          background: var(--accent-color);
          color: var(--default-text-color);
        }
      }
    }
  }
  .container-select-categories {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content-loading-ticket-sidebar {
    position: absolute;
    z-index: 51;
    opacity: 1;
    border-radius: 15px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f6f4f7;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
