<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper"  @click="handleClickOutsideSidebar($event)">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div class="modal-close-button close-config col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button @click="$emit('close')">
                  <app-textstyle size="size-l">×</app-textstyle>
                </button>
              </div>
              <app-heading level="h5" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 modal-title">{{ $t('advanced_filters.title') }}</app-heading>
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <div class="row-this-filter" v-for="(field, index) in inputDefault"  :key="index">
                <i class="material-icons topActionButtons icon-delete-row-filter" @click="removeField(index)" v-if="inputDefault.length > 1" v-title="$t('survey.delete_input')">delete</i>
                <!-- select de categoria -->
                  <!-- style="margin-left: -7px !important;" -->
                <v-select
                  class="survey-selector"
                  :placeholder="$t('advanced_filters.select_type')"
                  label="label"
                  :clearable='false'
                  :reduce="label => label.key"
                  :options="categories(index)"
                  @input="field.middle = '', field.category === 'tags' ? field.start = returnCategory(field)[0] : field.start = ''"
                  v-model="field.category"
                />
                <!-- select de opção da categoria (start - inicio da query) -->
                <!-- se for tag n precisa dessa parte,
                pois o inicio dela já é a categoria + option: 'tags' > 'operadores' > 'listagem de tags' -->
                <v-select
                  :clearable='false'
                  :placeholder="$t('advanced_filters.select_option')"
                  v-if="field.category && field.category === 'date.send'"
                  class="survey-selector"
                  label="label"
                  :options="returnCategory(field)"
                  @input="field.middle = '', field.end = ''"
                  v-model="field.start"
                ></v-select>
                <input
                  v-if="field.start.code === 'date.$gte' ||
                  field.start.code === 'date.$lte' ||
                  field.start.code === 'date.$gt' ||
                  field.start.code === 'date.$lt'"
                  type="date"
                  class="isDateInput"
                  onkeydown="return false"
                  v-model="field.start.date">
                <v-select
                  :clearable='false'
                  :placeholder="$t('advanced_filters.placeholder_category')"
                  v-if="field.category && field.category !== 'tags' && field.category !== 'channel' && field.category !== 'status' && field.category !== 'date.send'"
                  class="survey-selector"
                  label="label"
                  :options="returnCategory(field)"
                  @input="field.middle = '', field.end = ''"
                  v-model="field.start"
                >
                  <template #option="{ label, isExpired }">
                    <p :class="{'option-expired': isExpired}">
                      <span class="text-label">{{ label }}</span>
                      <i v-if="isExpired" class="material-icons expired-icon-select" v-title="$t('surveyList.survey_expired')">timer_off</i>
                    </p>
                  </template>
                </v-select>
                <v-select
                  :clearable='false'
                  :placeholder="$t('advanced_filters.select_option')"
                  v-if="field.category === 'channel'"
                  class="survey-selector"
                  label="label"
                  :options="equalOrNot()"
                  @input="field.middle = '', field.end = ''"
                  v-model="field.start"
                ></v-select>
                <v-select
                  :clearable='false'
                  :placeholder="$t('advanced_filters.placeholder_category')"
                  v-if="field.category === 'attendance' && field.start.code === 'ticket.category'"
                  class="survey-selector"
                  label="label"
                  :options="optionsCategoryList"
                  @input="field.middle = ''"
                  v-model="field.end"
                ></v-select>
                <v-select
                  :clearable='false'
                  :placeholder="$t('advanced_filters.placeholder_subcategory')"
                  v-if="field.category === 'attendance' && field.start.code === 'ticket.parentCategory'"
                  class="survey-selector"
                  label="label"
                  :options="optionsSubCategoryList"
                  @input="field.middle = ''"
                  v-model="field.end"
                ></v-select>
                <v-select
                  :clearable='false'
                  placeholder="Selecione um atendente"
                  v-if="field.category === 'attendance' && field.start.code === 'ticket.attendant'"
                  class="survey-selector"
                  label="label"
                  :options="listAttendants"
                  @input="field.middle = ''"
                  v-model="field.end"
                ></v-select>
                <v-select
                  :clearable='false'
                  :placeholder="$t('advanced_filters.select_option')"
                  v-if="field.category === 'channel' && field.start"
                  class="survey-selector"
                  label="label"
                  :options="channels()"
                  v-model="field.middle"
                ></v-select>
                <!-- select de operadores lógicos da query ou fórmulas prontas, como um  select de Detratores por exemplo -->
                <v-select
                  :clearable='false'
                  :placeholder="$t('advanced_filters.select_conditional')"
                  v-if="field.start && field.start.select"
                  class="survey-selector"
                  label="label"
                  :options="field.start.select"
                  v-model="field.middle"
                  @input="field.end = ''"
                ></v-select>
                <!-- STATUS -->
                <v-select
                  :clearable='false'
                  :placeholder="$t('advanced_filters.select_option')"
                  v-if="field.category === 'status'"
                  class="survey-selector"
                  label="label"
                  :options="equalOrNot()"
                  @input="field.middle = '', field.end = ''"
                  v-model="field.start"
                ></v-select>
                <v-select
                  :clearable='false'
                  :placeholder="$t('advanced_filters.select_option')"
                  v-if="field.category === 'status' && field.start"
                  class="survey-selector"
                  label="label"
                  :options="statusOption()"
                  v-model="field.middle"
                ></v-select>
                <!-- END STATUS -->
                <!-- select de valores -->
                <!-- select de um array de options passado quando o anterior é um equalOrNot -->
                <v-select
                  :clearable='false'
                  :placeholder="$t('advanced_filters.select_conditional')"
                  v-if="field.middle && field.middle.options"
                  class="survey-selector"
                  label="name"
                  :options="field.start.options"
                  :reduce="name => name.value"
                  v-model="field.end"
                ></v-select>
                <!-- select de um array de options passado quando o anterior é um equalOrNot - END -->
                <!-- para selects com opções multiplas, basta enviar 'values' preenchido com as opções de seleção
                dessa forma podemos diferenciar um select normal para um select de tags por exemplo -->
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 survey-selector" v-if="field.middle && field.start.values">
                  <multiselect
                    style="margin-top: 10px;"
                    v-model="field.end"
                    :max-height="150"
                    :options="field.start.values.map(item => item._id)"
                    :multiple="true"
                    :hide-selected="true"
                    :allow-empty="false"
                    :close-on-select="false"
                    :clear-on-select="false"
                    placeholder="Pick some"
                    :custom-label="opt => field?.start?.values.find(x => x._id == opt)?.name"
                  >
                    <template slot="selection"
                    slot-scope="{ values, isOpen }">
                    <span class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span>
                    </template>
                  </multiselect>
                </div>
                <!-- campos de texto para quando a ultima opção do filtro for uma busca por String -->
                <div class="field-input-text" v-if="field.middle && field.middle.input">
                  <input type="text" class="input-text-filter" v-model="field.end" :placeholder="$t('dashboard.type_value')">

                </div>
              </div>
            </slot>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{ 'add-fav-btns': inputDefault.length > 1, 'ml8neg': inputDefault.length === 1 }">
              <i class="material-icons topActionButtons btn-icons purpleI" style="font-size: 27px !important;" @click="addNewField" v-title="$t('dashboard.add_filter')">add_circle</i>
              <i class="material-icons topActionButtons btn-icons purpleI" style="font-size: 27px !important;" @click="isToSalveFilter = !isToSalveFilter" v-title="$t('dashboard.include_fav')">favorite</i>
              <input class="filterInputText input-filter"
                v-if="isToSalveFilter"
                :placeholder="$t('dashboard.type_filter_title')"
                secondary
                v-model="filterTitle"/>
              <i class="material-icons topActionButtons purpleI" style="margin-left: 5px;" v-if="filterTitle.length > 0" @click="apply(true)" v-title="$t('dashboard.save_and_apply')">done</i>
            </div>
          </div>
          <div class="row ml15 mt10 filterModalCheckboxMini">
              <app-form-checkbox-mini
              :label="$t('advanced_filters.clean_empty_questions')"
              :initial-value="cleanEmptyQuestions"
              :labelColor="user.account.theme.colors.primary"
              @valueChanged="updateCheckBox"
            />
          </div>
          <div class="row expander-title mt30">
            <Expander class="especial-width ml15" v-on:changeExpand="wasChanged($event)" :title="$t('dashboard.favorites')" :show-on-startup="true">
              <div id="favorite-container" style="max-height: 300px; overflow: scroll;overflow-x: hidden;">
                  <div class="row add-condition" v-for="(filter, index) in favoriteFilters" :key="index">
                    <button v-title="$t('dashboard.use_filter')" variation="primary" size="small" floating @click="loadFavoriteFilter(filter)">
                      <i class="material-icons topActionButtons btn-icons purpleI">favorite_border</i>
                    </button>
                    <button v-title="$t('dashboard_card.delete')" variation="primary" size="small" floating @click="checkIfCanDelete(index, filter); modalToDelete = true">
                      <i class="material-icons topActionButtons btn-icons purpleI">delete</i>
                    </button>
                    <app-heading level="h6" style="padding-top: 4.5px; margin-left: 15px !important;">{{ filter.name }}</app-heading>
                  </div>
              </div>
            </Expander>
          </div>
          <!-- botoões de ação -->
          <div class="modal-footer">
            <slot name="footer">
              <div class="row actionModalButtons center-xs center-sm center-md center-lg">
                <div class="btn f-btns">
                  <app-button class="btn-outline-primary" v-on:click="cleanFilter()">{{ $t('dashboard.clear_filters') }}</app-button>
                  <app-button class="btn-default-primary" v-on:click="apply()">{{ $t('dashboard.apply_filters') }}</app-button>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
       <app-modal
        v-model="modalToDelete"
        lightbox
        :title="$t('tickets.confirm_action')"
        close
        class="modal-default-primary"
        v-on:keyup.esc="modalToDelete = false"
        >
        <div>
          <div style="margin-top:20px;">
            <app-heading level="h5" style="line-height:25px;">{{ $t("advanced_filters.delete_filter") }}</app-heading>
            <div class="buttons-delete-approach" v-if="!loadingDelete">
              <app-button variation="secondary" v-on:click="modalToDelete = false"> {{ $t('notifications.cancel') }} </app-button>
              <app-button variation="primary" v-on:click="deleteFavoriteFilter()">{{$t('account_config.delete_confirm.yes')}}</app-button>
            </div>
            <div v-else style="text-align:right">
              <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
            </div>
          </div>
        </div>
      </app-modal>
      <!-- loading effect -->
      <div class="data-loading" v-show="isLoading">
        <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
      </div>
    </div>
  </transition>
</template>

<script>
import { tagsService, accountService, filtersService, ticketsService } from '@/_services'
import Expander from '../SideBarConfig/Expander.vue'
import { mapGetters } from 'vuex'
import { formatters, orderFormater, constants } from '@/_helpers'

import('lodash-es').then((lodashEs) => { window._ = lodashEs.default })

export default {
  name: 'FilterModal',
  props: ['entity'],
  components: {
    vSelect: () => import('vue-select'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-checkbox-mini': () => import('@binds-tech/binds-design-system/src/components/Form/CheckboxMini'),
    Multiselect: () => import('vue-multiselect'),
    Expander
  },
  data () {
    return {
      isLoading: false,
      isToSalveFilter: false,
      modalToDelete: false,
      loadingDelete: false,
      filterIdSelected: '',
      indexFilterSelected: '',
      filterTitle: '',
      indexThatCanUseSurvey: '',
      favoriteFilters: [],
      componentKey: 0,
      favoriteTitle: '',
      inputDefault: [
        this.defaultLayout()
      ],
      optionsCategoryList: [],
      optionsSubCategoryList: [],
      listAttendants: []
    }
  },
  created () {
    const onEscape = (e) => {
      if (e.keyCode === 27) {
        this.$emit('close')
      }
    }

    document.addEventListener('keydown', onEscape)

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape)
    })
  },
  async mounted () {
    this.isLoading = true
    const accountId = this.$store.getters['account/getUser'].account._id
    const fetchAll = [
      tagsService.getTags(),
      accountService.getUsers(),
      ticketsService.getTicketCategories(),
      accountService.getAccount(accountId),
      filtersService.getCustomFilters(this.entity)
    ]
    const [getTags, attendantUsers, allCategories, account, favoriteFilters] = await Promise.all(fetchAll)
    // tickets catgory
    const optionsCategory = []
    const optionsSubCategory = []
    allCategories.forEach(item => {
      optionsCategory.push({ label: item.title, id: item._id, key: '$eq' })
      item.subcategories.forEach(o => {
        optionsSubCategory.push({ label: o.title, id: o._id, key: '$eq' })
      })
    })
    this.optionsSubCategoryList = optionsSubCategory
    this.optionsCategoryList = optionsCategory
    // tickets attendants (users)
    const optionsAttendantList = []
    attendantUsers.forEach(item => {
      optionsAttendantList.push({ label: item.name, id: item._id, key: '$eq' })
    })

    this.listAttendants = optionsAttendantList

    const self = this
    if (!this.$store.getters['dashboard/getCurrentSurvey'] && this.entity !== 'sendings') {
      this.$emit('close')
      return false
    }
    this.$store.commit('account/setAccount', account)
    if (favoriteFilters.length) {
      this.favoriteFilters = favoriteFilters
    }
    this.$store.commit('filters/updateOptionValues', { tags: getTags })
    const hasFilter = this.selecteds
    if (account.fields) {
      account.fields.push(constants.metadataResend)

      account.fields.map(function (o) {
        if (o.key) {
          self.$store.commit('filters/insertMetadataField', { key: o.key, label: o.label })
        }
      })
    }
    if (this.$store.getters['dashboard/getCurrentSurvey']) {
      const questions = this.$store.getters['dashboard/getCurrentSurvey'].questions
      questions.map(function (question) {
        if (['ces', 'nes', 'nps', 'single', 'nvs', 'csat'].indexOf(question.type) > -1) {
          self.$store.commit('filters/insertQuestionField', question)
        }
      })
    }
    if (hasFilter.length) {
      const fields = []
      hasFilter.forEach(element => {
        const optionName = Object.keys(element)[0]
        let option = window._.find(this.options, { code: optionName })
        // procura por filtro de respostas, podendo ser em uma question especifica ou um filtro geral
        if (optionName === 'responses' && element[optionName].$elemMatch) {
          option = window._.find(this.options, { code: 'responses', toQuery: element[optionName] })
          if (element[optionName].$elemMatch['question._id']) {
            option = window._.find(this.options, { code: 'responses', _id: element[optionName].$elemMatch['question._id'] })
          }
        }
        if (optionName === 'survey' && element[optionName].$eq) {
          // não permite que mais de uma survey seja filtrada na query
          // assim evita de renderizar mais de 1 na listagem
          const hasSomeFieldUsingSurveyCategory = window._.findIndex(fields, item => item.category === 'survey')
          if (hasSomeFieldUsingSurveyCategory >= 0) {
            return
          }
          option = window._.find(this.options, { code: 'survey', _id: element[optionName].$eq })
        }
        if (optionName === 'optedOutAt' && typeof element[optionName] === 'object') {
          const findedIndexState = window._.findIndex(this.options, { code: 'state' })
          if (findedIndexState >= 0) {
            const keyToFind = element[optionName].$exists ? '$eq' : '$ne'
            option = window._.find(this.options[findedIndexState].select, { key: keyToFind })
          }
        }
        if (optionName === 'sending.createdAt' && element[optionName]) {
          const findedIndexState = window._.findIndex(this.options, { category: 'date.send' })
          if (findedIndexState >= 0) {
            const keyToFind = element[optionName].$gte ? '$gte' : element[optionName].$lte ? '$lte' : element[optionName].$gt ? '$gt' : '$lt'
            option = window._.find(this.options, { code: `date.${keyToFind}`, category: 'date.send' })
          }
        }
        if (optionName === 'type' && element[optionName]) {
          option = window._.find(this.options, { code: 'type', category: 'channel' })
        }
        let start = ''
        let category = ''
        let middle = ''
        let end = ''
        if (option) {
          start = option
          if (element[optionName]) {
            const objectToFindOption = { key: Object.keys(element[optionName])[0] }
            if (option.code === 'responses' && option._id) {
              if (element[optionName].$elemMatch.rating) {
                objectToFindOption.toQuery = { rating: element[optionName].$elemMatch.rating }
              }
              if (element[optionName].$elemMatch.$eq) {
                objectToFindOption.operator = '$eq'
              }
              if (element[optionName].$elemMatch.$ne) {
                objectToFindOption.operator = '$ne'
              }
            }
            // fix to find if select is true or false for doneAt
            if (option.code === 'doneAt' || option.code === 'ticket') {
              objectToFindOption.toQuery = element[optionName][objectToFindOption.key]
              objectToFindOption.key = '$exists'
            }
            // fix to find if select is true or false for respondedAt
            if (option.code === 'respondedAt' && option.category === 'responses') {
              objectToFindOption.toQuery = element[optionName][objectToFindOption.key]
            }

            if (option.category === 'metadata' && Object.keys(element[optionName])[0] === '$exists') {
              objectToFindOption.toQuery = element[optionName][objectToFindOption.key]
            }

            let optionSelected = window._.find(option.select, objectToFindOption)

            if (option.code === 'hasNotes' || option.code === 'isSolved') {
              const findEq = { toQuery: element[optionName][Object.keys(element[optionName])[0]] }
              optionSelected = window._.find(option.select, findEq)
            }

            if (optionSelected) {
              middle = optionSelected
            }
          }
          if (middle && element[optionName][middle.key] && typeof middle.toQuery !== 'object') {
            end = element[optionName][middle.key]
          }
          if (middle.input && option.code === 'responses' && option._id) {
            end = element[optionName].$elemMatch[middle.operator]
          }
          if (start.code === 'tags') {
            end = element[optionName][middle.key]
          }
          if (start.category) {
            category = start.category
          }
          if (start.code === 'type') {
            start = middle
            middle = { label: end }
            end = ''
          }
          if (start.code === 'state') {
            start = middle
            let selected = {}
            typeof end === 'string' ? selected = this.statusOption().find(o => o.value === end) : selected = { label: this.$i18n.t('advanced_filters.fields.Unsub') }
            if (selected) {
              middle = selected.label
            }
          }
          if (element.optedOutAt) {
            category = 'status'
            let selected = {}
            selected = this.statusOption().find(o => typeof o.value === 'object')
            if (selected) {
              middle = end ? selected.label : selected
            }
          }
          if (start.category === 'date.send') {
            const optionDate = Object.keys(element[optionName])[0]
            const date = element[optionName][optionDate]
            const formatedDate = formatters.formatDateByLang(date, this.user.language, 'YYYY-MM-DD')
            start.date = formatedDate
          }

          // COMENTADO POIS PODE SER USADO FUTURAMENTE

          // if (option.code === 'health.conversion') {
          //   const labelConversion = Object.keys(element[optionName])[0]
          //   const midConversion = element[optionName][labelConversion]
          //   end = ''
          //   middle = option.select.find(mid => mid.toQuery === midConversion)
          // }

          if (option.code === 'health.score' || option.code === 'health.churn') {
            const value = Object.keys(element[optionName])[0]
            const objConversion = element[optionName]
            end = ''
            middle = option.select.find(mid => mid.key[value] === objConversion[value])
          }

          fields.push({ category, start, middle, end })
        }
      })
      this.inputDefault = fields
    }
    this.isLoading = false
  },
  computed: {
    cleanEmptyQuestions () {
      // convert a string to boolean and change it back to default value
      // const var1 = 'true'
      // !var1 => false
      // !!var1 => true
      return this.$route.query.cleanEmptyQuestions === 'true'
    },
    options () {
      return this.$store.getters['filters/getDashFilter'].query.options
    },
    /**
     * importante para remover os campos fora do filtro avançado, como survey/data seleciona no dashboard
     * ou data/status nas abordagens, futuras telas q utilizam campos default tbm vão precisar passar por aqui
     * com isso vc evita que o modal tente renderizar um campo que não foi mapeado nas opções dessa tela
     */
    selecteds () {
      const result = window._.map(this.$store.getters['filters/getDashFilter'].query.$and, o => {
        if (['survey', 'respondedAt'].indexOf(Object.keys(o)[0]) >= 0 && this.entity !== 'sendings') {
          return []
        }
        if (['createdAt', 'archivedAt'].indexOf(Object.keys(o)[0]) >= 0 && this.entity === 'sendings') {
          return []
        }
        return o
      })
      return window._.reject(result, window._.isEmpty)
    },
    ...mapGetters({
      user: 'account/getUser'
    }),
    approachType () {
      return this.$store.getters['approaches/getSelectedType']
    }
  },
  methods: {
    handleClickOutsideSidebar (event) {
      if (event.target._prevClass === 'modal-wrapper') this.$emit('close')
      else return null
    },
    equalOrNot () {
      const ad = [
        {
          key: '$eq',
          label: this.$i18n.t('advanced_filters.fields.is_equal_to'),
          toQuery: '$eq',
          options: true
        },
        {
          key: '$ne',
          label: this.$i18n.t('advanced_filters.fields.is_diffrent_from'),
          toQuery: '$ne',
          options: true
        }
      ]
      return ad
    },
    channels () {
      const channel = [
        {
          label: 'widget'
        },
        {
          label: 'email'
        },
        {
          label: 'sms'
        },
        {
          label: 'link'
        },
        {
          label: 'whatsapp'
        }
      ]
      return channel
    },
    statusOption () {
      const status = [
        {
          label: this.$i18n.t('advanced_filters.fields.sent'),
          value: 'sent'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.rejected'),
          value: 'rejected'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.deferred'),
          value: 'deferred'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.spam'),
          value: 'spam'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.bounced'),
          value: 'bounced'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.soft_bounced'),
          value: 'soft-bounced'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.Unsub'),
          value: { optedOutAt: { $exists: true } }
        }
      ]
      return status
    },
    /**
     * lista as categorias e filtra elas por tela, algumas podem listar pesquisa nessas categorias e outras não
     *  e além disso permite que alguns campos sejam listados apenas uma vez para serem selecionados
     * nas abordagens por exemplo, só pode filtrar uma survey por vez ou todas
     * @param {Integer} currentFieldToFilter index do field que está chamando as categorias
     */
    categories () {
      let categories = this.$store.getters['filters/getDashFilter'].query.categories.filter(category => {
        return !category.entity || category.entity === this.entity
      })

      if (this.entity === 'sendings') {
        categories = categories.filter(category => { return category.key !== 'health' })
      }

      if (this.$store.getters['dashboard/getCurrentSurvey']?.isAnonymous || this.user.features.hiddenSendingContacts) {
        categories = categories.filter(category => category.key !== 'contact')
      }

      if (this.user.features.hiddenSendingContacts) {
        categories = categories.filter(category => category.key !== 'attendance')
      }

      if (this.user.features.hiddenSendingMetadata) {
        categories = categories.filter(category => category.key !== 'metadata')
      }

      return categories
    },

    defaultLayout () {
      return {
        category: '',
        start: '',
        middle: '',
        end: ''
      }
    },
    updateCheckBox (val) {
      this.$route.query.cleanEmptyQuestions = val
    },
    async loadFavoriteFilter (filter) {
      this.isLoading = true
      if (filter._id) {
        const result = await filtersService.getSpecificFilter(filter._id)
        if (result.query && result.query.$and) {
          // esse role éh bom para corrigir casos em que nosso time cria filtro pros clientes sem ter data definida,
          // desta forma tentamos contornar esse problema inserindo datas de acordo com o que tiver na url do cliente
          // ->> inicio do role
          const toQuery = result.query.$and
          const dateMetric = this.$store.state.filters.currentDateMetric
          const hasGteDateOnQuery = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$gte`)
          const hasLteDateOnQuery = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$lte`)
          const hasGteDateOnFavoriteQuery = window._.find(toQuery, `${dateMetric}.$gte`)
          const hasLteDateOnFavoriteQuery = window._.find(toQuery, `${dateMetric}.$lte`)
          if (hasGteDateOnQuery && !hasGteDateOnFavoriteQuery) {
            toQuery.push(hasGteDateOnQuery)
          }
          if (hasLteDateOnQuery && !hasLteDateOnFavoriteQuery) {
            toQuery.push(hasLteDateOnQuery)
          }
          const correctDateFromToUpdateFilter = window._.find(toQuery, `${dateMetric}.$gte`) // from
          const correctDateToUpdateFilter = window._.find(toQuery, `${dateMetric}.$lte`) // to
          const objectFilter = {
            query: {
              [dateMetric]: {
                $gte: correctDateFromToUpdateFilter ? correctDateFromToUpdateFilter[dateMetric].$gte : '',
                $lte: correctDateToUpdateFilter ? correctDateToUpdateFilter[dateMetric].$lte : ''
              }
            }
          }
          // ->> fim do rolê
          this.$store.commit('filters/updateFilterAnd', toQuery)
          this.$store.commit('filters/updateDashFilter', objectFilter)
          this.$route.query.q = { $and: this.$store.getters['filters/getDashFilter'].query.$and }
          this.$router.push({ name: this.$route.name, query: { q: JSON.stringify(this.$route.query.q) } }).catch(err => { if (err.name !== 'NavigationDuplicated') console.log('error when updateing url with filters', err) })
          this.$root.$emit('loadFilterAgain')
          this.$emit('close')
          return false
        }
      }
      this.isLoading = false
    },
    checkIfCanDelete (index, filter) {
      this.filterIdSelected = filter._id
      this.indexFilterSelected = index
    },
    async deleteFavoriteFilter () {
      this.loadingDelete = true
      if (this.filterIdSelected) {
        await filtersService.deleteCustomFilter(this.filterIdSelected)
        this.favoriteFilters.splice(this.indexFilterSelected, 1)
      }
      this.loadingDelete = false
      this.modalToDelete = false
    },
    async wasChanged (event) {
      this.isLoading = true
      if (event && !this.favoriteFilters.length) {
        this.favoriteFilters = await filtersService.getCustomFilters(this.entity)
      }
      this.isLoading = false
    },
    cleanFilter () {
      this.dateForFilter = []
      const dateMetric = this.$store.state.filters.currentDateMetric
      const findSurveyOnFilter = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, 'survey')
      const hasGteDateOnQuery = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$gte`)
      const hasLteDateOnQuery = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$lte`)
      this.$store.commit('filters/updateFilterAnd', [])

      if (['sending-responses', 'ranking'].indexOf(this.entity) > -1) {
        this.$store.commit('filters/updateFilterAnd', [findSurveyOnFilter])
      }
      if (hasGteDateOnQuery) {
        this.$store.commit('filters/createOrUpdateSpecificKey', hasGteDateOnQuery)
      }
      if (hasLteDateOnQuery) {
        this.$store.commit('filters/createOrUpdateSpecificKey', hasLteDateOnQuery)
      }
      this.$route.query.q = { $and: this.$store.getters['filters/getDashFilter'].query.$and }
      this.inputDefault = [this.defaultLayout()]
      this.updateCurrentUrl()
      this.$root.$emit('filter-updated')
    },
    removeField (index) {
      this.inputDefault.splice(index, 1)
    },
    addNewField () {
      this.inputDefault.push(this.defaultLayout())
    },
    returnCategory (field) {
      if (field.category === 'survey') {
        return orderFormater.expiredAt(window._.filter(this.options, { category: field.category }))
      }

      return window._.filter(this.options, { category: field.category })
    },
    updateCurrentUrl () {
      this.$router.push({ query: { ...this.$route.query, q: JSON.stringify(this.$route.query.q) } })
      this.$emit('close')
    },
    /**
     * prepara para converter os campos selecionados em uma query pronta para ser enviada ao backend
     * tambem atualiza a url da pagina atual para que o cliente possa copiar e colar os filtros
     * fazendo com que possa ser compartilhado o q foi filtrado
     */
    async apply (isToSaveFilter) {
      const toQuery = []
      this.isLoading = true
      const dateMetric = this.$store.state.filters.currentDateMetric
      const findArchivedAt = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, 'archivedAt')
      const findSurveyOnFilter = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, 'survey')
      const hasGteDateOnQuery = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$gte`)
      const hasLteDateOnQuery = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, `${dateMetric}.$lte`)
      const hasSomeFieldUsingSurveyCategory = window._.findIndex(this.inputDefault, item => item.category === 'survey')
      // caso n tenha selecionado uma survey, aplica a que está na url
      // esse if bate exatamente em telas que possuem filtro de survey no modal
      // utiliza a survey que foi selecionada e no caso quando for em telas que n possuem isso permanecem
      // com a survey que já existia na url
      if (findSurveyOnFilter && hasSomeFieldUsingSurveyCategory === -1) {
        toQuery.push(findSurveyOnFilter)
      }
      if (this.$route.name === 'Approaches' && findArchivedAt) {
        toQuery.push(findArchivedAt)
      }
      if (hasGteDateOnQuery) {
        toQuery.push(hasGteDateOnQuery)
      }
      if (hasLteDateOnQuery) {
        toQuery.push(hasLteDateOnQuery)
      }
      this.inputDefault.forEach(element => {
        if (window._.isEmpty(element.start)) {
          this.isLoading = false
          return false
        }
        if ((element.start.code === 'responses' || element.start.code === 'survey') && element.start.toQuery) {
          toQuery.push({ [element.start.code]: element.start.toQuery })
        } else if (window._.isEmpty(element.end) && !window._.isEmpty(element.middle.key)) {
          let hasToQuery = { [element.middle.key]: element.middle.toQuery }

          if (!element.middle.toQuery && element.start.code !== 'respondedAt') { hasToQuery = element.middle.key }

          toQuery.push({ [element.start.code]: hasToQuery })
        } else {
          if (typeof element.end === 'string') {
            let value = !window._.isEmpty(element.end) ? element.end.trim() : element.middle.toQuery
            if (element.middle.input && element.middle.operator) {
              element.middle.toQuery[element.middle.operator] = element.end.trim()
              value = element.middle.toQuery
            }
            if (!value && element.category === 'channel') {
              toQuery.push({ type: { [element.start.key]: element.middle.label } })
            } else if (element.category === 'status') {
              const startKey = element.start.key
              const filterState = typeof element.middle.value === 'object' ? { optedOutAt: { $exists: startKey === '$eq' } } : { state: { [element.start.key]: element.middle.value } }
              if (this.approachType.type === 'all') {
                toQuery.push(filterState)
              } else {
                let exists = false
                this.approachType.type === 'active' ? exists = false : exists = true
                toQuery.push({ archivedAt: { $exists: exists } }, filterState)
              }
            } else if (element.category === 'date.send') {
              const keyDate = element.start.code === 'date.$gte' ? '$gte' : element.start.code === 'date.$lte' ? '$lte' : element.start.code === 'date.$gt' ? '$gt' : '$lt'
              const formatedValue = this.$moment(element.start.date).add(-3, 'hours').toISOString()
              toQuery.push({ 'sending.createdAt': { [keyDate]: formatedValue } })
            } else {
              toQuery.push({ [element.start.code]: { [element.middle.key]: value.trim() } })
            }
          } else {
            if (element.start.code === 'tags') {
              toQuery.push({ [element.start.code]: { [element.middle.key]: element.end } })
            }
            if (element.start.code === 'ticket.lastStatusUpdate.label') {
              toQuery.push({ [element.start.code]: { [element.middle.key]: element.end.value } })
            }
            if (element.start.code === 'ticket.category' || element.start.code === 'ticket.parentCategory') {
              toQuery.push({ [element.start.code]: { [element.end.key]: element.end.id } })
            }
            if (element.start.code === 'ticket.attendant') {
              toQuery.push({ [element.start.code]: { [element.end.key]: element.end.id } })
            }
          }
        }
      })

      this.$store.commit('filters/updateFilterAnd', toQuery)
      this.$route.query.q = { $and: this.$store.getters['filters/getDashFilter'].query.$and }
      // save and apply filter
      if (isToSaveFilter) {
        // removes the respondedAt from $and
        const $andWithRespondedAtremoved = this.$store.getters['filters/getDashFilter'].query.$and.filter(item => !item.respondedAt)

        const filterToCreate = {
          query: {
            $and: $andWithRespondedAtremoved
          },
          entity: this.entity,
          name: this.filterTitle
        }
        await filtersService.createCustomFilter(filterToCreate)
        this.favoriteFilters = await filtersService.getCustomFilters(this.entity)
        this.isToSalveFilter = false
        this.filterTitle = ''
      }
      this.updateCurrentUrl()
      this.$root.$emit('filter-updated')
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss">
.vs__dropdown-option {
  padding: 0 0.5rem !important;
  display: flex;
  align-items: center;
  .option-expired {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    }
  }

.expander-title #favorite-container {
  display: flex;
  flex-wrap: wrap;

  .row.add-condition {
    width: 50%;
    flex: 50%;
    height: fit-content;
  }
}

.modal-body {
  margin-top: 25px;
  width: 99% !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .row-this-filter {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    gap: 10px;
    .field-input-text {
      width: 300px;
      height: 50px;
      .input-text-filter {
        width: 100%;
        height: 100%;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.03), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
        text-align: center;
        font-size: 16px;
        color: #9f9f9f;
      }

      .input-text-filter::placeholder {
        color: #cacaca;
      }
    }
    .icon-delete-row-filter {
      color: #cacaca;
    }
    .icon-delete-row-filter:hover {
      color: var(--accent-color);
    }
    .survey-selector {
      height: 100%;
      padding: 0 !important;
      margin: 0 !important;
      display: flex;
      align-items: center;
      padding: 0;
      .vs__dropdown-toggle {
        border: 1px solid #e1e1e1 !important;
        width: 300px;
        .vs__selected-options {
          .vs__selected {
            margin: 0;
            padding: 2px;
          }
        }
      }
    }
  }
}

.filterModalCheckboxMini > .input-wrapper::after {
  border: none;
}

.filterModalCheckboxMini > .input-wrapper input:checked ~ .checkmark {
  filter: grayscale(100%);
}
.actionModalButtons > .f-btns {
  & .binds-button.medium {
    font-size: 0.8em !important;
  }
}
.topActionButtons {
 cursor: pointer;
 padding: 5px;
 font-size: 18px !important;
}
.purpleI {
  .material-icons {
  color: rgb(156, 140, 202);
  }
}
.tooltip {
  z-index: 1000000000000000 !important;
}
.multiselect__option--highlight::after, .multiselect__option--highlight, .multiselect__tag {
  background: #BCBBBB;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #6E6C6C;
}
.multiselect__tag-icon:after {
  color: #6E6C6C;
}
.multiselect__single {
  color: #b8a7b0;
}
.filterInputText > .text-field__input {
  box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.03), 0 5px 4px 0 rgba(0, 0, 0, 0.01) !important;
  background-color: #fcfcfc !important;
}
.filterInputText > .text-field__input > input {
  color: #a7a2b5 !important;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.17);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  width: 880px !important;
  min-height: 300px !important;
  max-height: 450px !important;
  overflow-x: hidden;
}
.modal-container {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50px;
  width: 90%;
  margin: 0px auto;
  margin-top: -33px !important;
  padding: 10px 10px 0px 15px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.modal-title {
  margin-left: 5px !important;
}
.modal-close-button {
  top: -17px;
  right: 0;
  position: absolute;
}
.add-fav-btns {
  padding-left: 35px !important;
  margin-top: -16px !important;
}
.ml15 {
  margin-left: 14px !important;
}
.ml8neg {
  margin-left: -8px !important;
}
.especial-width {
  width: 98% !important;
}
.input-filter {
  border: 1px solid gray;
  border-radius: 4px;
  height: 35px;
  margin-left: 10px;
  padding-left: 10px;
  color: #a7a2b5;
  max-width: 152px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.isDateInput {
  margin: 5px 0 15px 0;
  border: none;
  border-radius: 5px;
  padding: 5px 5px 5px 15px;
  color: rgb(146, 146, 146);
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.isDateInput::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  height: 20px;
  width: 20px;
  filter: invert(0.4);
}

.isDateInput::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}
</style>
