<template>
  <div class="container-card-history-resend">
    <div class="section-history-resend">
      <p class="title-history-resend">{{ $t('resend.survey') }}</p>
      <p class="label-history-resend">{{ item.title }}</p>
    </div>
    <div class="section-history-resend">
      <p class="title-history-resend">{{ $t('resend.created_at') }}</p>
      <p class="label-history-resend">{{ formatDate(item.createdAt) }}</p>
    </div>
    <div class="section-history-resend">
      <p class="title-history-resend">{{ $t('resend.scheduled_to') }}</p>
      <p class="label-history-resend">{{ formatDate(item.schedulingDate) }}</p>
    </div>
    <div class="section-history-types-resend">
      <div class="section-type-resend" :class="itemType.count > 0 ? itemType.type : ''" v-for="itemType in item.deliveryTypes" :key="itemType.type" v-title="returnTypeTooltip(itemType.type)">
        <i class="material-icons icon-type-resend" :class="{'whatsapp-icon-schedule': itemType.type === 'whatsapp'}">{{ returnTypeIcon(itemType.type) }}</i>
        <p class="count-type-resend">{{ formatNumber.format(itemType.count) }}</p>
      </div>
    </div>
    <div class="section-actions-resend">
      <p class="title-cancel-resend">{{ $t('resend.cancel_resend') }}</p>
      <i class="material-icons-outlined icon-cancel-resend" @click="$emit('cancel-scheduled-item', item)">cancel</i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardHistoryResend',
  props: ['item'],
  components: {},
  data () {
    return {
    }
  },
  computed: {
    formatNumber () { return Intl.NumberFormat('pt-BR', { maximumSignificantDigits: 10 }) }
  },
  methods: {
    formatDate (date) { return this.$moment(date).format('DD/MM/YYYY, HH:mm') },
    returnTypeIcon (type) {
      if (type === 'email') { return 'mail' }
      if (type === 'sms') { return 'comment' }
      return 'call'
    },
    returnTypeTooltip (type) {
      if (type === 'email') { return this.$t('resend.type.email') }
      if (type === 'sms') { return this.$t('resend.type.sms') }
      return this.$t('resend.type.whatsapp')
    }
  }
}
</script>

<style lang="scss">
.whatsapp {
  color: #4daa56 !important;
  text-shadow: 1px 0.2px 2px #40bf4bb5;
  .whatsapp-icon-schedule {
    background: #40bf4b !important;
    box-shadow: 1px 0.2px 2px #40bf4bb5;
  }
}

.sms {
  color: #9ea232 !important;
  text-shadow: 1px 0.2px 2px #bcbf40b5;
}

.email {
  color: #2f99b9 !important;
  text-shadow: 1px 0.2px 2px #40a1bfb5;
}

.container-card-history-resend {
  width: 100%;
  height: 13vh;
  padding: 0.5rem;
  padding-left: 1.5rem;
  background: #ffffff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.08);
  border: 1px solid #e1e1e1;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  .section-history-resend {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .title-history-resend {
      font-size: 0.9vw;
      color: #b9b9b9;
    }
    .label-history-resend {
      width: 100%;
      color: #454548;
      font-size: 1vw;
      line-height: 1.2vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      text-shadow: 1px 0px 4px #e1e1e1;
    }
  }
  .section-history-types-resend {
    width: 20%;
    height: 100%;
    border: 1px solid #e1e1e1;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.08);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .section-type-resend {
      height: 90%;
      width: 30%;
      color: #e1e1e1;
      cursor: help;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .icon-type-resend {
        font-size: 1.2vw;
      }
      .whatsapp-icon-schedule {
        background: #e1e1e1;
        border-radius: 50%;
        color: #ffffff;
        font-size: 0.85vw;
        padding: 0.18rem;
      }
      .count-type-resend {
        font-size: 0.9vw;
        text-shadow: 1px 0px 4px #e1e1e1;
      }
    }
  }
  .section-actions-resend {
    width: 15%;
    height: 100%;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: #b9b9b9;
    .title-cancel-resend {
      font-size: 0.9vw;
    }
    .icon-cancel-resend {
      font-size: 1.8vw;
      cursor: pointer;
    }
    .icon-cancel-resend:hover {
      color: #ef6e6e;
      transform: scale(1.05);
    }
  }
}
</style>
